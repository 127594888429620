import { Injectable } from '@angular/core';
import { throwError, Observable, BehaviorSubject } from 'rxjs';
import { catchError, share, map, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from 'src/app/core/app-config.service';
import { Workspace } from 'src/app/workspace/workspace';
import { Widget } from '../workspace-widget/widget';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceService {
  workspaceData = new BehaviorSubject<Workspace>(null);
  workspaceDataObservable = this.workspaceData.asObservable();
  pieCommunicator = new BehaviorSubject<Widget>(null);
  pieCommunicatorObservable = this.pieCommunicator.asObservable();
  openLink = new BehaviorSubject<boolean>(false);
  openLinkObservable = this.openLink.asObservable();
  // Behavior subject to hold the CCID changed in MB Dashboard
  ccidChanged = new BehaviorSubject<any>(null);
  ccidChangedObservable = this.ccidChanged.asObservable();
  // Behavior subject for enabling the edit options
  editOption = new BehaviorSubject<boolean>(false);
  editOptionObservable = this.editOption.asObservable();
  editData = new BehaviorSubject<boolean>(false);
  editDataObservable = this.editData.asObservable();
  // Behavior subject for enabling the edit options
  updateApp = new BehaviorSubject<boolean>(false);
  updateAppObservable = this.editOption.asObservable();
  private readonly cacheApi: Map<string, Observable<string[]>> =
    new Map<string, Observable<string[]>>();
  // Behavior subject for User Refreshment notification
  userRefreshed = new BehaviorSubject<boolean>(false);
  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) { }

  /**
   * Method to set the workspace
   * @param workspace Workspace details
   */
  setWorkspace(workspace: Workspace): void {
    this.workspaceData.next(workspace);
  }

  /**
   * Expands the PIE Widget when the 1x1 is clicked
   * @param widget objected containing an id of the grid item to expand
   */
  setPIEExpand(widget: Widget): void {
    this.pieCommunicator.next(widget);
  }

  /**
   * Method to return the latest workspace values
   */
  getWorkspace(): Workspace {
    return this.workspaceData.value;
  }

  /**
   * Method to return the latest editOption values
   */
  getEditOptions(): boolean {
    return this.editOption.value;
  }

  /**
   * Method to update the workspace behavior subject with latest layout values
   * @param layout Layouts
   */
  setBasicWorkspace(layout: any) {
    const workspace = this.getWorkspace();
    this.updateLayouts(workspace.layout, layout);
    this.setWorkspace(workspace);
  }

  setFolderWorkspace(workspace: any) {
    const oldData = this.getWorkspace();
    this.updateLayouts(oldData.layout, workspace.layout);
    this.setWorkspace(workspace);
  }

  /**
   * Method to update the workspace behavior subject with latest values
   * @param id Workspace id
   * @param workspaceData Workspace data
   */
  setSingleColumWorkspace(id: any, workspaceData: any) {
    const workspace = this.getWorkspace();
    for (const item of workspace.layout) {
      if (item.id === id) {
        this.updateLayouts(item.content.layout, workspaceData.layout);
      }
    }
    this.setWorkspace(workspace);
  }

  /**
   * Method toadd/remove the current workspace layout list
   * @param currentList existing list
   * @param updatedList new list
   */
  updateLayouts(currentList: any, updatedList: any) {
    // Convert the Layouts Arrays to "Maps"
    // tslint:disable-next-line: only-arrow-functions
    const currentLayoutMap = currentList.reduce(function(layoutMap, layout) {
      layoutMap[layout.layoutId] = layout;
      return layoutMap;
    }, {});
    // Determine which layouts have been added and which
    // tslint:disable-next-line: only-arrow-functions
    const addedLayouts = updatedList.filter(function(layout) {
      return !(layout.layoutId in currentLayoutMap);
    });
    for (let i = currentList.length - 1; i >= 0; i--) {
      let layoutFound = false;
      for (let j = 0, upLen = updatedList.length; j < upLen; j++) {
        if (currentList[i].layoutId === updatedList[j].layoutId) {
          layoutFound = true;
          currentList[i].parentId = updatedList[j].parentId;
          currentList[i].shortName = updatedList[j].shortName;
          currentList[i].longName = updatedList[j].longName;
          currentList[i].templateFile = updatedList[j].templateFile;
          currentList[i].description = updatedList[j].description;
          currentList[i].x = updatedList[j].x;
          currentList[i].y = updatedList[j].y;
          currentList[i].height = updatedList[j].height;
          currentList[i].width = updatedList[j].width;
          currentList[i].icon = updatedList[j].icon;
          currentList[i].color = updatedList[j].color;
          currentList[i].url = updatedList[j].url;
          currentList[i].locked = updatedList[j].locked;
          currentList[i].minHeight = updatedList[j].minHeight;
          currentList[i].minWidth = updatedList[j].minWidth;
          currentList[i].maxHeight = updatedList[j].maxHeight;
          currentList[i].maxWidth = updatedList[j].maxWidth;
          // TODO: May need to revist and iteratively modify the elements, but so-far-so-good
          currentList[i].layout = updatedList[j].layout;
          break;
        }
      }
      if (!layoutFound) {
        currentList.splice(i, 1);
      }
    }
    for (let i = 0, len = addedLayouts.length; i < len; i++) {
      currentList.push(addedLayouts[i]);
    }
    this.editData.next(true);
  }

  setOpenLink(value: boolean): void {
    this.openLink.next(value);
  }

  setUpdateApp(value: boolean): void {
    this.updateApp.next(value);
  }

  setCCID(ccid): void {
    sessionStorage.setItem('ccid', JSON.stringify(ccid));
    this.ccidChanged.next(ccid);
  }

  setEditOptions(value: boolean): void {
    this.editOption.next(value);
  }

  getWorkspacesProp(withCache: boolean): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('workspaceEndpoint');
    return this.getXHRCall(endpoint, withCache);
  }

  setDefaultWorkspace(cacheData) {
    const endpoint = this.appConfigService.getBackendPath('defaultWorkspace');
    this.cacheApi[endpoint] = cacheData;
  }

  setHomeWorkspace(cacheData) {
    const endpoint = this.appConfigService.getBackendPath('homeWorkspace');
    cacheData.subscribe((response) => {
      this.setWorkspace(response.data);
    });
    this.cacheApi[endpoint] = cacheData;
  }

  getDefaultWorkspace(withCache: boolean = true, withSubscribe: boolean = false, withSet: boolean = false, src?: any): Observable<Widget> {
    const endpoint = this.appConfigService.getBackendPath('defaultWorkspace');
    if (src === 'publish'){
      withCache = false;
    }
    return this.getSetXHRCall(endpoint, withCache, withSubscribe, withSet);
  }

  getHomeWorkspace(withCache: boolean = true, withSubscribe: boolean = false, withSet: boolean = false, src?: any): Observable<Widget> {
    const endpoint = this.appConfigService.getBackendPath('homeWorkspace');
    if (src === 'publish'){
      withCache = false;
    }
    return this.getSetXHRCall(endpoint, withCache, withSubscribe, withSet);
  }

  private getSetXHRCall(endpoint: string, withCache: boolean, withSubscribe: boolean, withSet: boolean) {
    const callXhr = this.getXHRCall(endpoint, withCache);
    if (withSubscribe) {
      callXhr.subscribe((response) => {
        if (withSet) {
          this.setWorkspace(response.data);
          this.setUpdateApp(false);
        }

      });
    }
    return callXhr;
  }

  private getXHRCall(endpoint, withCache?: boolean): Observable<any> {
    if (withCache) {
      if (!this.cacheApi[endpoint] || this.cacheApi[endpoint]?.code === 'OK') {
        this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(), catchError(this.error));
      }
    } else {
      this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(), catchError(this.error));
    }
    return this.cacheApi[endpoint];
  }

  getCurrentWorkspace() {
    if (this.workspaceData.value) {
      return this.workspaceData;
    } else {
      return this.getDefaultWorkspace(true, false, false).pipe(map((defaultWorkspace: any) => {
        return defaultWorkspace.data;
      }), share(),
        catchError(this.error));
    }
  }

  getActionableWorkspace(workspace: Workspace) {
    let actionableWorkspace = workspace;
    if (workspace.type === 'BasicWorkspace') {
      actionableWorkspace = workspace;
    } else if (workspace.type === 'SingleColumnWorkspace') {
      for (const item of workspace.layout) {
        if (item.type === 'BasicWorkspace') {
          actionableWorkspace = item.content;
          break;
        }
      }
    }
    return actionableWorkspace;
  }

  getWorkspaceById(workspaceId: string) {
    const endpoint = this.appConfigService.getBackendPath('getWorkspace') + '/' + workspaceId;
    return this.getXHRCall(endpoint);
  }

  saveLayout(layoutData: any[], workspace: Workspace): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const endpoint = this.appConfigService.getBackendPath('saveLayout') + '/' + workspace.id + '/' + 'layouts-collection';
    return this.httpClient.post<any>(endpoint, JSON.stringify(layoutData), httpOptions).pipe(catchError(this.error));
  }

  toggleLayout(layoutId: string, dataName: string, dataValue: boolean) {
    const endpoint = this.appConfigService.getBackendPath('toggleLayout') + '/' + layoutId + '/' + dataName;
    return this.httpClient.post<any>(endpoint, dataValue).pipe(catchError(this.error));
  }

  removeLayout(layoutId: string) {
    const endpoint = this.appConfigService.getBackendPath('widgetLayout') + '/' + layoutId + '/' + 'remove';
    return this.httpClient.post<any>(endpoint, {}).pipe(catchError(this.error));
  }

  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

  setEditOptionsForWorkspace(value: boolean) {
    this.workspaceData.subscribe((response: any) => {
      if (response.type === 'SingleColumnWorkspace') {
        for (const workspace of response.layout) {
          if (workspace.type === 'BasicWorkspace') {
            for (const item of workspace.content.layout) {
              if (item.noHoverMenu === false &&
                (item.removable) && value) {
                item.editOptions = true;
              } else {
                item.editOptions = false;
              }
              item.disablePointer = value;
            }
          } else {
            if (workspace && workspace.content && workspace.content.layout) {
              for (const item of workspace.content.layout) {
                item.editOptions = false;
                item.disablePointer = false;
              }
            }
          }
        }
      } else if (response.type === 'BasicWorkspace' || response.type === 'BasicFolder') {
        for (const item of response.layout) {
          if (item.noHoverMenu === false &&
            (item.removable) && value) {
            item.editOptions = true;
          } else {
            item.editOptions = false;
          }
          item.disablePointer = value;
        }
      }
    });
  }

  getDashboardWidgetConfig(dashboardName: string): Observable<any> {
    let fileName: string;
    let environment: string;
    const environmentString = 'environment';
    this.appConfigService.envConfig.subscribe(config => {
      if (config[environmentString]) {
        environment = config[environmentString];
        environment = environment === 'uat' ? 'frz' : environment;
      }
    });
    if (window.innerWidth < 600) {
      if (dashboardName === 'Boeing Business Jets Dashboard') {
        fileName = 'bbj-mobile-' + environment;
      } else if (dashboardName === 'Leasing Dashboard') {
        fileName = 'leasing-mobile-' + environment;
      } else if (dashboardName === 'Material Buyer Dashboard') {
        fileName = 'mbd-mobile-' + environment;
      }
    } else if (window.innerWidth >= 600 && window.innerWidth < 1200) {
      if (dashboardName === 'Boeing Business Jets Dashboard') {
        fileName = 'bbj-tablet-' + environment;
      } else if (dashboardName === 'Leasing Dashboard') {
        fileName = 'leasing-tablet-' + environment;
      } else if (dashboardName === 'Material Buyer Dashboard') {
        fileName = 'mbd-tablet-' + environment;
      }
    }
    return this.appConfigService.fetchComponentConfig(fileName);
  }

}
