import { Component, OnInit, NgZone } from '@angular/core';

@Component({
  selector: 'app-back-to-top',
  templateUrl: './back-to-top.component.html',
  styleUrls: ['./back-to-top.component.css']
})
export class BackToTopComponent implements OnInit {
  showBackToTop = false;
  fixButton = false;
  backToTopElm: any;
  constructor(private ngZone: NgZone) { }

  ngOnInit(): void {
    this.ngZone.runOutsideAngular(() => {
      document.addEventListener('scroll', this.scrollFunction.bind(this));
    });
    this.backToTopElm = document.getElementById('backToTopBtn');
    this.backToTopElm.style.display = 'none';
  }

  scrollFunction() {
    const footerHeight = document.getElementsByTagName('footer')[0] ? (document.getElementsByTagName('footer')[0].offsetHeight - 100) : 0;
    const screenHeight = window.screen.height;
    const pageHeight = document.getElementById('fullBodyContent').offsetHeight;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    if (document.body.scrollTop > (2 * screenHeight) || document.documentElement.scrollTop > (2 * screenHeight)) {
      this.backToTopElm.style.display = 'block';
      if (window.pageYOffset + windowHeight > pageHeight - footerHeight) {
        this.backToTopElm.classList.add('fixed_button');
      } else {
        this.backToTopElm.classList.remove('fixed_button');
      }
    } else {
        this.backToTopElm.style.display = 'none';
    }
  }

  }
