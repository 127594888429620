import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { AppConfigService } from 'src/app/core/app-config.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FooterService } from 'src/app/footer/footer.service';
import { WorkspaceService } from '../workspace/workspace.service';
import { FooterBaseComponent } from './footer-base.component';
import { UserApiService } from '../user/user-api.service';
import { UserRolesItem } from 'src/app/user/user-roles-item';
import { User } from 'src/app/user/user';

@Component({
  selector: 'app-footer-mbf',
  templateUrl: './footer-mbf.component.html',
  styleUrls: ['./footer-mbf.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterMBFComponent extends FooterBaseComponent {

  nonTBCUser = false;
  accessAdministratorFlag = false;
  user = {
    firstName: '',
    lastName: '',
    userRoles: [] as UserRolesItem[]
  } as User;
  // show build, configuration to specific users
  isEligibleUser = false;
  constructor(
    cd: ChangeDetectorRef,
    footerService: FooterService,
    appConfigService: AppConfigService,
    workspaceService: WorkspaceService,
    router: Router,
    location: Location,
    private userApiService: UserApiService
    ) {
    super(cd, footerService, appConfigService, router, location, workspaceService );
    this.checkAccessAdminiPriviledge();
    this.userApiService.getUser().subscribe((user) => {
      this.user = user;
      if (!(user && user.airlineCode && user.airlineCode === 'TBC')) {
        this.nonTBCUser = true;
      }
      else{
        this.nonTBCUser = false;
      }
      this.cd.markForCheck();
    });
    if (this.user.userRoles.length > 0) {
      const findAdminRole = this.user.userRoles.find(function (item) {
        return item.userRoleName == 'PORTAL ADMINISTRATOR';
      });
      this.isEligibleUser = findAdminRole != undefined;
    }
  }

  getURL(url: string, urlTarget: string, type?: string, title?: string){
    const value = window.location.href;
    if ( title === 'Access Administrator Support') {
      const updatedUrl = this.buildLink(url, type, title);
      window.open(updatedUrl, urlTarget);
    } else if ( title === 'Product and Data Access (Access Summary)') {
      const updatedUrl = this.buildLink(url, type, title);
      window.open(updatedUrl, urlTarget);
    }
    else {
      if (value.includes(url)) {
        window.scroll(0, 0);
        } else {
          if (title === 'My Field Service Office') {
            window.localStorage.setItem('fsoOpen', 'true');
          }
          const updatedUrl = this.buildLink(url, type, title);
          window.open(updatedUrl, urlTarget);
        }
    }
   }
   checkAccessAdminiPriviledge() {
     let envConfigAccessAdmin = '';
     const accessAdminUrl = 'accessAdminUrl';
     this.appConfigService.envConfig.subscribe(config => {
      if (config[accessAdminUrl]) {
        this.envConfig = config;
        envConfigAccessAdmin = config[accessAdminUrl];
        if (envConfigAccessAdmin === '') {
          this.accessAdministratorFlag = false;
        }
        else {
          this.accessAdministratorFlag = true;
        }
      }
      else {
        this.accessAdministratorFlag = false;
      }
    });
   }
}
