import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef,
  ChangeDetectorRef, ChangeDetectionStrategy  } from '@angular/core';
import { HeaderService } from '../header.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { AppConfigService } from '../../core/app-config.service';
import { UserApiService } from '../../user/user-api.service';
import { User } from '../../user/user';
import searchCategories from 'src/assets/config/data/search-categories-data-MBF.json';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatMenuTrigger } from '@angular/material/menu';
import { of } from 'rxjs';
@Component({
  selector: 'app-header-mbf-search',
  templateUrl: './header-mbf-search.component.html',
  styleUrls: ['./header-mbf-search.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderMbfSearchComponent implements OnInit {
  hasFocus = false;
  user: User = null;
  headerGroup: FormGroup;
  searchResults: any[] = [];
  airplaneIdSearchResults: any[] = [];
  isLoading = false;
  isAirplaneLoading = false;
  advsearchFilter = false;
  searchCategories: any = searchCategories;
  categoryList = this.getCategories();
  selectedRefinements: any[] = [];
  selectedFilter: any;
  @ViewChild('autoCompleteAirplaneID', { read: MatAutocompleteTrigger }) autoCompleteAirplaneID: MatAutocompleteTrigger;
  @ViewChildren('insideClick') insideClickElement: QueryList<ElementRef>;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  constructor(private cd: ChangeDetectorRef,
              private userApiService: UserApiService,
              private appConfigService: AppConfigService,
              private headerService: HeaderService,
              private formBuilder: FormBuilder) { }
  ngOnInit(): void {
    this.onDocumentClick = this.onDocumentClick.bind(this);
    document.addEventListener('click', this.onDocumentClick);
    this.userApiService.getUser().subscribe((user: User) => this.user = user);
    this.initializeFormControl();
    this.applyFilter();
    this.headerGroup.get('searchText').valueChanges.pipe(
      debounceTime(300),
      tap(() => {
        this.searchResults = [];
        this.isLoading = true;
        this.cd.markForCheck();
      }),
      switchMap(value => this.doHeaderSearch(value)
        .pipe(
          finalize(() => {
          }),
        )
      )
    ).subscribe(response => {
      if (response.mbfSearchResults === undefined) {
        this.searchResults = [];
        this.isLoading = false;
      }
      else {
        this.searchResults = response.mbfSearchResults.results;
        this.isLoading = false;
      }
      this.cd.markForCheck();
    });

    this.headerGroup.get('AirplaneID').valueChanges.pipe(
      debounceTime(300),
      tap(() => {
        this.airplaneIdSearchResults = [];
        this.isAirplaneLoading = true;
      }),
      switchMap(value => this.doAirplaneIdSearch(value)
        .pipe(
          finalize(() => {
            this.isAirplaneLoading = false;
          }),
        )
      )
    ).subscribe(response => {
      if (response.mbfSearchResults === undefined) {
        this.airplaneIdSearchResults = [];
      }
      else {
        this.airplaneIdSearchResults = response.mbfSearchResults.results;
      }
      this.cd.markForCheck();
    });
  }
  protected onDocumentClick(event: MouseEvent) {
    const isInside = this.insideClickElement.filter((el: ElementRef) =>
     { if (el && el.nativeElement && this.isContain(el, event)) { return true; } return false; });
    if (isInside && isInside.length > 0) {
      this.menuTrigger.openMenu();
      this.hasFocus = true;
    }
    else {
      this.menuTrigger.closeMenu();
      this.advsearchFilter = false;
      this.hasFocus = false;
    }
    this.cd.markForCheck();
  }
  protected isContain(source: ElementRef, event: MouseEvent) {
    try {
      return source.nativeElement.contains(event.target);
    }
    catch (ex) {
      return false;
    }
  }
  public getSiteServerRelativeUrl(): string {
    const endpoint = this.appConfigService.getPortalNgPath('');
    return endpoint;
  }
  private doAirplaneIdSearch(value: any) {
    const d = {
      queryType: 'airplaneTypeAhead',
      resultsPerPage: 1,
      text: value,
      pageNo: 1,
      sortBy: 'relevance',
      selectedRefinements: []
    };
    return value.length > 2 ? this.headerService.getSearchResult(d) : of([]);
  }

  private doHeaderSearch(value: any) {
    const appliedSelectedCat = this.selectedFilter.selectedCat;
    const selectedCat = appliedSelectedCat ? this.categoryList.find(x => x.id === appliedSelectedCat) : { id: '', value: 'All' };
    if (value.length > 2 && selectedCat && selectedCat.value.toUpperCase() !== 'MESSAGES') {
      const d = {
        pageNo: 1,
        queryType: 'searchTypeAhead',
        resultsPerPage: 1,
        text: value,
        sortBy: 'relevance',
        selectedRefinements: this.selectedRefinements
      };
      this.cd.markForCheck();
      return this.headerService.getSearchResult(d);
    }
    else {
      this.cd.markForCheck();
      return of([]);
    }
  }
  goToSearchPage(searchResult) {
    const searchData = this.getSearchData(searchResult);
    sessionStorage.setItem('mbf_search', JSON.stringify(searchData));
    window.location.href = this.getSiteServerRelativeUrl() + '/#/Platform/searchResults';
  }
  getSearchData(searchResult) {
    const appliedSelectedCat = this.selectedFilter.selectedCat;
    const selectedCat = appliedSelectedCat ? this.categoryList.find(x => x.id === appliedSelectedCat) : { id: '', value: 'All' };
    const searchService: any = {};
    const valId = searchResult ? searchResult.identifier : null;
    searchService.globalSearchCall = true;
    searchService.globalTypeAheadItem = null;
    searchService.searchTypeAheadSelected = true;
    searchService.searchTextTyped = this.headerGroup.get('searchText').value;
    searchService.searchCategory = selectedCat.value;
    searchService.searchCategoryId = selectedCat.id;
    if (searchResult) {
      // tslint:disable-next-line: no-shadowed-variable
      const valId = searchResult.identifier;
      const searchText = searchResult.description;
      searchService.globalTypeAheadItem = searchResult;
      searchService.currentAirplaneSelection = null;
      searchService.currentrefName = null;
      searchService.currentrefValId = null;
      searchService.currentrefValName = null;
      searchService.currentRefinementSelection = null;
      if (searchText.endsWith(' - Serial Number') ||
        searchText.endsWith(' - Basic Effectivity') ||
        searchText.endsWith(' - Line Number') ||
        searchText.endsWith(' - Engine Effectivity') ||
        searchText.endsWith(' - Tail Number') ||
        searchText.endsWith(' - Customer Variable')
      ) {
        const refValId = searchText.substring(0, searchText.indexOf(' - '));
        const refValName = searchText.substring(searchText.indexOf(' - ') + 3, searchText.length);
        searchService.currentrefValId = refValId;

        if (searchText.endsWith(' - Serial Number')) { searchService.currentrefValName = 'Serial_Number'; }
        if (searchText.endsWith(' - Basic Effectivity')) { searchService.currentrefValName = 'Basic_Eff'; }
        if (searchText.endsWith(' - Line Number')) { searchService.currentrefValName = 'Line_No'; }
        if (searchText.endsWith(' - Engine Effectivity')) { searchService.currentrefValName = 'Engine_Eff'; }
        if (searchText.endsWith(' - Tail Number')) { searchService.currentrefValName = 'Tail_No'; }
        if (searchText.endsWith(' - Customer Variable')) { searchService.currentrefValName = 'Customer_Variable'; }
        searchService.currentAirplaneSelection = searchText;
      } else if (valId > 0) {
        // tslint:disable-next-line: no-shadowed-variable
        const searchText = searchResult.description;
        searchService.currentrefName = searchResult.ridentifier;
        searchService.currentrefValId = valId;
        searchService.currentrefValName = searchResult.edescription;

        searchService.currentRefinementSelection = searchText;
      } else {
        searchService.searchText = searchText;
        searchService.searchData = null;
      }
    }
    else {
      searchService.searchText = this.headerGroup.get('searchText').value;
      searchService.searchData = null;
    }
    this.cd.markForCheck();
    return searchService;
  }
  goToApplicationPage(searchResult) {
    const applicationRefinement = {
      refinementName: 'App_Name_Long',
      refinementType: 'list',
      refinementValues: [{
        refValId: searchResult.identifier
      }]
    };
    const d = {
      queryType: 'result',
      resultsPerPage: 1,
      pageNo: 1,
      sortBy: 'relevance',
      selectedRefinements: [applicationRefinement]
    };
    this.headerService.getSearchResult(d).subscribe(response => {
      const url = response.mbfSearchResults.results.length > 0 ? response.mbfSearchResults.results[0].url : '';
      // tslint:disable-next-line: no-unused-expression
      url && (window.open(url, '_blank'));
    });

  }
  displayAirplaneId(selectedAirplane) {
    return selectedAirplane.description;
  }
  advFilterMenuOpened() {
    // tslint:disable-next-line: no-unused-expression
    this.autoCompleteAirplaneID ? this.autoCompleteAirplaneID.closePanel() : '';
    this.headerGroup.patchValue({
      selectedCat: this.selectedFilter.selectedCat,
      AirplaneID: this.selectedFilter.AirplaneID
    });
  }
  onCatChange(event) {
    this.applyFilter();
  }
  applyFilter() {
    this.selectedFilter = { selectedCat: this.headerGroup.get('selectedCat').value, AirplaneID: this.headerGroup.get('AirplaneID').value };
    this.selectedRefinements.length = 0;
    const catRef = this.getCategoryRefinement();
    // tslint:disable-next-line: no-unused-expression
    catRef && this.selectedRefinements.push(catRef);
    const airRef = this.getAirplaneRefinement();
    // tslint:disable-next-line: no-unused-expression
    airRef && this.selectedRefinements.push(airRef);
    this.searchResults = [];
  }

  getAirplaneRefinement() {
    let airplaneRefinement = null;
    const selectedAirplane = this.selectedFilter.AirplaneID;
    try {
      const refValId = selectedAirplane.description.substring(0, selectedAirplane.description.indexOf(' - '));
      const refValName = selectedAirplane.description.substring(selectedAirplane.description.indexOf(' - ') + 3,
      selectedAirplane.description.length);
      airplaneRefinement = {
        refinementName: 'airplaneId',
        refinementType: 'selection',
        refinementValues: [{
          refValId,
          refValName,
          airplaneIdDisplayName: selectedAirplane.airplaneIdDisplayName
        }]
      };
    }
    catch (ex) {

    }
    return airplaneRefinement;
  }
  getCategoryRefinement() {
    const selectedCat = this.categoryList.find(x => x.id === this.selectedFilter.selectedCat);
    let categoryRefinement = null;
    try {
      if (selectedCat) {
        categoryRefinement = {
          refinementName: 'Category',
          refinementType: 'list',
          refinementValues: [{
            refValId: selectedCat.id,
            refValName: selectedCat.value,
            refValLeaf: true
          }]
        };
      }
    }
    catch (ex) {

    }
    return categoryRefinement;
  }
  getTextRefinement() {
    let textRefinement = null;
    const searchTerm = this.headerGroup.get('searchText').value;
    try {
      textRefinement = {
        refinementName: 'Text Search',
        refinementType: 'text',
        refinementValues: [{
          refSearchString: searchTerm
        }]
      };
    }
    catch (ex) {

    }
    return textRefinement;
  }
  getCategories() {
    const catList: any[] = [];
    // tslint:disable-next-line: forin
    for (const c in this.searchCategories) {
      catList.push({
        label: this.searchCategories[c].label,
        value: this.searchCategories[c].value,
        id: this.searchCategories[c].id,
        defaultval: this.searchCategories[c].defaultval,
        searchPrivs: this.searchCategories[c].searchPrivs
      });
    }
    return catList;
  }

  initializeFormControl() {
    this.headerGroup = this.formBuilder.group({
      searchText: new FormControl(''),
      selectedCat: new FormControl(''),
      AirplaneID: new FormControl('')
    });
    this.cd.markForCheck();
  }
}

