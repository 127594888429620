<div class="w-full bg-black-54 pb-6">
    <div class="padFooterDesk bg-black-54">
        <div class="m-auto max-w-screen-lg">
            <div class="flex justify-between">

                <!-- Mobile View -->
                <!-- <div class="md:hidden block w-full pt-4">
                    <mat-list-item *ngFor="let category of linkCategories" class="">
                        <div class="mt-20 mb-4 pt-3.5 ml-8 pb-1 text-left text-base text-white bg-black-54 font-meso uppercase ">
                            <span class="pt-9 ml-8  font-semibold mat-header-letter-spacing text-px-13"> {{category.title}}
              </span>
                        </div>
                        <mat-list-item *ngFor="let link of category.links">
                            <span *ngIf="link.title=='My Field Service Office';then MyFielderviceLink else otherLinks">
              </span>
                            <ng-template #MyFielderviceLink>
                                <div [ngClass]="{'px-5 mx-2 pt-4 pb-2':nonTBCUser}">
                                    <a *ngIf="nonTBCUser" class="ml-10 text-base text-white text-sm-px-12-5 bg-black-54 hover:bg-black-54 font-medium font-meso mat-footer-expansion-content cursor-pointer" (click)="getURL(link.url,link.urlTarget, link.type, link.title)" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}"
                                        href="javascript:void(0);">{{link.title}}</a>
                                </div>
                            </ng-template>
                            <ng-template #otherLinks>
                                <span *ngIf="link.title == 'Terms of Use'; then loadTermBlock else elseBlock"></span>
                                <ng-template #loadTermBlock>
                                    <div class="px-5 mx-2 pt-4 pb-2">
                                        <a (click)="getURL(link.url, link.type, link.title,link.urlTarget)" class="ml-10 text-base text-white text-sm-px-12-5 bg-black-54 hover:bg-black-54 font-medium font-meso mat-footer-expansion-content cursor-pointer" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                    </div>
                                </ng-template>

                                <ng-template #elseBlock>
                                    <span *ngIf="link.title=='Access Administrator Support';then accessAdminLink else noAccessAdminLinks"></span>
                                    <ng-template #accessAdminLink>
                                        <div class="px-5 mx-2 pt-4 pb-2" *ngIf="accessAdministratorFlag">
                                            <a *ngIf="accessAdministratorFlag" class="ml-10 text-base text-white text-sm-px-12-5 bg-black-54 hover:bg-black-54 font-medium font-meso mat-footer-expansion-content cursor-pointer" (click)="getURL(link.url, link.urlTarget, link.type, link.title )" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>

                                        </div>
                                    </ng-template>
                                    <ng-template #noAccessAdminLinks>
                                        <span *ngIf="link.title=='Product and Data Access (Access Summary)'; then accessSummaryLink else noAccessSummaryLinks"></span>
                                        <ng-template #accessSummaryLink>
                                            <div class="px-5 mx-2 pt-4 pb-2">
                                                <a  class="ml-10 text-base text-white text-sm-px-12-5 bg-black-54 hover:bg-black-54 font-medium font-meso mat-footer-expansion-content cursor-pointer" (click)="getURL(link.url, link.urlTarget, link.type, link.title)"
                                                    [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>

                                            </div>
                                        </ng-template>
                                        <ng-template #noAccessSummaryLinks>
                                            <div class="px-5 mx-2 pt-4 pb-2">
                                                <a *ngIf="envConfig | footerBuildLink:link.url:link.type:link.title else noLink; let url" class="ml-10 text-base text-white text-sm-px-12-5 bg-black-54 hover:bg-black-54 font-medium font-meso mat-footer-expansion-content cursor-pointer" (click)="getURL(url,link.urlTarget)"
                                                    [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>

                                            </div>
                                            <ng-template #noLink>
                                                <span class="ml-10 text-base text-gray-400 text-sm-px-12-5 bg-black-54 hover:bg-black-54 font-medium font-meso mat-footer-expansion-content">{{link.title}}</span>
                                            </ng-template>
                                        </ng-template>
                                    </ng-template>

                                </ng-template>
                            </ng-template>
                        </mat-list-item>
                    </mat-list-item>
                </div> -->

                <!-- Tablet View -->

                <!-- <div class="hidden md:block lg:hidden container">
                    <div class="row">
                        <div class=" ft-w-18 pt-20 -ml-16 w-2/4" *ngFor="let category of linkCategories">
                            <div class="text-white text-left text-ffooter font-bold font-meso tracking-widest subpixel-antialiased pb-10 -my-1 uppercase">
                                {{category.title}}
                            </div>
                            <div class="footer-col">
                                <div>
                                    <div *ngFor="let link of category.links">
                                        <span *ngIf="link.title=='My Field Service Office';then MyFielderviceLink else otherLinks">
                </span>
                                        <ng-template #MyFielderviceLink>
                                            <div [ngClass]="{'pb-8':nonTBCUser}">
                                                <a *ngIf="nonTBCUser" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" (click)="getURL(link.url, link.urlTarget, link.type, link.title)" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}" href="javascript:void(0);">{{link.title}}</a>
                                            </div>
                                        </ng-template>
                                        <ng-template #otherLinks>
                                            <span *ngIf="link.title == 'Terms of Use'; then loadTermBlock else elseBlock"></span>
                                            <ng-template #loadTermBlock>
                                                <div class="pb-8">
                                                    <a (click)="getURL(link.url, link.type, link.title,link.urlTarget)" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                                </div>
                                            </ng-template>
                                            <ng-template #elseBlock>
                                                <span *ngIf="link.title=='Access Administrator Support';then accessAdminLink else noAccessAdminLinks"></span>
                                                <ng-template #accessAdminLink>
                                                    <div class="pb-8" *ngIf="accessAdministratorFlag">
                                                        <a *ngIf="accessAdministratorFlag" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" (click)="getURL(link.url, link.urlTarget, link.type, link.title )" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                                    </div>
                                                </ng-template>
                                                <ng-template #noAccessAdminLinks>
                                                    <span *ngIf="link.title=='Product and Data Access (Access Summary)'; then accessSummaryLink else noAccessSummaryLinks"></span>
                                                    <ng-template #accessSummaryLink>
                                                        <div class="pb-8">
                                                            <a (click)="getURL(link.url, link.urlTarget, link.type, link.title)"  class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template #noAccessSummaryLinks>
                                                        <div class="pb-8">
                                                            <a *ngIf="envConfig | footerBuildLink:link.url:link.type:link.title else noLink; let url" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" (click)="getURL(url,link.urlTarget)" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                                        </div>
                                                        <ng-template #noLink>
                                                            <span class="text-ffooter font-meso text-gray-400">{{link.title}}</span>
                                                        </ng-template>
                                                    </ng-template>
                                                </ng-template>

                                            </ng-template>
                                        </ng-template>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                <!--Row 2-->
                    <!-- <div class="row1">
                        <div class="  hidden md:block lg:hidden ft-w-18 pt-5 -ml-16 w-2/4" *ngFor="let category of linkCategories">
                            <div *ngIf="category.title === 'Other Resources'" class=" display:none text-white text-left text-ffooter font-bold font-meso tracking-widest subpixel-antialiased pb-10 -my-1 uppercase">
                                {{category.title}}
                            </div>
                            <div class="footer-col">
                                <div *ngIf="category.title === 'Other Resources'">
                                    <div *ngFor="let link of category.links">
                                        <span *ngIf="link.title=='My Field Service Office';then MyFielderviceLink else otherLinks">
                </span>
                                        <ng-template #MyFielderviceLink>
                                            <div [ngClass]="{'pb-8':nonTBCUser}">
                                                <a *ngIf="nonTBCUser" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" (click)="getURL(link.url, link.urlTarget, link.type, link.title)" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}" href="javascript:void(0);">{{link.title}}</a>
                                            </div>
                                        </ng-template>
                                        <ng-template #otherLinks>
                                            <span *ngIf="link.title == 'Terms of Use'; then loadTermBlock else elseBlock"></span>
                                            <ng-template #loadTermBlock>
                                                <div class="pb-8">
                                                    <a (click)="getURL(link.url, link.type, link.title,link.urlTarget)" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                                </div>
                                            </ng-template>
                                            <ng-template #elseBlock>
                                                <span *ngIf="link.title=='Access Administrator Support';then accessAdminLink else noAccessAdminLinks"></span>
                                                <ng-template #accessAdminLink>
                                                    <div class="pb-8" *ngIf="accessAdministratorFlag">
                                                        <a *ngIf="accessAdministratorFlag" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" (click)="getURL(link.url, link.urlTarget, link.type, link.title )" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                                    </div>
                                                </ng-template>
                                                <ng-template #noAccessAdminLinks>
                                                    <span *ngIf="link.title=='Product and Data Access (Access Summary)'; then accessSummaryLink else noAccessSummaryLinks"></span>
                                                    <ng-template #accessSummaryLink>
                                                        <div class="pb-8">
                                                            <a class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" (click)="getURL(link.url, link.urlTarget, link.type, link.title)" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template #noAccessSummaryLinks>
                                                        <div class="pb-8">
                                                            <a *ngIf="envConfig | footerBuildLink:link.url:link.type:link.title else noLink; let url" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" (click)="getURL(url,link.urlTarget)" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                                        </div>
                                                        <ng-template #noLink>
                                                            <span class="text-ffooter font-meso text-gray-400">{{link.title}}</span>
                                                        </ng-template>
                                                    </ng-template>
                                                </ng-template>

                                            </ng-template>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div> -->

                <!-- Desktop View -->
                <div class="hidden md:hidden lg:block ft-w-18 pt-10" *ngFor="let category of linkCategories">
                    <div class="text-white text-left text-ffooter font-bold font-meso tracking-widest subpixel-antialiased pb-14 uppercase">
                        {{category.title}}</div>
                    <div *ngFor="let link of category.links">
                        <span *ngIf="link.title=='My Field Service Office';then MyFielderviceLink else otherLinks">
            </span>
                        <ng-template #MyFielderviceLink>
                            <div [ngClass]="{'pb-8':nonTBCUser}">
                                <a *ngIf="nonTBCUser" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" (click)="getURL(link.url, link.urlTarget, link.type, link.title)" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}" href="javascript:void(0);">{{link.title}}</a>
                            </div>
                        </ng-template>
                        <ng-template #otherLinks>
                            <span *ngIf="link.title == 'Terms of Use'; then loadTermBlock else elseBlock"></span>
                            <ng-template #loadTermBlock>
                                <div class="pb-8">
                                    <a (click)="getURL(link.url, link.type, link.title,link.urlTarget)" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                </div>
                            </ng-template>
                            <ng-template #elseBlock>

                                <span *ngIf="link.title=='Access Administrator Support';then accessAdminLink else noAccessAdminLinks"></span>
                                <ng-template #accessAdminLink>
                                    <div *ngIf="accessAdministratorFlag" class="pb-8">
                                        <a *ngIf="accessAdministratorFlag" (click)="getURL(link.url, link.urlTarget, link.type, link.title )" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                    </div>
                                </ng-template>
                                <ng-template #noAccessAdminLinks>
                                    <span *ngIf="link.title == 'Product and Data Access (Access Summary)'; then accessSummaryLink else noAccessSummaryLinks"></span>
                                        <ng-template #accessSummaryLink>
                                            <div class="pb-8">
                                                <a (click)="getURL(link.url, link.urlTarget, link.type, link.title)" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                            </div>
                                        </ng-template>

                                        <ng-template #noAccessSummaryLinks>
                                            <div class="pb-8">
                                                <a *ngIf="envConfig | footerBuildLink:link.url:link.type:link.title else noLink; let url" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" (click)="getURL(url,link.urlTarget)" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                            </div>
                                        </ng-template>
                                </ng-template>
                                <ng-template #noLink>
                                    <span class="text-ffooter font-meso text-gray-400">{{link.title}}</span>
                                </ng-template>
                            </ng-template>
                        </ng-template>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="padFooterTab bg-black-54">
        <div class="m-auto max-w-screen-lg">
            <div class="flex justify-between">

                <!-- Tablet View -->

                <div class="hidden md:block lg:hidden container">
                    <div class="row">
                        <div class=" ft-w-18 pt-20 -ml-16 w-2/4" *ngFor="let category of linkCategories">
                            <div class="text-white text-left text-ffooter font-bold font-meso tracking-widest subpixel-antialiased pb-10 -my-1 uppercase">
                                {{category.title}}
                            </div>
                            <div class="footer-col">
                                <div>
                                    <div *ngFor="let link of category.links">
                                        <span *ngIf="link.title=='My Field Service Office';then MyFielderviceLink else otherLinks">
                </span>
                                        <ng-template #MyFielderviceLink>
                                            <div [ngClass]="{'pb-8':nonTBCUser}">
                                                <a *ngIf="nonTBCUser" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" (click)="getURL(link.url, link.urlTarget, link.type, link.title)" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}" href="javascript:void(0);">{{link.title}}</a>
                                            </div>
                                        </ng-template>
                                        <ng-template #otherLinks>
                                            <span *ngIf="link.title == 'Terms of Use'; then loadTermBlock else elseBlock"></span>
                                            <ng-template #loadTermBlock>
                                                <div class="pb-8">
                                                    <a (click)="getURL(link.url, link.type, link.title,link.urlTarget)" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                                </div>
                                            </ng-template>
                                            <ng-template #elseBlock>
                                                <span *ngIf="link.title=='Access Administrator Support';then accessAdminLink else noAccessAdminLinks"></span>
                                                <ng-template #accessAdminLink>
                                                    <div class="pb-8" *ngIf="accessAdministratorFlag">
                                                        <a *ngIf="accessAdministratorFlag" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" (click)="getURL(link.url, link.urlTarget, link.type, link.title )" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                                    </div>
                                                </ng-template>
                                                <ng-template #noAccessAdminLinks>
                                                    <span *ngIf="link.title=='Product and Data Access (Access Summary)'; then accessSummaryLink else noAccessSummaryLinks"></span>
                                                    <ng-template #accessSummaryLink>
                                                        <div class="pb-8">
                                                            <a (click)="getURL(link.url, link.urlTarget, link.type, link.title)"  class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                                        </div>
                                                    </ng-template>
                                                    <ng-template #noAccessSummaryLinks>
                                                        <div class="pb-8">
                                                            <a *ngIf="envConfig | footerBuildLink:link.url:link.type:link.title else noLink; let url" class="text-ffooter font-meso text-white mat-footer-highlight cursor-pointer" (click)="getURL(url,link.urlTarget)" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                                        </div>
                                                        <ng-template #noLink>
                                                            <span class="text-ffooter font-meso text-gray-400">{{link.title}}</span>
                                                        </ng-template>
                                                    </ng-template>
                                                </ng-template>

                                            </ng-template>
                                        </ng-template>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="md:px-1/10 bg-black-54">
        <div class="m-auto max-w-screen-lg">
            <div class="flex justify-between">

                <!-- Mobile View -->
                <div class="md:hidden block w-full pt-4">
                    <mat-list-item *ngFor="let category of linkCategories" class="">
                        <div class="mt-20 mb-4 pt-3.5 ml-8 pb-1 text-left text-base text-white bg-black-54 font-meso uppercase ">
                            <span class="pt-9 ml-8  font-semibold mat-header-letter-spacing text-px-13"> {{category.title}}
              </span>
                        </div>
                        <mat-list-item *ngFor="let link of category.links">
                            <span *ngIf="link.title=='My Field Service Office';then MyFielderviceLink else otherLinks">
              </span>
                            <ng-template #MyFielderviceLink>
                                <div [ngClass]="{'px-5 mx-2 pt-4 pb-2':nonTBCUser}">
                                    <a *ngIf="nonTBCUser" class="ml-10 text-base text-white text-sm-px-12-5 bg-black-54 hover:bg-black-54 font-medium font-meso mat-footer-expansion-content cursor-pointer" (click)="getURL(link.url,link.urlTarget, link.type, link.title)" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}"
                                        href="javascript:void(0);">{{link.title}}</a>
                                </div>
                            </ng-template>
                            <ng-template #otherLinks>
                                <span *ngIf="link.title == 'Terms of Use'; then loadTermBlock else elseBlock"></span>
                                <ng-template #loadTermBlock>
                                    <div class="px-5 mx-2 pt-4 pb-2">
                                        <a (click)="getURL(link.url, link.type, link.title,link.urlTarget)" class="ml-10 text-base text-white text-sm-px-12-5 bg-black-54 hover:bg-black-54 font-medium font-meso mat-footer-expansion-content cursor-pointer" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>
                                    </div>
                                </ng-template>

                                <ng-template #elseBlock>
                                    <span *ngIf="link.title=='Access Administrator Support';then accessAdminLink else noAccessAdminLinks"></span>
                                    <ng-template #accessAdminLink>
                                        <div class="px-5 mx-2 pt-4 pb-2" *ngIf="accessAdministratorFlag">
                                            <a *ngIf="accessAdministratorFlag" class="ml-10 text-base text-white text-sm-px-12-5 bg-black-54 hover:bg-black-54 font-medium font-meso mat-footer-expansion-content cursor-pointer" (click)="getURL(link.url, link.urlTarget, link.type, link.title )" [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>

                                        </div>
                                    </ng-template>
                                    <ng-template #noAccessAdminLinks>
                                        <span *ngIf="link.title=='Product and Data Access (Access Summary)'; then accessSummaryLink else noAccessSummaryLinks"></span>
                                        <ng-template #accessSummaryLink>
                                            <div class="px-5 mx-2 pt-4 pb-2">
                                                <a  class="ml-10 text-base text-white text-sm-px-12-5 bg-black-54 hover:bg-black-54 font-medium font-meso mat-footer-expansion-content cursor-pointer" (click)="getURL(link.url, link.urlTarget, link.type, link.title)"
                                                    [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>

                                            </div>
                                        </ng-template>
                                        <ng-template #noAccessSummaryLinks>
                                            <div class="px-5 mx-2 pt-4 pb-2">
                                                <a *ngIf="envConfig | footerBuildLink:link.url:link.type:link.title else noLink; let url" class="ml-10 text-base text-white text-sm-px-12-5 bg-black-54 hover:bg-black-54 font-medium font-meso mat-footer-expansion-content cursor-pointer" (click)="getURL(url,link.urlTarget)"
                                                    [analyticTagging]="{'eventId':'footerNavFn','srcCtrl':[category,link]}">{{link.title}}</a>

                                            </div>
                                            <ng-template #noLink>
                                                <span class="ml-10 text-base text-gray-400 text-sm-px-12-5 bg-black-54 hover:bg-black-54 font-medium font-meso mat-footer-expansion-content">{{link.title}}</span>
                                            </ng-template>
                                        </ng-template>
                                    </ng-template>

                                </ng-template>
                            </ng-template>
                        </mat-list-item>
                    </mat-list-item>
                </div>
            </div>
        </div>
    </div>
</div>

<app-nxg-skinny-footer></app-nxg-skinny-footer>
