import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { WidgetDialogComponent } from '../../workspace-widget/dialogs/widget-dialog/widget-dialog.component';
import { WorkspaceOptionsService } from '../workspace-options.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { AppListingService } from 'src/app/app-listing/services/app-listing.service';

@Component({
  selector: 'app-reset-home-to-default-dialog',
  templateUrl: './reset-home-to-default-dialog.component.html',
  styleUrls: ['./reset-home-to-default-dialog.component.css']
})
export class ResetHomeToDefaultDialogComponent implements OnInit {
  isWorkspaceIdAvailable = false;
  isWorkspacePage: boolean;
  loading: boolean;
  updateWorkspaceData: boolean;
  workspaceId: string;
  workspaceType: string;

  constructor(
    public dialogRef: MatDialogRef<ResetHomeToDefaultDialogComponent>,
    public dialog: MatDialog,
    public workspaceOptionsService: WorkspaceOptionsService,
    public workspaceService: WorkspaceService, private appListingService: AppListingService) { }

  ngOnInit(): void {
    const url = window.location.href;
    this.isWorkspacePage = url.includes('Platform/workspace');
    this.getWorkspaceId();
  }

  getWorkspaceId() {
    if (this.isWorkspacePage) {
      this.workspaceService.workspaceData.subscribe((workspace: any) => {
        if (workspace) {
          this.workspaceType = workspace.type;
          if (this.workspaceType === 'BasicWorkspace') {
            this.workspaceId = workspace.id;
          } else if (this.workspaceType === 'SingleColumnWorkspace') {
            for (const item of workspace.layout) {
              if (item.type === 'BasicWorkspace') {
                this.workspaceId = item.id;
              }
            }
          }
          this.updateWorkspaceData = true;
          this.isWorkspaceIdAvailable = true;
        }
      });
    }
  }

  doAction() {
    this.loading = true;
    if (this.isWorkspaceIdAvailable) {
      this.resetWorkspace();
    } else {
      this.getDefaultWorkspace();
    }
  }

  resetWorkspace() {
    this.workspaceOptionsService.resetWorkspace(this.workspaceId).subscribe((response: any) => {
      if (response) {
        if (this.updateWorkspaceData) {
          this.refreshWorkspace(response.data);
        }
        this.dialogRef.close();
        this.loading = false;
        this.openConfirmDialog('Your workspace has been restored.', true);
      }
      // tslint:disable-next-line: no-shadowed-variable
      this.appListingService.getAppList([], true, true).subscribe((response: any) => { });
    });
  }

  refreshWorkspace(workspace: any) {
    if (this.workspaceType === 'BasicWorkspace') {
      this.workspaceService.setBasicWorkspace(workspace.layout);
    } else if (this.workspaceType === 'SingleColumnWorkspace') {
      this.workspaceService.setSingleColumWorkspace(workspace.id, workspace);
    }
  }

  openConfirmDialog(title: string, success: boolean) {
    const dialogRef = this.dialog.open(WidgetDialogComponent, {
      data: { Title: title, isSuccess: success }
    });
    setTimeout(() => {
      dialogRef.close();
    }, 3000);
  }

  getDefaultWorkspace() {
    this.workspaceService.getDefaultWorkspace().subscribe((workspace: any) => {
      if (workspace.data) {
        this.workspaceType = workspace.data.type;
        if (this.workspaceType === 'BasicWorkspace') {
          this.workspaceId = workspace.data.id;
        } else if (this.workspaceType === 'SingleColumnWorkspace') {
          for (const item of workspace.data.layout) {
            if (item.type === 'BasicWorkspace') {
              this.workspaceId = item.id;
            }
          }
        }
        this.updateWorkspaceData = false;
        this.resetWorkspace();
      }
    });
  }

}
