import { Component, ViewChild, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { HeaderBaseComponent } from './header-base.component';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/core/app-config.service';
import { UserApiService } from 'src/app/user/user-api.service';
import { User } from 'src/app/user/user';
import { UserRolesItem } from 'src/app/user/user-roles-item';
import { HeaderService } from 'src/app/header/header.service';
import { LogoutService } from '../services/logout/logout.service';
import { HeaderData, HeaderMenu, HeaderSubmenu } from './header-data';
import { MatDialog } from '@angular/material/dialog';
import { AddFolderDialogComponent } from '../workspace-widget/basic-folder/add-folder-dialog/add-folder-dialog.component';
import { ViewFolderArchiveDialogComponent } from '../workspace-options/view-folder-archive-dialog/view-folder-archive-dialog.component';
import {
  ResetHomeToDefaultDialogComponent
} from '../workspace-options/reset-home-to-default-dialog/reset-home-to-default-dialog.component';
import { AddLinkDialogComponent } from '../workspace-widget/drag-drop/add-link-dialog/add-link-dialog.component';
import { Location } from '@angular/common';
import { NavigationEnd, Router, Event } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatMenuTrigger } from '@angular/material/menu';
import { UserPortalMessageFormat } from './user-portal-message-interface';
import { PollingTimerService } from '../services/shared/polling-timer/polling-timer.service';
import { PollingItem } from '../services/shared/polling-timer/polling-interface';
import { memoize, values } from 'lodash';
import { WorkspaceService } from '../workspace/workspace.service';
import {
  SystemMessageDialogBoxComponent
} from '../admin/system-message/components/system-messages-display-view/system-message-dialog-box/system-message-dialog-box.component';
import { TutorialDialogComponent } from './tutorial-dialog/tutorial-dialog.component';
import { SearchResultsTutorialDialogComponent } from 'src/app/header/search-results-tutorial-dialog/search-results-tutorial-dialog.component';
import { SearchResultsService } from 'src/app/services/search-results/search-results.service';
import { ApplistTutorialDialogComponent } from './applist-tutorial-dialog/applist-tutorial-dialog.component';
import { YourAccountService } from '../services/your-account/your-account.service';
import { CustomSnackbarComponent } from '../notification/custom-snackbar/custom-snackbar.component';
import { environment } from 'src/environments/environment';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-header-mbf',
  templateUrl: './header-mbf.component.html',
  styleUrls: ['./header-mbf.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderMBFComponent extends HeaderBaseComponent implements OnDestroy {
  headerData: HeaderData;
  primaryMenu: HeaderMenu[] = [] as HeaderMenu[];
  secondaryMenu: HeaderMenu[] = [] as HeaderMenu[];
  secondaryBannerLeft: HeaderSubmenu[] = [] as HeaderSubmenu[];
  helpItems: HeaderSubmenu[] = [] as HeaderSubmenu[];
  workspaceOptionMenu: HeaderSubmenu[] = [] as HeaderSubmenu[];
  dashboardItems: HeaderSubmenu[] = [] as HeaderSubmenu[];
  communitiesItems: HeaderSubmenu[] = [] as HeaderSubmenu[];
  user: User = {
    firstName: '',
    lastName: '',
    userRoles: [] as UserRolesItem[],
    formalCompanyName: ''
  } as User;
  envConfig = {};
  logoutDialog: any;
  logoutUrl = '';
  urlItems: string[] = (window.location.href).split('/');
  notifications: UserPortalMessageFormat[] = [] as UserPortalMessageFormat[];
  unreadMessages: UserPortalMessageFormat[] = [];
  userNotificationMessages: UserPortalMessageFormat[] = [];
  unreadNotifications = 0;
  unreadNotificationsAvailable = false;
  undismissedNotificationsAvailable = false;
  dismissJobRoleSurvey = false;

  dataRefresher: any;
  showSnackbar = true;
  @ViewChild('notificationTrigger') notificationTrigger: MatMenuTrigger;
  @ViewChild('accountTrigger') accountTrigger: MatMenuTrigger;
  @ViewChild('linkTrigger') linkTrigger: MatMenuTrigger;
  snackBarConent: string;
  currentTab = '';
  viewSearchBar = false;
  searchCloseBox = false;
  displayHelpOptions = false;
  displayMenuOptions = false;
  displayNotificationOptions = false;
  displayActionOptions = false;
  snackBarDismiss = true;
  environment: string;
  yourAccountLink = '';
  pollingItems: PollingItem[] = [{ key: 'notifications', callback: () => { this.refreshUserAndWorkspaces(); } }];
  searchBar: boolean;
  memoizeGetmessageType = memoize(this.getNotificationsHeader, (...args) => values(args).join(''));
  memoizeGetBuildUrl = memoize((item: HeaderSubmenu) => this.buildUrl(item), (...args) => args[0].title);

  currentOpenedSnackbarId = '';
  mode = true;
  isSmallScreen: boolean;

  public getScreenWidth: any;
  mobile: boolean;
  showSearch = false;
  hideSearch = true;
  showClose = false;
  constructor(
    cd: ChangeDetectorRef,
    headerService: HeaderService,
    public appConfigService: AppConfigService,
    userApiService: UserApiService,
    matDialog: MatDialog,
    httpClient: HttpClient,
    router: Router,
    location: Location,
    snackBar: MatSnackBar,
    public workspaceService: WorkspaceService,
    private pollingTimerService: PollingTimerService,
    private searchResultsService: SearchResultsService,
    private yourAccountService: YourAccountService,
    logoutService: LogoutService) {
    super(cd, headerService, appConfigService, userApiService, matDialog,
      httpClient, router, location, workspaceService, snackBar, logoutService);
    this.yourAccountService.enableHeaderNotification.subscribe(() => {
      this.enableGetNotifications();
    });
    this.getYourAccountLinks();
    this.headerService.getOpenSideMenu().subscribe(response => {
      this.showClose = response;
      this.cd.markForCheck();
    });
    this.router.events.subscribe((routeEvent) => { this.onRouteEvent(routeEvent); });
    const hasSeenSurvey = sessionStorage.getItem('sentToSurvey');
    if (!hasSeenSurvey || hasSeenSurvey === '0') {
      this.userApiService.checkSurveyEligibility().subscribe(response => {
        if (response) {
          this.showSnackbar = false;
        } else {
          this.showSnackbar = true;
        }
      });
    } else {
      this.showSnackbar = true;
    }

    this.checkAccessAdminiPriviledge();
    this.refreshUserAndWorkspace();
    this.pollingTimerService.registerPolling(this.pollingItems);
    this.setCurrentPage(router.url);
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.setCurrentPage(router.url);
      }
      this.cd.markForCheck();
    });
    this.headerSideMenuBar();
    this.headerService.viewSearchData.subscribe((response) => {
      this.searchBar = response;
      this.cd.markForCheck();
    });
    this.headerService.undismissedNotificationsAvailable.subscribe((data) => {
      this.undismissedNotificationsAvailable = data;
      this.cd.markForCheck();
    });
    this.headerService.notificationMarkedRead.subscribe((data) => {
      if (data) {
        this.getNotifications(false);
      }
    });
    this.loadNotificationEvents();

    this.screenChange();
  }
  private screenChange(){
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth < 600){
      this.mobile = true;
    }
    else{
      this.mobile = false;
    }
  }
  private onRouteEvent(routeEvent: Event) {
    if (routeEvent instanceof NavigationEnd) {
      if (this.isPageView() !== 'Platform/browserEOL') {
        const hasSeenSurvey = sessionStorage.getItem('sentToSurvey');
        if (!hasSeenSurvey || hasSeenSurvey === '0') {
          this.userApiService.checkSurveyEligibility().subscribe(response => {
            if (response) {
              this.showSnackbar = false;
            } else {
              this.showSnackbar = true;
            }
          });
        } else {
          this.showSnackbar = true;
        }
      } else {
        this.showSnackbar = false;
      }
    }
  }
  private isPageView() {
    let isPageView = '';
    try {
      isPageView = this.router.routerState.root.snapshot.firstChild.routeConfig.path;
    }
    catch (ex) { }
    return isPageView;
  }
  ngOnDestroy() {
    this.pollingTimerService.deregisterPolling(this.pollingItems);
  }
  refreshUserAndWorkspace(): void {
    this.userApiService.getUserAndWorkspace().subscribe((user: User) => {
      this.user = user;
      this.setupProfileMenuMessage();
      this.getNotifications(true);
      this.cd.markForCheck();
      this.workspaceService.userRefreshed.next(true);
    });
  }
  refreshUserAndWorkspaces(): void {
    this.userApiService.getUserAndWorkspace().subscribe((user: User) => {
      this.user = user;
      this.setupProfileMenuMessage();
      this.showSnackbar = true;
      this.snackBar.dismiss();
      this.getNotifications(false);
      this.headerService.setNotificationsUpdated(true);
      this.cd.markForCheck();
      this.workspaceService.userRefreshed.next(true);
    });
  }
  setCurrentPage(url: string) {
    if (url === '/' || url.indexOf('Platform/workspace') !== -1) {
      this.currentTab = 'MBF Home';
    }
    else if (url.indexOf('/Admin') !== -1) {
      this.currentTab = 'Admin';
    } else if (url.indexOf('Platform/apps') !== -1) {
      this.currentTab = 'Applications';
    } else if (url.indexOf('YourAccount') !== -1) {
      this.currentTab = 'Your ' + (this.envConfig[`oemPlatformAcronym`] !== undefined ? this.envConfig[`oemPlatformAcronym`] : 'MBF') +
        ' Account';
    } else if (url.indexOf('Platform/trainingHub') !== -1) {
      this.currentTab = 'Training';
    } else {
      this.currentTab = '';
    }
    if (url.indexOf('Platform/jobRoleSurvey') !== -1) {
      this.dismissJobRoleSurvey = true;
    } else {
      this.dismissJobRoleSurvey = false;
    }
    this.cd.markForCheck();
  }

  buildUrl(item: HeaderSubmenu) {
    let url = item.url;
    // if (item.title === this.appConfigService.getProperty(`partpageTitle`)) {
    //   url = this.envConfig[`partpageUrl`];
    // }
    if (item.title === this.appConfigService.getProperty(`mbfSupportPageTitle`)) {
      url = `#/Platform/psp/${this.envConfig['mbfPspId']}`;
    }
    if (item.title === 'Access Administrator Support') {
      url = this.buildAccessAdminLink(item);
    }
    if (item.title === 'Product and Data Access (Access Summary)') {
      url = this.buildAccessSummaryLink();
    }
    if (item.title === 'Introduction to MBF') {
      // tslint:disable-next-line: no-string-literal
      url = this.envConfig['mbfIntroUrl'];
    }
    return this.buildUrlFromString(url, item.type);
  }
  buildAccessAdminLink(item: HeaderSubmenu) {
    let url = '';
    const accessAdminUrl = 'accessAdminUrl';
    const mbfAdminAccessId = this.envConfig[accessAdminUrl];
    url = `#/Platform/psp/${mbfAdminAccessId}`;
    return url;
  }

  buildAccessSummaryLink() {
    let url = '';
    const accessSummaryUrl = 'accessSummaryUrl';
    url = this.envConfig[accessSummaryUrl];
    return url;
  }

  urlClicked(item: HeaderSubmenu) {
    const url: string = this.buildUrl(item);
    if (url.toLowerCase().endsWith(window.location.hash.toLowerCase())) {
      window.scrollTo(0, 0);
    } else {
      if (item.title === 'My Field Service Office') {
      //  window.localStorage.setItem('fsoOpen', 'true');
      }
      window.open(url, item.target ? item.target : '_self');
    }
    return false;
  }
  openDashboardDialog(item: HeaderSubmenu) {
    this.closeSideMenu();
    if (item.title === 'Add a folder') {
      const obj = {
        action: 'Add'
      };
      this.matDialog.open(AddFolderDialogComponent, {
        data: obj,
        disableClose: true
      });
    } else if (item.title === 'Add a link') {
      const obj = {
        action: 'Add'
      };
      this.matDialog.open(AddLinkDialogComponent, {
        data: obj,
        disableClose: true
      });
    } else if (item.title === 'View folder archive') {
      this.matDialog.open(ViewFolderArchiveDialogComponent);
    } else if (item.title === 'Reset home page to default') {
      this.matDialog.open(ResetHomeToDefaultDialogComponent, {
        disableClose: true,
        autoFocus: false
      });
    }
  }

  getNotifications(fromCache: boolean): void {
    this.headerService.getActiveNotifications(fromCache).subscribe((response: any) => {
      this.notifications = [];
      this.unreadMessages = [];
      this.userNotificationMessages = [];
      const unreadNotifications: UserPortalMessageFormat[] = [];
      const plannedSystemMessages: UserPortalMessageFormat[] = [];
      const unPlannedSystemMessages: UserPortalMessageFormat[] = [];
      const infoSystemMessages: UserPortalMessageFormat[] = [];
      const undismissedNotifications: UserPortalMessageFormat[] = [];
      if (response) {
        for (const notification of response) {
          if (!notification.messageRead) {
            unreadNotifications.push(notification);
          }
          if (!notification.messageDismissed && notification.messageType !== 'USER_NOTIFICATION') {
            undismissedNotifications.push(notification);
          }
          if (notification.messageType === 'UNPLANNED_OUTAGE') {
            unPlannedSystemMessages.push(notification);
          } else if (notification.messageType === 'PLANNED_OUTAGE') {
            plannedSystemMessages.push(notification);
          } else if (notification.messageType === 'INFORMATIONAL') {
            infoSystemMessages.push(notification);
          } else if (notification.messageType === 'USER_NOTIFICATION') {
            this.userNotificationMessages.push(notification);
          } else if (notification.messageType === 'TRAINING') {
            this.userNotificationMessages.push(notification);
          } else if (notification.messageType === 'ACTION_REQUIRED') {
            this.userNotificationMessages.push(notification);
          }
        }
      }
      this.notifications = unPlannedSystemMessages.concat(plannedSystemMessages)
        .concat(infoSystemMessages).concat(this.userNotificationMessages);
      this.headerService.setUndismissedNotificationsAvailable(undismissedNotifications.length > 0);
      this.unreadMessages = unreadNotifications;
      this.updateCount();
      this.isRefresh = false;
      for (const notification of this.notifications) {
        if (!notification.messageDismissed && notification.messageType !== 'USER_NOTIFICATION') {
          if (this.showSnackbar && this.unreadNotificationsAvailable && !this.dismissJobRoleSurvey) {
            if (this.currentOpenedSnackbarId === '' || this.currentOpenedSnackbarId !== notification.messageId) {
              this.currentOpenedSnackbarId = notification.messageId;
              this.openSnackBar(notification);
            }
          }
          localStorage.setItem('welcomeMsgAndNotificationDisplayed', 'true');
          break;
        }
      }
    });
  }

  updateCount(): void {
    this.unreadNotifications = this.unreadMessages.length;
    this.unreadNotificationsAvailable = this.unreadNotifications > 0;
    this.cd.markForCheck();
  }

  getNotificationsHeader(messageType: string): string {
    if (messageType === 'UNPLANNED_OUTAGE') {
      return 'UNPLANNED OUTAGE';
    } else if (messageType === 'PLANNED_OUTAGE') {
      return 'PLANNED OUTAGE';
    } else if (messageType === 'USER_NOTIFICATION' || messageType === 'INFORMATIONAL') {
      return 'INFORMATION';
    } else if (messageType === 'TRAINING') {
      return 'TRAINING';
    } else if (messageType === 'ACTION_REQUIRED') {
      return 'ACTION REQUIRED';
    }
    this.cd.markForCheck();
  }

  deleteNotification(item: UserPortalMessageFormat): void {
    this.isRefresh = true;
    this.headerService.deleteNotification(item.messageId).subscribe((response: any) => {
      this.getNotifications(false);
    });
    this.cd.markForCheck();
  }

  /**
   * Method to handle Notification details pop up
   * @param action Type of Action from Dialog
   * @param item Notification details
   */
  openNotificationDetailsDialog(action: string, item: UserPortalMessageFormat) {
    this.closeSideMenu();
    const dataToDialog: any = item;
    dataToDialog.action = action;
    const dialogRef = this.matDialog.open(SystemMessageDialogBoxComponent, {
      data: dataToDialog
    });
    // tslint:disable-next-line: no-string-literal
    dialogRef['_overlayRef'].overlayElement.parentElement.style.cssText += 'z-index: 2000 !important';
    if (!item.messageRead) {
      this.headerService.markAsReadNotification(item.messageId).subscribe((response: any) => {
        this.getNotifications(false);
      });
    }
    dialogRef.afterClosed().subscribe(result => {
      if (result.event === 'Details') {
        this.deleteNotification(result.dialogData);
      }
      this.cd.markForCheck();
    });
  }

  // tslint:disable-next-line: member-ordering
  notification = null;
  openSnackBar(notification: any): void {
    this.showSnackbar = false;
    this.notification = notification;
    const horizontalPositionVal: MatSnackBarHorizontalPosition = 'right';
    const verticalPositionVal: MatSnackBarVerticalPosition = 'top';
    let snackBarRef: MatSnackBarRef<CustomSnackbarComponent>;
    if (notification.messageType === 'UNPLANNED_OUTAGE') {
      snackBarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
        duration: 0,
        horizontalPosition: horizontalPositionVal,
        verticalPosition: verticalPositionVal,
        panelClass: ['notification-custom-snackbar', 'unplanned-snackbar-color-band'],
        data: notification
      });
    } else if (notification.messageType === 'PLANNED_OUTAGE') {
      snackBarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
        duration: 0,
        horizontalPosition: horizontalPositionVal,
        verticalPosition: verticalPositionVal,
        panelClass: ['notification-custom-snackbar', 'planned-snackbar-color-band'],
        data: notification
      });
    } else if (notification.messageType === 'INFORMATIONAL') {
      snackBarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
        duration: 0,
        horizontalPosition: horizontalPositionVal,
        verticalPosition: verticalPositionVal,
        panelClass: ['notification-custom-snackbar', 'information-snackbar-color-band'],
        data: notification
      });
    } else if (notification.messageType === 'TRAINING') {
      snackBarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
        duration: 0,
        horizontalPosition: horizontalPositionVal,
        verticalPosition: verticalPositionVal,
        panelClass: ['notification-custom-snackbar', 'training-snackbar-color-band'],
        data: notification
      });
    } else if (notification.messageType === 'ACTION_REQUIRED') {
      snackBarRef = this.snackBar.openFromComponent(CustomSnackbarComponent, {
        duration: 0,
        horizontalPosition: horizontalPositionVal,
        verticalPosition: verticalPositionVal,
        panelClass: ['notification-custom-snackbar', 'action-req-snackbar-color-band'],
        data: notification
      });
    }
    // tslint:disable-next-line: no-string-literal
    snackBarRef['_overlayRef'].overlayElement.parentElement.style.cssText += 'z-index: 0 !important';
  }

  loadNotificationEvents() {
    this.headerService.learnMoreClicked.subscribe((data: boolean) => {
      if (data) {
        this.snackBar.dismiss();
        this.showSnackbar = true;
        this.headerService.setLearnMoreClicked(false);
        if (this.currentOpenedSnackbarId === this.notification.messageId) {
          this.openNotificationDetailsDialog('Details', this.notification);
        }
        this.getNotifications(false);
      }
    });
    this.headerService.dismissClicked.subscribe((data: boolean) => {
      if (data) {
        this.snackBar.dismiss();
        this.showSnackbar = true;
        this.currentOpenedSnackbarId = '';
        this.headerService.setDismissClicked(false);
        this.headerService.dismissNotification(this.notification.messageId).subscribe((dismissdata) => {
          if (dismissdata) {
            this.getNotifications(false);
          }
        });
      }
    });
  }
  clearUpdates(): void {
    this.isRefresh = true;
    const messageIds = this.unreadMessages.map(a => a.messageId);
    this.headerService.clearUpdates(messageIds).subscribe((response: any) => {
      this.getNotifications(false);
    });
  }
  openSideNav(): void {
    this.displayHelpOptions = false;
    this.displayNotificationOptions = false;
    this.displayMenuOptions = false;
    this.displayActionOptions = false;
    this.headerService.setopenSideMenu(true);
  }

  displayHelp(value: boolean): void {
    this.displayMenuOptions = value;
    this.displayHelpOptions = value;
  }

  /**
   * Display the Notification options in side navigation bar
   * @param value Boolean value
   */
  notificationOptions(value: boolean): void {
    this.displayMenuOptions = value;
    this.displayNotificationOptions = value;
  }

  /**
   * Display the action menu options in side navigation bar
   * @param value Boolean value
   */
  actionOptions(value: boolean): void {
    this.displayMenuOptions = value;
    this.displayActionOptions = value;
  }

  /**
   * Hide the activated menu options and show the default options
   */
  closeSideMenu(): void {
    this.headerService.setopenSideMenu(false);
    this.displayHelpOptions = false;
    this.displayNotificationOptions = false;
    this.displayMenuOptions = false;
    this.displayActionOptions = false;
    this.headerService.setopenNotificationData(false);
  }

  headerSideMenuBar() {
    // To open the side menu bar
    this.headerService.openSideMenuValue.subscribe((response: any) => {
      if (response) {
        if (this.headerService.openNotificationData.value) {
          // Open the side menu bar with notifications
          this.displayMenuOptions = true;
          this.displayNotificationOptions = true;
          this.displayHelpOptions = false;
          this.snackBarDismiss = false;
          this.displayActionOptions = false;
        } else {
          // open the side menu bar with default menu options
          this.displayHelpOptions = false;
          this.displayNotificationOptions = false;
          this.displayMenuOptions = false;
          this.displayActionOptions = false;
        }
      } else {
        if (this.accountTrigger) {
          this.accountTrigger.closeMenu();
          this.linkTrigger.closeMenu();
        }
      }
    });
  }

  /**
   * On click of link icon Close the account menu
   */
  closeAccountMenu() {
    if (this.accountTrigger.menuOpen) {
      this.accountTrigger.closeMenu();
    }
  }

  /**
   * On click of account icon Close the link menu
   */
  closeLinkMenu() {
    if (this.linkTrigger.menuOpen) {
      this.linkTrigger.closeMenu();
    }
  }


  openTutorialDialog(obj) {
    if (window.location.href.includes('/Platform/searchResults')) {
      this.searchResultsService.setTextSearchRequest('Training');
      this.router.navigate(['Platform/searchResults']);
      this.matDialog.open(SearchResultsTutorialDialogComponent, {
        data: obj, panelClass: 'search-tutorial-dialog-cls',
        disableClose: true, id: 'search-tutorial-dlg',
        closeOnNavigation: true
      });
    } else if (window.location.href.includes('/Platform/apps')) {
      this.matDialog.open(ApplistTutorialDialogComponent, {
        data: obj, panelClass: 'tutorial-dialog-cls',
        disableClose: true, id: 'tutorial-dlg',
        closeOnNavigation: true
      });
    } else {
      this.matDialog.open(TutorialDialogComponent, {
        data: obj, panelClass: 'tutorial-dialog-cls',
        disableClose: true, id: 'tutorial-dlg',
        closeOnNavigation: true
      });
    }
  }

  enableGetNotifications() {
    this.showSnackbar = true;
    this.openSnackBar(this.notifications);
  }

  /**
   * Method to call on window resize to update the notifications from cache
   */
  @HostListener('window:resize', ['$event'])
  onResize() {

    if (window.innerWidth < 600) {
      this.mobile = true;
      this.screenChange();
    } else if (window.innerWidth >= 600) {
      this.mobile = false;
      this.screenChange();
    }
    this.getNotifications(true);
  }
  checkAccessAdminiPriviledge() {
    let envConfigAccessAdmin = '';
    const environmentString = 'environment';
    const accessAdminUrl = 'accessAdminUrl';
    this.appConfigService.envConfig.subscribe(config => {
      if (config[accessAdminUrl]) {
        this.envConfig = config;
        envConfigAccessAdmin = config[accessAdminUrl];
        if (envConfigAccessAdmin === '') {
          this.accessAdministratorFlag = false;
        }
        else {
          this.accessAdministratorFlag = true;
        }
      }
      else {
        this.accessAdministratorFlag = false;
      }
    });
  }

  updateShowSearchValue(value: boolean) {
    this.showSearch = value;
    this.hideSearch = true;
  }

  search() {
    this.showSearch = true;
    this.searchBar = true;
    this.hideSearch = false;
  }

      getYourAccountLinks() {
        let env: string = "prod";
        this.yourAccountLink = 'https://accounts.boeing.com';
        const environmentString = 'environment';
        this.appConfigService.envConfig.subscribe(config => {
          if (config[environmentString]) {
            env = config[environmentString];
          }

          this.appConfigService.fetchComponentConfig('your-account-links').subscribe((result: any) => {
            const envLink = result.yourAccountLinks;
            this.yourAccountLink = envLink[env] ? envLink[env] : this.yourAccountLink;
          });
        });
      }

      dislayTitle(str: string) {
        if (str !== undefined) {
          if (str.length > 25) {
            str = str.substring(0, 25);
            return str + '...';
          }
          else {
            return str;
          }
        }
        else {
          return '';
        }
      }
      openOrCloseSideNav() {
        this.showClose = !this.showClose;
        if (this.showClose) {
          this.openSideNav();
        } else {
          this.closeSideMenu();
        }
      }
}
