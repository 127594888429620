import { Pipe, PipeTransform } from '@angular/core';
import { HeaderSubmenu } from 'src/app/header/header-data';

@Pipe({
  name: 'headerAdminLink'
})
export class HeaderAdminLinkPipe implements PipeTransform {

  transform(item: HeaderSubmenu, user: any, envConfig: any): any {
    return this.IfAdminLink(item, user, envConfig);
  }

   // Checking user permission to show/hide Admin Tab
   IfAdminLink(item: HeaderSubmenu, user: any, envConfig: any) {
    if (item.title.toLowerCase() === 'admin') {
      if (user.userRoles.length > 0) {
        return true;
      } else {
        return false;
      }
    } else if (item.title.toLowerCase() === 'interchangeability advisor') {
      // tslint:disable-next-line: no-string-literal
      if (envConfig != null && envConfig['interchangeAdvisorUrl']) {
        return true;
      } else {
        return false;
      }
    } else if (item.title.toLowerCase() === 'training') {
      // tslint:disable-next-line: no-string-literal
      if (envConfig != null && envConfig['showTrainingHub']) {
          return (envConfig['showTrainingHub'].toUpperCase() === 'TRUE') ? true : false;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
}
