import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth-guard/auth.guard';

import { CustomPreloadingStrategyService } from './services/custom-preloading/custom-preloading-strategy.service';

const routes: Routes = [
  {
    path: 'Platform/workspace',
    loadChildren: () => import('./workspace/workspace.module').then(m => m.WorkspaceModule),
    data: { preload: true }
  },
  {
    path: 'Platform/sbiFaq',
    loadChildren: () => import(
      './workspace-widget/custom-widgets/devproxy-sbi-widget/sbi-faq/sbi-faq/sbi-faq.module'
    ).then(m => m.SbiFaqModule)
  },
  {
    path: 'Platform/jobRoleSurvey',
    loadChildren: () => import('./user-role-survey/user-role-survey.module').then(m => m.UserRoleSurveyModule)
  },
  {
    path: 'Platform/psp/:id',
    loadChildren: () => import('./psp/psp.module').then(m => m.PSPModule)
  },
  {
    path: 'Platform/YourAccount',
    loadChildren: () => import('./your-account/your-account.module').then(m => m.YourAccountModule)
  },
  {
    path: 'Platform/termsOfUse',
    loadChildren: () => import('./mbf-termsofuse/mbf-termsofuse.module').then(m => m.MbfTermsofuseModule)
  },
  {
    path: 'Admin/Dashboard',
    loadChildren: () => import('./admin/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Admin/ContactUsAdministration',
    loadChildren: () => import('./admin/contact-us/contact-us.module').then(m => m.ContactUsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Admin/UserAdministration',
    loadChildren: () => import('./admin/user-administration/user-administration.module').then(m => m.UserAdministrationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Admin/UserRole',
    loadChildren: () => import('./admin/user-role/user-role.module').then(m => m.UserRoleModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Admin/HelpPageAdministration',
    loadChildren: () => import('./admin/help-page/help-page.module').then(m => m.HelpPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Admin/SystemMessageAdministration',
    loadChildren: () => import('./admin/system-message/system-message.module').then(m => m.SystemMessageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Admin/ProductAdminAdministration',
    loadChildren: () => import('./admin/product-admin/product-admin.module').then(m => m.ProductAdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Admin/MarketingAdministration',
    loadChildren: () => import(
      './admin/marketing-administration/marketing-administration.module'
    ).then(m => m.MarketingAdministrationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Admin/ContentLibrary',
    loadChildren: () => import('./admin/content-library/content-library.module').then(m => m.ContentLibraryModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Admin/ShortURL',
    loadChildren: () => import('./admin/short-url/short-url.module').then(m => m.ShortUrlModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Platform/searchResults', loadChildren: () => import('./search-results/search-results.module').
      then(m => m.SearchResultsModule), runGuardsAndResolvers: 'always', data: { analytics: { pageName: 'SearchResults', pageView: true } }
  },
  {
    path: 'Solo',
    loadChildren: () => import('./workspace/solo-workspace.module').then(m => m.SoloWorkspaceModule),
    data: { preload: true }
  },
  {
    path: '', redirectTo: '/Platform/workspace', pathMatch: 'full'
  },
  {
    path: 'Platform/community', loadChildren: () => import('./community-workspace/community-workspace.module').then(m =>
      m.CommunityWorkspaceModule)
  },
  {
    path: 'Platform/apps', loadChildren: () => import('./app-listing/app-listing.module').then(m => m.AppListingModule),
    data: { preload: true, analytics: { pageName: 'Apps', pageView: true } }
  },
  {
    path: 'Platform/browserEOL',
    loadChildren: () => import('./browser-eol/browser-eol-routing.module').then(m => m.BrowserEolRoutingModule)
  },
  {
    path: 'Admin/EventCatelog/UserActions',
    loadChildren: () => import('./admin/event-catelog-admin/event-catelog-admin.module').then(m => m.EventCatelogAdminModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'Platform/trainingHub',
    loadChildren: () => import('./training-hub/training-hub.module').then(m => m.TrainingHubModule),
    data: { preload: true, analytics: { pageName: 'Training Hub', pageView: false }  }
  },
  {
    path: 'Platform/claf-support',
    loadChildren: () => import('./claf-support/claf-support.module').then(m => m.ClafSupportModule),
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      useHash: true,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      preloadingStrategy: CustomPreloadingStrategyService,
      onSameUrlNavigation: 'reload'
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
