import { Component, OnInit, Inject, Optional } from '@angular/core';
import { ContainerWidgetFormat } from '../../workspace-widget/widget-data';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { WidgetService } from '../../workspace-widget/widget.service';
import { WorkspaceService } from '../../workspace/workspace.service';
import { AlertDialogComponent } from 'src/app/shared/alert-dialog/alert-dialog.component';
import { of } from 'rxjs';
import { AppListingService } from 'src/app/app-listing/services/app-listing.service';

@Component({
  selector: 'app-view-folder-archive-dialog',
  templateUrl: './view-folder-archive-dialog.component.html',
  styleUrls: ['./view-folder-archive-dialog.component.css']
})
export class ViewFolderArchiveDialogComponent implements OnInit {
  loading = false;
  dialogLoading = false;
  form: FormGroup;
  archivedWidgets: ContainerWidgetFormat[];
  isWorkspacePage: boolean;
  workspaceId: string;
  workspaceType: string;
  favoritesId: string;

  constructor(private fb: FormBuilder,
              public dialogRef: MatDialogRef<ViewFolderArchiveDialogComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              public dialog: MatDialog,
              private widgetService: WidgetService,
              public workspaceService: WorkspaceService, private appListingService: AppListingService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    const urlItems = (window.location.href).split('/');
    // To decide if the current page is workspace page or not
    this.isWorkspacePage = urlItems.indexOf('Platform') !== -1 && urlItems.indexOf('workspace') !== -1;
    this.getWorkspaceId();
    this.getHomeWorkspace();
    this.form = this.fb.group({
      name: this.fb.array([], [Validators.required])
    });
    this.dialogLoading = true;
    this.widgetService.getArchivedWidgets().subscribe((result: ContainerWidgetFormat[]) => {
      this.sortWidgetList(result);
      this.archivedWidgets = result;
      this.dialogLoading = false;
    }, error => {
      this.closeDialog();
      this.throwError('Error Occured!');
      this.dialogLoading = false;
    });
  }

  /**
   * Method to throw the failure message
   */
  throwError(errorMessage: string): void {
    this.openDialog(errorMessage, false);
  }

  /**
   * Method to get the workspace id to where the folder must be unarchived.
   */
  getWorkspaceId() {
    this.workspaceService.workspaceData.subscribe((workspace: any) => {
      if (workspace) {
        this.workspaceType = workspace.type;
        if (this.workspaceType === 'BasicWorkspace') {
          this.workspaceId = workspace.id;
        } else if (this.workspaceType === 'SingleColumnWorkspace') {
          for (const item of workspace.layout) {
            if (item.type === 'BasicWorkspace') {
              this.workspaceId = item.id;
            }
          }
        }
      }
    });
  }

  /**
   * Method to get the updated workspace details
   */
  getUpdatedWorkspace() {
    if (this.workspaceId !== undefined) {
      this.loading = true;
      this.widgetService.getWidget(this.workspaceId).subscribe((workspace: any) => {
        this.refreshWorkspace(workspace.data);
      }, error => {
        this.throwError('Error Occured!');
        this.loading = false;
      });
    }
  }

  /**
   * Method to refresh the workspace, to update it with unarchived folder
   * @param workspace Workspace info
   */
  refreshWorkspace(workspace: any) {
    if (this.workspaceType === 'BasicWorkspace') {
      this.workspaceService.setBasicWorkspace(workspace.layout);
    } else if (this.workspaceType === 'SingleColumnWorkspace') {
      this.workspaceService.setSingleColumWorkspace(workspace.id, workspace);
    }
    this.loading = false;
  }

  /**
   * Method to get the home workspace if the current page is not workspace page
   */
  getHomeWorkspace() {
    this.workspaceService.getHomeWorkspace().subscribe((workspaceData: any) => {
      const workspace = workspaceData.data;
      if (workspace) {
        if (workspace.type === 'BasicWorkspace') {
          this.favoritesId = workspace.id;
        } else if (workspace.type === 'SingleColumnWorkspace') {
          for (const item of workspace.layout) {
            if (item.type === 'BasicWorkspace') {
              this.favoritesId = item.id;
            }
          }
        }
      }
    }, error => {
      this.throwError('Error Occured!');
      this.loading = false;
    });
  }

  /**
   * Method to open Success/Failure Dialog
   * @param title Title
   * @param isSuccessVal Success or failure indicator
   */
  openDialog(title: string, isSuccessVal: boolean) {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      data: { Title: title, isSuccess: isSuccessVal }
    });
    // For Success messages the dialog should disappear automatically
    if (isSuccessVal) {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    }
  }

  /**
   * Method to close the dialog
   */
  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * Method to handle 'Add' button functionality to restore archived folder
   */
  restoreWidget() {
    for (const folder of this.form.value.name) {
      if (folder && folder.layout.length > 0){
        folder.layout.forEach((item) => {
          this.appListingService.updatedAppList(item, 'add');
        });
      }
    }

    this.loading = true;
    this.unArchive();
  }

  /**
   * Method to handle unarchive of folders
   */
  unArchive() {
    const selectedFolders = new Map<string, string>();
    for (const folder of this.form.value.name) {
      selectedFolders.set(folder.id, folder.longName);
    }
    let isFailed = false;
    this.widgetService.restoreWidgets(this.favoritesId, selectedFolders).subscribe((response: any) => {
      for (const value of response.data) {
        isFailed = !value;
        break;
      }
      this.closeDialog();
      if (isFailed) {
        this.throwError('Failure! Unable to unarchive some folder.');
      }
      this.workspaceService.getDefaultWorkspace().subscribe((workspace: any) => {
        if (workspace && workspace.data && workspace.data.longName === 'MBF Home'){
          this.workspaceService.getHomeWorkspace(false, true, false).subscribe((res) => {
            this.workspaceService.setDefaultWorkspace(of(res));
          });
        } else {
          this.workspaceService.getHomeWorkspace(false, true, false);
        }
      }, (error: any) => {
        this.workspaceService.getHomeWorkspace(false, true, false);
      });
      this.loading = false;
      if (this.isWorkspacePage) {
        this.getUpdatedWorkspace();
      }
    }, error => {
      this.closeDialog();
      this.throwError('Error Occured!');
      this.loading = false;
    });
  }

  /**
   * Method to sort the widgets list
   * @param widgetList Widgets list
   */
  sortWidgetList(widgetList: ContainerWidgetFormat[]) {
    return widgetList.sort((a, b) => {
      return (a.longName.toLowerCase() > b.longName.toLowerCase()) ? 1 :
        ((b.longName.toLowerCase() > a.longName.toLowerCase()) ? -1 : 0);
    });
  }

  /**
   * Method to handle Checkbox change event
   * @param widget Widget info
   * @param event MatCheckboxChange event
   */
  onChangeEventFunc(widget: ContainerWidgetFormat, event: MatCheckboxChange) {
    const isChecked = event.checked;
    const widgets = (this.form.controls.name as FormArray);
    if (isChecked) {
      widgets.push(new FormControl(widget));
    } else {
      const index = widgets.controls.findIndex(x => x.value === widget);
      widgets.removeAt(index);
    }
  }

}
