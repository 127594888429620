import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UseCacheInterceptor } from '../app-listing/services/use-cache.interceptor';
import { LoginInterceptor } from './login.interceptor';


import { XsrfInterceptor } from './xsrf.interceptor';
/* If we have a need of adding multiple interceptors then create seperate name.interceptor.ts file in this folder level
and add new provider into below array, instead of adding in the app module provider.

httpInterceptorProviders has been already added into app module providers.
*/
export const httpInterceptorProviders = [
  // The interceptors will be called in the below order in which they were provided.
  { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: LoginInterceptor, multi: true },
  {provide: HTTP_INTERCEPTORS, useClass: UseCacheInterceptor, multi: true}
];
