import { NgModule } from '@angular/core';
import { CustomCamelCasePipe } from './custom-camel-case.pipe';



@NgModule({
  declarations: [CustomCamelCasePipe],
  exports: [CustomCamelCasePipe],
})
export class CustomCamelCaseModule { }
