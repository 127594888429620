import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthGuardService } from '../services/auth-guard/auth-guard.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private authGuardService: AuthGuardService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
    return this.authGuardService.checkAuthGuardAccess(state.url).pipe(
      map(e => {
        if (e) {
          return true;
        } else {
          this.router.navigate(['/Platform/workspace']);
          return false;
        }
      }),
      catchError((err) => {
        this.router.navigate(['/Platform/workspace']);
        return of(false);
      })
    );
  }
}
