import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlightText'
})
export class HighlightTextPipe implements PipeTransform {
  transform(input: string, searchText: string, replacer?: string): string {
    if (!searchText) { return input; }
    // tslint:disable-next-line: prefer-const

    let searchTextSingleWord = searchText.trim().split(' ');
    
    searchTextSingleWord.forEach(element => {
      let tmp = element.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
      const re = new RegExp(tmp + '(?![^<]*>)', 'igm');
      input = input.replace(re, replacer ? replacer : '<strong>$&</strong>');  
    });

    return input;
  }
}
