import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AppConfigService } from 'src/app/core/app-config.service';
import { catchError } from 'rxjs/operators';
import { Brand, SystemMessage, UserCountInput } from 'src/app/admin/system-message/components/system-message-create-edit-view/system-message-interface';
@Injectable({
  providedIn: 'root'
})
export class SystemMessageService {
  constructor(private httpClient: HttpClient,
              private appConfigService: AppConfigService) { }

  deleteImage = new BehaviorSubject<string>('');
  closeBrand = new BehaviorSubject<string>('');
  saveBrand = new BehaviorSubject<string[]>([]);
  resetBrand = new BehaviorSubject<Brand>(new Brand);
  existingSystemMessage = new BehaviorSubject<any>({});
  existingBrands = new BehaviorSubject<Brand[]>([]);

  // Get system messages by ID
  public getSystemMessage(Id): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('singleSystemMessageEndpoint');
    return this.httpClient.get<any>(`${endpoint}/${Id}`, { observe: 'response' }).pipe(catchError(this.error));
  }
  // Update system message
  public updateSystemMessage(systemMessage: SystemMessage): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const endpoint = this.appConfigService.getBackendPath('systemMessageEndpoint');
    if (systemMessage.messageId) {
      // tslint:disable-next-line: max-line-length
      return this.httpClient.put<any>(`${endpoint}/${systemMessage.messageId}`, JSON.stringify(systemMessage), httpOptions).pipe(catchError(this.error));
    }
    return this.httpClient.post<any>(endpoint, JSON.stringify(systemMessage)).pipe(catchError(this.error));
  }

  // Get all avilable system messages
  getSystemMessages() {
    const apisystemMessagesList: string = this.appConfigService.getBackendPath('systemMessagesListEndpoint');
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json;charset=UTF-8' }) };
    return this.httpClient.get(`${apisystemMessagesList}`, httpOptions).pipe(catchError(this.error));
  }
  // Delete systemMessage
  deleteSystemMessage(messageId: string) {
    const apisystemMessage: string = this.appConfigService.getBackendPath('systemMessageEndpoint');
    return this.httpClient.get(`${apisystemMessage}/${messageId}/delete`).pipe(catchError(this.error));
  }
  // Publish systemMessage
  publishSystemMessage(messageId: string) {
    const apisystemMessage: string = this.appConfigService.getBackendPath('systemMessageEndpoint');
    return this.httpClient.get(`${apisystemMessage}/${messageId}/publish`).pipe(catchError(this.error));
  }

  // get Count
  public getCount(
    userCountInput: UserCountInput): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const endpoint = this.appConfigService.getBackendPath('systemMessageEndpointCount');
    return this.httpClient.post<any>(endpoint, userCountInput, httpOptions).pipe(catchError(this.error));
  }

   // get all brands
   getAllBrands(){
    const apiSystemMessageBrand: string = this.appConfigService.getBackendPath('fetchBrands');
    return this.httpClient.get(`${apiSystemMessageBrand}`).pipe(catchError(this.error));
  }

  // add or update Brand
  addOrEditBrand(brandMode: any, brand: Brand): Observable<any>{
    const apiSystemMessageBrand: string = brandMode === 'Add' ?
      this.appConfigService.getBackendPath('addBrand') : this.appConfigService.getBackendPath('updateBrand');
    return  brandMode === 'Add' ?
      this.httpClient.post<any>(`${apiSystemMessageBrand}`, brand).pipe(catchError(this.error)) :
      this.httpClient.put<any>(`${apiSystemMessageBrand}`, brand).pipe(catchError(this.error));
  }

  setDeleteImage(value: string): void {
    this.deleteImage.next(value);
  }

  getDeleteImage(){
    return this.deleteImage;
  }

  setCloseBrand(value: string): void {
    this.closeBrand.next(value);
  }

  getCloseBrand(){
    return this.closeBrand;
  }

  setSaveBrand(value: string[]): void {
    this.saveBrand.next(value);
  }

  getSaveBrand(){
    return this.saveBrand;
  }

  setResetBrand(value: any): void {
    this.resetBrand.next(value);
  }

  getResetBrand(){
    return this.resetBrand;
  }

  setExistingSystemMessage(value: any): void{
    this.existingSystemMessage.next(value);
  }

  getExistingSystemMessage(){
    return this.existingSystemMessage;
  }

  setExistingBrands(value:Brand[]): void{
    this.existingBrands.next(value);
  }

  getExistingBrands(){
    return this.existingBrands;
  }

  // Handle Errors
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
