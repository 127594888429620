import { Injectable } from '@angular/core';
export interface Adapter<T> {
    adapt(item: any): T;
}

/**
 * {status: "OK", data: {data} }
 */
@Injectable()
export class BaseAdapter implements Adapter<any> {
    public adapt(response: any): any {
      return response.data || {};
    }
}
