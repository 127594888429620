import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AppConfigService } from 'src/app/core/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AppListingUpdateService {

  constructor(private iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer,
              private appConfigService: AppConfigService) { }

  bootSvgIcons(){
    this.appConfigService.fetchComponentConfig('fetch-svg-icon-list').subscribe(icons => {
      icons.forEach(icon => {
        this.iconRegistry.addSvgIcon(
          icon.svgIcon,
          this.sanitizer.bypassSecurityTrustResourceUrl(icon.iconUrl)
        );
      });
    });
  }
}
