import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { soloIndex } from 'src/environments/environment';

@Injectable()
export class LoginInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => this.handleAuthError(err)));
  }
  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    if(err.status === 403 && err.error === "JWT token not present - failed authentication") {
      window.location.href = window.location.origin + "/directURL-error.html";
    } else if (err.url.toLowerCase().indexOf('/env-config') > -1 && err.status === 403) {
      window.location.href = window.location.origin + "/error.html";
      return of(err.message); // or EMPTY may be appropriate here
    }
    return throwError(err);
  }
}
