<div class="text-px-14 alert-dialog-width">
    <div mat-dialog-content>
        <div>
            <mat-icon *ngIf="data.isSuccess" class="mat-palette-green-700 text-px-60">check_circle</mat-icon>
            <mat-icon *ngIf="!data.isSuccess" color="warn" class="text-px-60">error</mat-icon>
        </div>
        <br>
        <br>
        <div>{{data.Title}}</div>
        <br>
        <br>
    </div>
    <div mat-dialog-actions class="text-right float-right">
        <button mat-button mat-dialog-close color="accent" class="uppercase">DISMISS</button>
    </div>
</div>