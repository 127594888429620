import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/app-config.service';
import { RoleSurveyItems } from './role-survey.item';

@Injectable({
  providedIn: 'root'
})
export class RoleSurveyService {

  constructor(private appConfigService: AppConfigService, private httpClient: HttpClient) { }

  jobRoleSurveyEndPoint: string = this.appConfigService.getBackendPath('jobRoleSurveyEndPoint');
  optOutURSSurevyEndPoint: string = this.appConfigService.getBackendPath('optOutSurveyEndPoint');
  jobRoleSurveySubmitDataEndPoint: string = this.appConfigService.getBackendPath('jobRoleSurveyEndPoint');
  jobRoleSurveyUpdateSkipCountEndPoint: string = this.appConfigService.getBackendPath('jobRoleSurveyUpdateSkipCountEndPoint');
  private readonly cacheAPI: Map<string, Observable<string[]>> =
    new Map<string, Observable<string[]>>();

  public getJobRoleSurveyData(withCache: boolean = false): Observable<any> {
    const apiurl = this.jobRoleSurveyEndPoint;
    if (withCache) {
      if (!this.cacheAPI[apiurl]) {
        this.cacheAPI[apiurl] = this.httpClient.get<any>(apiurl, { observe: 'response' }).pipe(shareReplay(), catchError(this.error));
      }
    } else {
      this.cacheAPI[apiurl] = this.httpClient.get<any>(apiurl, { observe: 'response' }).pipe(shareReplay(), catchError(this.error));
    }
    return this.cacheAPI[apiurl];
  }

  public setJobRoleSurveyData(value) {
    this.cacheAPI[this.jobRoleSurveyEndPoint] = of(value);
  }
  // Handle error
  error(error: HttpErrorResponse) {
    return throwError(error);
  }

  public submitData(roleSurveyItems: RoleSurveyItems) {
    const requesturl = this.jobRoleSurveyEndPoint;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers };
    return this.httpClient.post(requesturl, roleSurveyItems, options);
  }

  public updateSkipCount() {
    const requesturl = this.jobRoleSurveyUpdateSkipCountEndPoint;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers };
    return this.httpClient.post(requesturl, options);
  }
  public submitOptOut() {
    const requesturl = this.optOutURSSurevyEndPoint;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = { headers };
    return this.httpClient.post(requesturl, options);
  }
}
