import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/core/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class BenNavigationService {

  mbfEcoBaseUrl: string = "https://myboeingfleet.boeing.com";
  mbfEcoNxUrl: string = "https://www.myboeingfleet.com";
  useTbCx: boolean = false;
  toolboxAlias: string[] = [];

  constructor(private appConfigService: AppConfigService) { 
    let environment: string;
    this.appConfigService.envConfig.subscribe(config => {
      if (config['environment']) {
        environment = config['environment'];
      }
      if(config['useTbCx']) {
        this.useTbCx = config['useTbCx'];
      }

      const envUrls = this.appConfigService.getProperty('rpUrlPrefix');
      if(environment !== null && typeof environment !== 'undefined') {
        let envUrl = envUrls[environment.toUpperCase()];
        if(envUrl !== null && typeof envUrl !== 'undefined') {
          this.mbfEcoBaseUrl = envUrl.url;
          this.mbfEcoNxUrl = envUrl.nxUrl ? envUrl.nxUrl : this.mbfEcoNxUrl;
        }
      }
      const tbNxUrls = this.appConfigService.getProperty('toolboxNxAliasList');
      if(environment !== null && typeof environment !== 'undefined') {
        let tbxList = tbNxUrls[environment.toUpperCase()];
        if(tbxList !== null && typeof tbxList !== 'undefined') {
          this.toolboxAlias = tbxList;
        }
      }
    });
  }

  formatLink(link) : any {
    let formattedLink = link;
    // Relative Links will be directed to the MBF Ecosystem URL
    if(this.mbfEcoBaseUrl && link && link.charAt(0) === '/') {
      if(this.useNonAcceleratedUrl(formattedLink)) {
        formattedLink = this.mbfEcoNxUrl + formattedLink;
      } else {
        formattedLink = this.mbfEcoBaseUrl + formattedLink;
      }
    }
    return formattedLink;
  }

  useNonAcceleratedUrl(link) : boolean {
    if(!this.useTbCx && this.toolboxAlias && this.toolboxAlias.indexOf(link)!== -1) {
      return true;
    }
    return false;
  }

}
