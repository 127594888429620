import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfigService } from 'src/app/core/app-config.service';
import { AppListingService } from './app-listing.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class UseCacheInterceptor implements HttpInterceptor {
  cacheMap = new Map<string, HttpResponse<any>>();
  endPointList: any[] = [];
  constructor(private appConfigService: AppConfigService,
              private appListingService: AppListingService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // not cachable
    if (!this.isRequestCachable(request)) {
      return next.handle(request);
    }
    else {
      // request is cachable
       const url = request.url.toLowerCase();
      // if the request is cached
       if (this.cacheMap.has(url)) {
        return of(this.cacheMap.get(url) as HttpResponse<any>);
      }
      else {
        return next.handle(request).pipe(
          tap(event => {
            if (event instanceof HttpResponse) {
              // set the map
              this.cacheMap.set(url, event);
            }
          })
        );
      }
    }
  }
  isRequestCachable(req: HttpRequest<any>): boolean {
    // the reuest must the GET method
    if (req.method === 'GET') {
      // define all the cache partial URL
      this.endPointList[0] = this.appConfigService.getBackendPath('appLists');
      this.endPointList[1] = this.appConfigService.getBackendPath('userAppList');
      // get the requets URL
      for (let i = 0; i < this.endPointList.length; i++) {
        if (req.url.toLowerCase().includes(this.endPointList[i].toLowerCase())) {
          return true;
        }
      }
      if (this.appListingService.isSrcPublish.value) {
        return false;
      }
    }
    return false;
  }
}
