import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
];


@NgModule({
    imports: [MODULES],
    exports: [MODULES],
    providers: [
        DatePipe
      ]
})
export class SharedModule { }
