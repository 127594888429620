import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hdrImgSrc'
})
export class HdrImgSrcPipe implements PipeTransform {

  transform(envConfig: any): unknown {
    return envConfig.oemPlatformAcronym ? `assets/img/logos/oem-logo-${envConfig.oemPlatformAcronym}-GlobalHeader.png` : '';
  }

}
