import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/core/interfaces/adapter';
import { UserRolesItem } from 'src/app/user/user-roles-item';
import { RolePermissionsItemArrayAdapter } from 'src/app/user/role-permissions-item-adapter';

@Injectable({
    providedIn: 'root'
  })
export class UserRoleAdapter implements Adapter<UserRolesItem> {
    constructor(private rolePermissionArrayAdapter: RolePermissionsItemArrayAdapter) {}
    adapt(item: any): UserRolesItem {
        const role: UserRolesItem = {
            userRoleId: item.userRoleId || '',
            userRoleName: item.userRoleName || '',
            rolePermissions: this.rolePermissionArrayAdapter.adapt(item.rolePermissions)
        };
        return role;
    }

}


@Injectable({
    providedIn: 'root'
  })
export class UserRoleArrayAdapter implements Adapter<UserRolesItem[]> {
    constructor(private userRoleAdapter: UserRoleAdapter) {}

    adapt(items: any): UserRolesItem[] {
        const roles = new Array<UserRolesItem>();
        if ( Array.isArray(items) ) {
           for ( const item of items ) {
               roles.push(this.userRoleAdapter.adapt(item));
           }
        } else {
            roles.push(this.userRoleAdapter.adapt(items));
        }
        return roles;
    }

}
