import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class ExportService {

    /**
     * Method to export data to CSV file
     * @param data export data
     * @param filename name of the file
     * @param columnNames column names in object
     * @param headerNames column header names for csv file
     */
    downloadFile(data, filename, columnNames, headerNames) {
        const csvData = this.convertToCSV(data, columnNames, headerNames);
        const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        // if IE 10+ browser
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename + '.csv');
        } else {
            const dwldLink = document.createElement('a');
            const url = URL.createObjectURL(blob);
            const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
            // if Safari open in new window to save file with random filename.
            if (isSafariBrowser) {

                dwldLink.setAttribute('target', '_blank');
            }
            dwldLink.setAttribute('href', url);
            dwldLink.setAttribute('download', filename + '.csv');
            dwldLink.style.visibility = 'hidden';
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        }
    }

    /**
     * Method to create the comma separated input data for CSV file creation
     * @param objArray data
     * @param columnNames column names in object
     * @param headerNames column header names for csv file
     */
    convertToCSV(objArray, columnNames, headerNames) {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        let row = '';
        // tslint:disable-next-line: forin
        for (const index in headerNames) {
            row += headerNames[index] + ',';
        }
        row = row.slice(0, -1);
        str += row + '\r\n';
        if (objArray !== null) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < array.length; i++) {
                let line = '';
                // tslint:disable-next-line: forin
                for (const index in columnNames) {
                    const head = columnNames[index];
                    line += (array[i][head] == null ? '' : array[i][head]) + ',';
                }
                str += line + '\r\n';
            }
        }
        return str;
    }

    downloadFileforCount(data, filename, columnNames, headerNames) {
        const csvData = this.convertToCSVforCount(data, columnNames, headerNames);
        const blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
        // if IE 10+ browser
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, filename + '.csv');
        } else {
            const dwldLink = document.createElement('a');
            const url = URL.createObjectURL(blob);
            const isSafariBrowser = navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;
            // if Safari open in new window to save file with random filename.
            if (isSafariBrowser) {

                dwldLink.setAttribute('target', '_blank');
            }
            dwldLink.setAttribute('href', url);
            dwldLink.setAttribute('download', filename + '.csv');
            dwldLink.style.visibility = 'hidden';
            document.body.appendChild(dwldLink);
            dwldLink.click();
            document.body.removeChild(dwldLink);
        }
    }
    /*
    * Method to create the comma separated input data for CSV file creation
    * @param objArray data
    * @param columnNames column names in object
    // tslint:disable-next-line: jsdoc-format
    * @param headerNames column header names for csv file
    */
    convertToCSVforCount(objArray, columnNames, headerNames) {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = '';
        const row = '';
        // for (let index in headerNames) {
        //     row += headerNames[index] + ',';
        // }
        // row = row.slice(0, -1);
        // str += row + '\r\n';
        if (objArray !== null) {
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < array.length; i++) {
                let line = '';


                line += (array[i] == null ? '' : array[i]) + ',';

                str += line + '\r\n';
            }
        }
        return str;
    }

}
