import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
import { Adapter } from 'src/app/core/interfaces/adapter';
import { AppConfigService } from 'src/app/core/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class ApiResponseService {
  public resUrlStringToSplit: string;
  public loc: Location;

  constructor(  private http: HttpClient
              , private router: Router
              , private location: Location
              , private appConfigService: AppConfigService)
  {
    this.loc = location;
  }

  public unknownErrorHandler(response: Response) {

    const currentUrl = this.router.url;
    const quesMarkIndex = currentUrl.indexOf('?');
    const amberMarkIndex = currentUrl.indexOf('&');
    const equalMarkIndex = currentUrl.indexOf('=');
    const slashIndex = currentUrl.lastIndexOf('/');
    let replaceCurUrl: boolean;
    if (quesMarkIndex !== -1 && amberMarkIndex !== -1 && equalMarkIndex !== -1) {
        replaceCurUrl = true;
    }
    else if ((slashIndex > 1) && (slashIndex < currentUrl.length)) {
        replaceCurUrl = true;
    }
    else {
        replaceCurUrl = false;
    }

    if (response.status === 403 || response.status === 401) {
    }
    else if (response.status === 404) {
    }
    else if (response.status >= 400) {
    }
    else {
    }
    return throwError(new Error('Unknown error from API'));
}

public responseHandler(response: any, adapter?: Adapter<any>) {
  if (response.status === 200) {
      this.resUrlStringToSplit = response.url.toString();
      const urlString = this.resUrlStringToSplit.split('/');
      const urlTokenLength = urlString.length; // Authentication.html
      if (urlString[urlTokenLength - 1] === 'Authentication.html' &&
        response.text().toString().includes('Forgot your Password?') &&
        response.text().toString().includes(' Log In'))
      {
         window.location.href = '/ecommerce';
         setTimeout(() => window.location.reload(), 300);
      }
      else {
        if (response.code && response.code !== 'OK') {
            throw new Error('Error');
        }

        return (adapter) ? adapter.adapt(response.body)
                       : {
                          code: response.code || 'OK',
                          data: response.body || response
                        };
      }
  } else {
     // this.dialogService.openSnackBar(response.text(), 'E');
      throw new Error('Unknown error from API');
  }
}

public encodeUri(ParameterValues: any) {
  return (encodeURIComponent(ParameterValues));
}

}

export interface ErrorObject {
  errorCode: string;
  defaultMessage: string;
  objectName: string;
  errorType: string;
  argsMap: any;
}


export interface ServiceResponse {
  code: string;
  // errorList: any[];
  // combinedErrorList: any[];
  // accessValue: any[];
  data: any;
  // body: any;
}
