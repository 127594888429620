import { Component, Inject, NgZone, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppConfigService } from 'src/app/core/app-config.service';
import searchResultsTutorialData from '../../../assets/config/data/tutorial/search-results-tutorial-MBF.json';
import { Router } from '@angular/router';
import { SearchResultsService } from 'src/app/services/search-results/search-results.service';
import { SearchSvcRequest } from 'src/app/services/search-results/search-results.data';

@Component({
  selector: 'app-search-results-tutorial-dialog',
  templateUrl: './search-results-tutorial-dialog.component.html',
  styleUrls: ['./search-results-tutorial-dialog.component.css']
})
export class SearchResultsTutorialDialogComponent implements OnInit {

  link: any;
  action: any = '';
  dialogData: any = [];
  isLinear = false;
  tutorialSlides: any = [];
  currentIndex = 0;
  layoutName = 'welcome';
  tHiLight: any;
  tHiTarget: any;
  plateformName = '';
  currentSlide: any;
  closeSts = false;
  searchData: SearchSvcRequest;

  constructor(public dialogRef: MatDialogRef<SearchResultsTutorialDialogComponent>,
              @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
              private snackBar: MatSnackBar, private appConfigService: AppConfigService, private ngZone: NgZone,
              private searchResultsService: SearchResultsService, private router: Router) {
    this.action = data.action;
    this.dialogData = data.data;
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.appConfigService.envConfig.subscribe(config => {
      this.plateformName = config[`oemPlatformName`];
    });
    this.currentIndex = 0;
    this.tutorialSlides = searchResultsTutorialData;

    this.tHiLight = document.getElementById('tutorial-highlight');
    this.tHiLight.style.display = 'block';
    this.tHiTarget = document.getElementById('tutorial-highlight-target');
    this.tHiTarget.style.height = '0px';
    this.tHiTarget.style.width = '0px';
    this.snackBar.dismiss();
    document.getElementById('htmlContent').style.overflow = 'hidden';

    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('resize', this.onResize.bind(this));
    });
    this.closeSts = true;
    this.searchData = new SearchSvcRequest();
    this.searchData.searchText = '';
  }

  next() {
    window.scrollTo(0, 0);
    const cnt = document.getElementById('search-tutorial-dlg');
    if (this.currentIndex < this.tutorialSlides.length) {
      this.currentIndex += 1;

      this.currentSlide = this.tutorialSlides[this.currentIndex];
      setTimeout(() => {
        this.highlightingElement(this.tutorialSlides[this.currentIndex], cnt);
      }, 50);
    }
    this.layoutName = this.tutorialSlides[this.currentIndex].mediaPresent ? 'contentWithMedia' : 'content';

  }

  highlightingElement(item, tmodel) {
    this.ngZone.runOutsideAngular(() => {
      const hElement = document.getElementById('tutorial-highlight');
      const hElementTarget = document.getElementById('tutorial-highlight-target');
      if (window.innerWidth > 991) {
        if (item && item.highlight && document.getElementById(item.highlight)) {
          const marker = document.getElementById(item.highlight).getBoundingClientRect();
          let adjustedpadding = 0;
          if (!(item.index === 5 || item.index === 6 || item.index === 2 || item.index === 3 || item.index === 4)) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            if (item.index === 1) {
              adjustedpadding = 15;
            }
            hElement.style.display = 'block';
            hElementTarget.style.top = (marker.top) - adjustedpadding  + 'px';
            hElementTarget.style.left = (marker.left) - adjustedpadding + 'px';
            hElementTarget.style.height = (marker.height) + adjustedpadding * 2 + 'px';
            hElementTarget.style.width = (marker.width) - 5 + adjustedpadding * 2 + 'px';
            if (item.position === 'left') {
              tmodel.style.left = (marker.left - 5) + 'px';
              tmodel.style.top = (marker.top + marker.height + 24) + 'px';
            } else if (item.position === 'right') {
              tmodel.style.left = 'calc(' + (marker.right + 5) + 'px - 360px)';
              tmodel.style.top = (marker.top + marker.height + 24) + 'px';
            }
          } else {
            const browser = window.navigator.userAgent;
            const isIE = browser.indexOf('MSIE ') > -1 || browser.indexOf('Trident/') > -1;
            if (item.index === 5 || item.index === 4 || item.index === 3) {
              adjustedpadding = 7;
            }
            if (item.index === 2) {
              adjustedpadding = 0.5;
            }
            let adjustedPaddingHeight = 0;
            if (item.index === 3) {
              adjustedPaddingHeight = 4;
            }
            let adjustedPaddingTop = 0;
            if (item.index === 3) {
              adjustedPaddingTop = 4;
            }

            let adjustedHeight = 0;
            if (item.index === 4 || item.index === 5) {
              adjustedHeight = 100;
            }
            let adjustedLeft = 0;
            if (item.index === 2) {
              adjustedLeft = 2;
            }
            // tslint:disable-next-line: radix
            const nb = parseInt((marker.top + '').replace('px', '')) - adjustedHeight - 125;
            if (isIE) {
              window.scrollTo(0, nb);
            } else {
              window.scrollTo({ top: nb, behavior: 'smooth' });

            }
            if (item.index === 2) {
              hElement.style.display = 'block';
              hElementTarget.style.top = marker.top - 7  + 'px';
              hElementTarget.style.left = (marker.left) - 10 + 'px';
              hElementTarget.style.height = (marker.height) + 15 + 'px';
              hElementTarget.style.width = (marker.width) + 10 * 2 + 'px';
            }
            else {
              hElement.style.display = 'block';
              hElementTarget.style.top = (120) + adjustedHeight + adjustedPaddingTop + 'px';
              hElementTarget.style.left = (marker.left) - adjustedpadding + 'px';
              hElementTarget.style.height = (marker.height) + adjustedPaddingHeight + 'px';
              hElementTarget.style.width = (marker.width) + adjustedpadding * 2 + 'px';
            }
            if (item.index === 4) {
              hElement.style.display = 'block';
              hElementTarget.style.top = (125) + adjustedHeight - 16 + 'px';
              hElementTarget.style.left = (marker.left) - 16 + 'px';
              hElementTarget.style.height = (marker.height) + adjustedPaddingHeight + 31 + 'px';
              hElementTarget.style.width = (marker.width) + 60 + 'px';
            }
            else if (item.index === 5) {
              hElement.style.display = 'block';
              hElementTarget.style.top = (125) + adjustedHeight - 6 + 'px';
              hElementTarget.style.left = (marker.left) - 13 + 'px';
              hElementTarget.style.height = (marker.height) + adjustedPaddingHeight + 7 + 'px';
              hElementTarget.style.width = (marker.width) + 28 + 'px';
            }
            else if (item.index === 6) {
              hElement.style.display = 'block';
              hElementTarget.style.top = (24) + adjustedHeight  - 1 + 'px';
              hElementTarget.style.left = (marker.left) + 3 + 'px';
              hElementTarget.style.height = (marker.height) + adjustedPaddingHeight + 6 + 'px';
              hElementTarget.style.width = (marker.width) - 5 + 'px';
            }
            let adjustedtmodelleft = 0;
            if (item.index === 5) {
              adjustedtmodelleft = 9;
            }
            if (item.index === 2) {
              adjustedtmodelleft = 7;
            }
            let adjustedtmodeltop = 0;
            if (item.index === 4) {
              adjustedtmodelleft = 9;
              adjustedtmodeltop = 3;
            }
            if (item.position === 'left') {
              if (item.index === 2) {
                tmodel.style.left = 'calc(65%)';
                tmodel.style.top = 'calc(10%)';
              } else {
                tmodel.style.left = 'calc(40%)';
                tmodel.style.top = 'calc(30%)';
              }
            } else if (item.position === 'right') {
              tmodel.style.left = 'calc(40%)';
              tmodel.style.top = 'calc(30%)';
            }
          }
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
          hElementTarget.style.height = '0px';
          hElementTarget.style.width = '0px';
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        hElementTarget.style.height = '0px';
        hElementTarget.style.width = '0px';
        tmodel.style.left = 'calc(50% - 180px)';
      }
    });
  }

  closeDialog() {
    this.searchResultsService.setSearchRequestItem(this.searchData);
    this.router.navigate(['Platform/searchResults']);
    this.closeSts = false;
    this.tHiLight.style.display = 'none';
    document.getElementById('htmlContent').style.overflow = 'auto';
    this.dialogRef.close();
  }

  onResize(event) {
    const tdialog = document.getElementById('search-tutorial-dlg');
    if (this.closeSts) {
      this.highlightingElement(this.tutorialSlides[this.currentIndex], tdialog);
    }
  }
}
