import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/user/user';
@Pipe({
  name: 'categoryListByUserAccess'
})
export class CategoryListByUserAccessPipe implements PipeTransform {

  transform(searchCategories: any, user: User): any {
    const categoryList = [];

    const categories = (searchCategories) ? searchCategories : {};

    for (const category in categories) {
      if (categories[category].defaultval === 'true') {
        categoryList.push({ label: categories[category].label, value: categories[category].value, id: categories[category].id,
          defaultval: categories[category].defaultval, searchPrivs: categories[category].searchPrivs });
      } else if (this.checkUserAccess(user, categories[category].searchPrivs)) {
        categoryList.push({ label: categories[category].label, value: categories[category].value, id: categories[category].id,
          defaultval: categories[category].defaultval, searchPrivs: categories[category].searchPrivs });
      }
    }
    return categoryList;
  }
  checkForAccess(accessList, access) {
    let hasAccess = false;

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < accessList.length; i++) {
      if (accessList[i].name === access) {
        hasAccess = true;
      }
    }

    return hasAccess;
  }

  checkUserAccess(userData, searchPrivs) {
    if (!(userData && searchPrivs && searchPrivs !== '')) {
      return false;
    }

    let hasAccess = false;

    if (userData && userData.searchAccessList) {
      const accessList = userData.searchAccessList;

      // tslint:disable-next-line: prefer-for-of
      for (let i = 0; i < searchPrivs.length; i++) {
        if (this.checkForAccess(accessList, searchPrivs[i])) {
          hasAccess = true;
        }
      }
    }

    return hasAccess;
  }
}
