import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { FooterBaseComponent } from '../footer-base.component';
import { FooterService } from '../footer.service';
import { AppConfigService } from 'src/app/core/app-config.service';
import { WorkspaceService } from 'src/app/workspace/workspace.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserApiService } from 'src/app/user/user-api.service';
import { User } from 'src/app/user/user';
import { AnalyticTaggingService } from 'src/app/services/shared/analytic-tagging/analytic-tagging.service';

@Component({
  selector: 'app-nxg-skinny-footer',
  templateUrl: './nxg-skinny-footer.component.html',
  styleUrls: ['./nxg-skinny-footer.component.scss']
})
export class NxgSkinnyFooterComponent extends FooterBaseComponent {

  isEligibleUser = false;
  isScreenEligibleUser = false;
  isCodeOff = false;
  findAdminRole: any;
  user: User;
  category = {"title":"Other Resources","links":[{"type":"portal-ui","title":"Terms of Use","url":"#/Platform/termsOfUse","urlTarget":"_blank"},{"type":"external","title":"Privacy Policy & Cookie Statement","url":"http://www.boeing.com/privacy-and-cookie-policy.page","urlTarget":"_blank"}]};
  termsofUselink: any;
  privacyPolicyCookielink: any;

  constructor(
    cd: ChangeDetectorRef,
    footerService: FooterService,
    appConfigService: AppConfigService,
    workspaceService: WorkspaceService,
    router: Router,
    location: Location,
    private userApiService: UserApiService,
    private analyticService: AnalyticTaggingService
  ) {
    super(cd, footerService, appConfigService, router, location, workspaceService);
    this.userApiService.getUser().subscribe((user) => {
      this.user = user;
      if (this.user.userRoles.length > 0) {
        this.findAdminRole = this.user.userRoles.find(function (item) {
          return item.userRoleName == 'PORTAL ADMINISTRATOR';
        });
        this.isEligibleUser = this.findAdminRole != undefined;
      }
    });
  }

  ngOnInit(): void {
    this.isScreenEligibleUser = window.innerWidth > 1024 ? true : false;
    this.termsofUselink = this.category.links[0];
    this.privacyPolicyCookielink = this.category.links[1];
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isScreenEligibleUser = window.innerWidth > 1024 ? true : false;
  }

  toggleCodeOff() {
    this.isCodeOff = this.isCodeOff ? false : true;
  }

  getURL(url: string, urlTarget: string, link: any, type?: string, title?: string) {
    const analyticData = [];
    const value = window.location.href;
    if (value.includes(url)) {
      window.scroll(0, 0);
    } else {
      const updatedUrl = this.buildLink(url, type, title);
      window.open(updatedUrl, urlTarget);
    }
    analyticData.push(this.category);
    analyticData.push(link)
    this.analyticService.track('footerNavFn', analyticData);
  }
}
