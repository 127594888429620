import { Directive, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { AnalyticTaggingService } from 'src/app/services/shared/analytic-tagging/analytic-tagging.service';
import { WorkspaceService } from 'src/app/workspace/workspace.service';

@Directive({
  selector: '[appBackToTop]'
})
export class BackToTopDirective implements OnInit {
  @Input() event = 'click'; // pass desired event
  constructor(
    private ngZone: NgZone,
    private elRef: ElementRef,
    private analyticService: AnalyticTaggingService,
    private workspaceService: WorkspaceService) { }

  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.elRef.nativeElement.addEventListener(this.event, this.clickEvent.bind(this));
    });
  }

  clickEvent(event: any) {
    const editOptionValue = this.workspaceService.getEditOptions();
    if (editOptionValue) {
      this.workspaceService.setEditOptions(!editOptionValue);
      this.workspaceService.setEditOptionsForWorkspace(!editOptionValue);
    }
    event.preventDefault();
    event.stopPropagation();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    this.analyticService.track('backToTopAnalyticFn', 'Back to Top');
  }

}
