import { AppConfigService } from 'src/app/core/app-config.service';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { HttpResponse, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, throwError } from 'rxjs';
import { Preferences } from 'src/app/user/preferences';
import { User } from 'src/app/user/user';

@Injectable({
  providedIn: 'root'
})
export class YourAccountService {

  constructor(private httpClient: HttpClient,
              private appConfigService: AppConfigService) { }
              private callHeaderNotification = new Subject<any>();
              enableHeaderNotification = this.callHeaderNotification.asObservable();
              private callSourceYourAccount = new Subject<any>();
              yourAccountMethodCalled = this.callSourceYourAccount.asObservable();
              // tslint:disable-next-line: variable-name
              private readonly cache_api: Map<string, Observable<string[]>> =
                new Map<string, Observable<string[]>>();
              upEndpoint = this.appConfigService.getBackendPath('usersEndpoint') + '/' + null + '/preferences';
  /**
   * Method to update the default workspace
   * @param id Workspace Id
   */
  public updateDefaultWorkspace(id: string): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('widgetsEndPoint') + '/' + id + '/default';
    return this.httpClient.post<any>(endpoint, { observe: 'response' }).pipe(
      map((response: HttpResponse<any>) =>
        response,
        catchError(this.error)));
  }


  /**
   * Method to get User preferences
   */
   public getUserPreferences(): Observable<Preferences> {
    if (!this.cache_api[this.upEndpoint]){
      this.cache_api[this.upEndpoint] = this.httpClient.get<any>(this.upEndpoint).pipe(shareReplay(), catchError(this.error));
    }
    return this.cache_api[this.upEndpoint];
  }

  public setUserPreferences(value){
    this.cache_api[this.upEndpoint] = of(value);
  }

  /**
   * Method to update preferences
   * @param preference Preferences to be updated
   */
  public updatePreference(preference: any): Observable<User> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const userId = 'none';
    const endpoint = this.appConfigService.getBackendPath('usersEndpoint') + '/' + userId + '/preferences';
    return this.httpClient.post<any>(endpoint, JSON.stringify(preference), httpOptions).pipe(
      map((response: User) =>
        response,
        catchError(this.error)));
  }

  callYourAccountMethod() {
    this.callSourceYourAccount.next();
  }

  callHeaderNotificationMethod() {
    this.callHeaderNotification.next();
  }

  // Handle error
  error(error: HttpErrorResponse) {
    return throwError(undefined);
  }
}
