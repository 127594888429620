import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, share, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ApiResponseService } from 'src/app/api/api-response.service';
import { UserAdapter } from 'src/app/user/user-adapter';
import { AppConfigService } from 'src/app/core/app-config.service';
import { EAFPerson, User } from './user';

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  private user: Observable<User>;
  private userData: User;
  // tslint:disable-next-line: variable-name
  private readonly cache_api: Map<string, Observable<string[]>> =
  new Map<string, Observable<string[]>>();
  private updatedDatasource: boolean = false;
  constructor(private httpClient: HttpClient,
              private appConfigService: AppConfigService,
              private apiResponseService: ApiResponseService,
              private userAdapter: UserAdapter) { }

  refreshUser() {
    const endpoint = this.appConfigService.getBackendPath('userEndpoint');
    if (!this.cache_api[endpoint]){
      this.cache_api[endpoint] = this.httpClient.get<any>(endpoint).pipe(map((response: Response) => {
        let newUser = this.userAdapter.adapt(response);
        this.updateDatasourceInfo(this.userData, newUser);
        this.userData = newUser;
        return newUser;
      }), shareReplay(),
      catchError(error => this.apiResponseService.unknownErrorHandler(error)));
    }
    this.user = this.cache_api[endpoint];
  }

  getUser(): Observable<User> {
    if (this.user === undefined) {
      this.refreshUser();
    }
    return this.user;
  }
  refreshUserAndWorkspace() {
    const endpoint = this.appConfigService.getBackendPath('refreshUserWorkspace');
    if (!this.cache_api[endpoint]){
      this.cache_api[endpoint] = this.httpClient.get<any>(endpoint).pipe(map((response: Response) => {
        let newUser = this.userAdapter.adapt(response);
        this.updateDatasourceInfo(this.userData, newUser);
        this.userData = newUser;
        return newUser;
      }), shareReplay(),
      catchError(error => this.apiResponseService.unknownErrorHandler(error)));
    }
    this.user = this.cache_api[endpoint];
  }
  getUserAndWorkspace(): Observable<User> {
    this.refreshUserAndWorkspace();
    return this.user;
  }
  isNewUser(user) {
    let isNew = false;
    // A user is consider "new" if their initial and latest login times are identical
    if (user && user.initialLogin && user.latestLogin && user.initialLogin === user.latestLogin) {
      isNew = true;
    }
    return isNew;
  }

  updateDatasourceInfo(prevData, newData) {
    if(prevData != null && prevData.userDataSources != null && newData != null && newData.userDataSources != null) {
      let newSources = newData.userDataSources.filter(source=>prevData.userDataSources.indexOf(source) < 0);
      if(newSources != null && newSources.length > 0) {
        this.updatedDatasource = true;
      } else {
        this.updatedDatasource = false;
      }
    } else {
      this.updatedDatasource = false;
    }
  }

  public isNewSourceListed(): boolean {
    return this.updatedDatasource;
  }

  searchUsers(bemsId, airlineCodes, firstName, lastName): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('users');
    const searchParams: any = {};

    if (firstName) {
      searchParams.firstName = firstName;
    }
    if (lastName) {
      searchParams.lastName = lastName;
    }
    if (bemsId) {
      searchParams.bemsId = bemsId;
    }
    if (airlineCodes) {
      searchParams.airlineCodes = airlineCodes;
    }
    return this.httpClient.get(endpoint, { params: searchParams }).pipe(
      map((response: Response) => {
        return response;
      }),
      share(),
      catchError((error) => {
        return of([]);
      }));
  }
  getUserFavorites(): Observable<any> {
    const endpoint = this.appConfigService.getBackendPath('userFavorite');
    return this.httpClient.get<any>(endpoint).pipe(catchError(error => this.apiResponseService.unknownErrorHandler(error)));
  }
  addUserFavorite(Id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/html; charset=utf-8'
      }),
      responseType: 'text' as 'json'
    };
    const endpoint = this.appConfigService.getBackendPath('userFavorite') + '/' + Id;
    // tslint:disable-next-line: max-line-length
    return this.httpClient.post<any>(endpoint, {}, httpOptions).pipe(catchError(error => this.apiResponseService.unknownErrorHandler(error)));
  }
  removeFavoriteUser(Id): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'text/html; charset=utf-8'
      }),
      responseType: 'text' as 'json'
    };
    const endpoint = this.appConfigService.getBackendPath('userFavorite') + '/' + Id + '/remove';
    return this.httpClient.get<any>(endpoint, httpOptions).pipe(catchError(error => this.apiResponseService.unknownErrorHandler(error)));
  }
   /*
   * Method to get the EAF list
   */
    getEAFList(): Observable<EAFPerson[]> {
      const endpoint = this.appConfigService.getBackendPath('usersEndpoint') + '/eaf';
      if (!this.cache_api[endpoint]){
        // tslint:disable-next-line: max-line-length
        this.cache_api[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(), catchError(error => this.apiResponseService.unknownErrorHandler(error)));
      }
      return this.cache_api[endpoint];
    }


  // Check Survey Eligibility
  checkSurveyEligibility(): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const endpoint = this.appConfigService.getBackendPath('userRoleSurveyEndpoint');
    if (!this.cache_api[endpoint]){
      this.cache_api[endpoint] = this.httpClient.get<any>(endpoint, httpOptions).pipe(shareReplay(), catchError(this.error));
    }
    return this.cache_api[endpoint];
  }

  // Handle error
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }
}
