import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUtilityService } from 'src/app/services/shared/common-utility.service';
import { SystemMessage } from '../../system-message-create-edit-view/system-message-interface';
import { MatDialog } from '@angular/material/dialog';
import { SystemMessageService } from 'src/app/services/system-message/system-message.service';
import { AppConfigService } from 'src/app/core/app-config.service';

@Component({
  selector: 'app-system-message-dialog-box',
  templateUrl: './system-message-dialog-box.component.html',
  styleUrls: ['./system-message-dialog-box.component.css']
})
export class SystemMessageDialogBoxComponent implements OnInit {
  action: string;
  dialogData: SystemMessage;
  brandMode: any;
  saveBtnDisabled: any;
  apiPath: any = '';
  messageTypeStyle: any = '';

  constructor(
    private commonUtilityService: CommonUtilityService,
    public dialogRef: MatDialogRef<SystemMessageDialogBoxComponent>,
    public dialog: MatDialog,
    public systemMessageService: SystemMessageService,
    private appServiceConfig: AppConfigService,
    // @Optional() is used to prevent error if no data is passed
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.action = data.action;
    this.dialogData = { ...data };
    this.apiPath = this.appServiceConfig.getBackendPath('') + '/';
    if (this.dialogData.messageText !== null && this.dialogData.contactInformation !== null){
       this.dialogData.messageText = this.setApiPathForSystemMessageImgTags(this.dialogData.messageText);
       this.dialogData.contactInformation = this.setApiPathForSystemMessageImgTags(this.dialogData.contactInformation);
    }
    this.brandMode = data.brandMode;
    this.saveBtnDisabled = data.saveBtnStatus;
  }

  ngOnInit(): void {
    this.commonUtilityService.resizeIframe();
  }

  doAction() {
    this.dialogRef.close({ event: this.action, dialogData: this.dialogData });
  }

  closeDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  /**
   * Method to get the Headers that should be displayed inside Notification drop down list
   * @param notificationDetails Notification details
   */
  getNotificationsHeader(messageType: string): string {
    if (messageType === 'UNPLANNED_OUTAGE') {
      this.messageTypeStyle = 'unplanned-color';
      return 'UNPLANNED OUTAGE';
    } else if (messageType === 'PLANNED_OUTAGE') {
      this.messageTypeStyle = 'planned-color';
      return 'PLANNED OUTAGE';
    } else if (messageType === 'USER_NOTIFICATION' || messageType === 'INFORMATIONAL') {
      this.messageTypeStyle = 'update-color';
      return 'INFORMATION';
    } else if (messageType === 'TRAINING') {
      this.messageTypeStyle = 'training-color';
      return 'TRAINING';
    } else if (messageType === 'ACTION_REQUIRED') {
      this.messageTypeStyle = 'action-color';
      return 'ACTION REQUIRED';
    }
  }
  viewAffectedApplications(action, obj) {
    obj.action = action;
    this.dialog.open(SystemMessageDialogBoxComponent, {
      data: obj,
      disableClose: true
    });
  }

  closeDialogAndSaveBrand(){
    const saveBrand: string[] = [];
    saveBrand.push('saveBrand');
    saveBrand.push(this.brandMode);
    this.systemMessageService.setSaveBrand(saveBrand);
    this.dialogRef.close({ event: 'Cancel'});
  }

  closeDialogAndDeleteImage(){
     this.systemMessageService.setDeleteImage('deleteImage');
     this.dialogRef.close({ event: 'Cancel' });
  }

  closeDialogAndCloseBrand(){
     this.dialogRef.close({ event: 'Cancel' });
     this.systemMessageService.setCloseBrand('closeBrand');
  }

  setApiPathForSystemMessageImgTags(systemMessageText){
    let updatedMessageText = '';
    const parser = new DOMParser();
    const doc = parser.parseFromString(systemMessageText, 'text/html');
    const imagelinks = doc.getElementsByTagName('img');
    const links = Array.from(imagelinks);
    for (let i = 0; i < links.length; i++) {
      let imgsrc = links[i].src;
      imgsrc = imgsrc.substring(imgsrc.indexOf('content'));
      imgsrc = this.apiPath + imgsrc;
      doc.images[i].src = imgsrc;
    }
    updatedMessageText = doc.body.innerHTML;
    return updatedMessageText;
  }
}
