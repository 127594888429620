import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef, Input, Renderer2,
  HostListener, ChangeDetectorRef, ChangeDetectionStrategy, Output, EventEmitter, SimpleChanges, OnChanges  } from '@angular/core';
import { HeaderService } from '../header.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { AppConfigService } from '../../core/app-config.service';
import { UserApiService } from '../../user/user-api.service';
import { User } from '../../user/user';
import searchCategories from '../../../assets/config/data/search-categories-data-MBF.json';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSelect } from '@angular/material/select';
import { AnalyticTaggingService } from 'src/app/services/shared/analytic-tagging/analytic-tagging.service';
import { of, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router, Event } from '@angular/router';
import { SearchResultsService } from 'src/app/services/search-results/search-results.service';
import { UtilitiesService } from 'src/app/services/shared/utilities-service';
import { SearchClickService } from './search-click.service';
import { SearchSvcRequest } from 'src/app/services/search-results/search-results.data';
import { EventCatalogSharedService } from 'src/app/services/shared/event-catalog/event-catalog-shared.service';
import { BenNavigationService } from 'src/app/services/ben-navigation/ben-navigation.service';

@Component({
  selector: 'app-mbf-search',
  templateUrl: './mbf-search.component.html',
  styleUrls: ['./mbf-search.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MbfSearchComponent implements OnInit, OnChanges {
  clearSearchText = false;
  hasFocus = false;
  user: User = null;
  headerGroup: FormGroup;
  searchResults: any[] = [];
  airplaneIdSearchResults: any[] = [];
  isLoading = false;
  isAirplaneLoading = false;
  advsearchFilter = false;
  searchCategories: any = searchCategories;
  categoryList = this.getCategories();
  refinementValues: any[] = [];
  selectedFilter: any;
  @Input() viewSearchBar: boolean;

  @Input() showSearch = false;
  @Output() showSearchEvent = new EventEmitter<boolean>();
  searchDisplayBar: boolean;
  @ViewChild('autoCompleteAirplaneID', { read: MatAutocompleteTrigger }) autoCompleteAirplaneID: MatAutocompleteTrigger;
  @ViewChildren('insideClick') insideClickElement: QueryList<ElementRef>;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
  @ViewChild('lgMatSelect') lgMatSelect: MatSelect;
  @ViewChild('smMatSelect') smMatSelect: MatSelect;
  screenWidth = window.innerWidth;
  highlightValue = false;
  searchInputValue: string;
  checkMatSelectOpenLg: boolean;
  checkMatSelectOpenSm: boolean;
  analyticCatValue: any;
  analyticSearchData: any[] = [];
  clickData: Subscription;
  insideClick = false;
  outsideClick = false;
  searchRequest: SearchSvcRequest;
  showSearchClearIcons = false;
  constructor( private cd: ChangeDetectorRef, private userApiService: UserApiService,
               private appConfigService: AppConfigService, private headerService: HeaderService,
               private formBuilder: FormBuilder, private renderer2: Renderer2, private analyticService: AnalyticTaggingService,
               private searchResultsService: SearchResultsService, private searchRouter: Router,
               private route: ActivatedRoute, private utilitiesService: UtilitiesService, public searchClickService: SearchClickService, 
               private eventCatalogSharedService: EventCatalogSharedService, private benNavigationService: BenNavigationService ) {
                this.searchRequest = new SearchSvcRequest();
                this.searchRouter.events.subscribe((routeEvent) => { this.onRouteEvent(routeEvent); });
              }
  ngOnChanges(changes: SimpleChanges): void {
  this.searchDisplayBar = this.showSearch;
  }


  ngOnInit(): void {
    this.searchClickService.searchClickOption.subscribe((response: any) => {
      if (response === true) {
        this.clickData = this.utilitiesService.documentClickedTarget.subscribe(event => this.onDocumentClick(event));
      }
      else {
        if (this.clickData) {
          this.clickData.unsubscribe();
        }
      }
    });
    this.userApiService.getUser().subscribe((user: User) => this.user = user);
    this.initializeFormControl();
    this.applyFilter('onLoad');
    this.headerGroup.get('searchText').valueChanges.pipe(
      debounceTime(300),
      tap(() => {
        this.searchResults = [];
        this.isLoading = true;
      }),
      switchMap(value => this.doHeaderSearch(value)
        .pipe(
          finalize(() => {
          }),
        )
      )
    ).subscribe(response => {
      if (response.mbfSearchResults === undefined) {
        this.searchResults = [];
        this.isLoading = false;
      }
      else {
        this.searchResults = response.mbfSearchResults.results;
        this.isLoading = false;
      }
      this.cd.markForCheck();
    });
    this.headerGroup.get('AirplaneID').valueChanges.pipe(
      debounceTime(300),
      tap(() => {
        this.airplaneIdSearchResults = [];
        this.isAirplaneLoading = true;
      }),
      switchMap(value => this.doAirplaneIdSearch(value)
        .pipe(
          finalize(() => {
            this.isAirplaneLoading = false;
          }),
        )
      )
    ).subscribe(response => {
      if (response.mbfSearchResults === undefined) {
        this.airplaneIdSearchResults = [];
      }
      else {
        this.airplaneIdSearchResults = response.mbfSearchResults.results;
      }

    });
  }

  private onRouteEvent(routeEvent: Event) {
    if (routeEvent instanceof NavigationEnd) {
      if (this.isPageView() !== 'SearchResults') {
        this.headerGroup.controls.selectedCat.setValue('');
        this.headerGroup.controls.searchText.setValue('');
      }
    }

  }

  private isPageView() {
    let isPageView = 'nonSearchResultsPage';
    try {
      // tslint:disable-next-line: no-string-literal
      isPageView = this.searchRouter.routerState.root.snapshot.firstChild.data.analytics['pageName'];
    }
    catch (ex) { }
    return isPageView;
  }

  protected onDocumentClick(event: MouseEvent) {
    let res: boolean;
    this.searchClickService.searchClickOption.subscribe((response: any) => {
      res = response;
    });
    if (res === true) {
      this.outsideClick = false;
      this.insideClick = false;
      if (this.insideClickElement.length > 0) {
        const isInside = this.insideClickElement.filter((el: ElementRef) => el && el.nativeElement && this.isContain(el, event));
        if (isInside.length > 0) {
          // Change the width and position of mat menu for large screen
          this.insideClick = true;
          this.outsideClick = false;
          const catOptionId = document.getElementById('mat-drop-id');
          const catOptionLargeId = document.getElementById('mat-cat-large');
          const searchId = document.getElementById('matsearch');
          const menu = document.getElementById(this.menuTrigger.menu.panelId);
          const searchBarSmall = document.getElementById('matsearchbar');
          if (catOptionLargeId && searchId && menu && window.innerWidth > 991) {
            menu.style.width = searchId.clientWidth - catOptionLargeId.clientWidth + 'px';
            menu.style.maxWidth = searchId.clientWidth - catOptionLargeId.clientWidth + 'px';
            menu.style.position = 'Fixed';
            menu.style.right = '134px';
          }
          // Change the width and position of mat menu for medium and small screen
          if (menu && catOptionId && searchBarSmall && window.innerWidth < 992) {
            menu.style.width = searchBarSmall.clientWidth - catOptionId.clientWidth - 70 + 'px';
            menu.style.maxWidth = searchBarSmall.clientWidth - catOptionId.clientWidth - 70 + 'px';
            menu.style.position = 'Fixed';
            menu.style.left = catOptionId.clientWidth + 'px';
          }
          // Listen for click events on body
          this.hasFocus = true;
          // To close the mat menu when we change the screen size
          if ((document.getElementById('searchInputSm') as HTMLInputElement) ||
            (document.getElementById('searchInput') as HTMLInputElement)) {
            if ((document.getElementById('searchInputSm') as HTMLInputElement) &&
              (document.getElementById('searchInput') as HTMLInputElement)) {
              if ((document.getElementById('searchInputSm') as HTMLInputElement).value ===
                (document.getElementById('searchInput') as HTMLInputElement).value) {
                this.advsearchFilter = false;
                this.searchResults = [];
                this.menuTrigger.closeMenu();
              }
              else {
                this.menuTrigger.openMenu();
              }
            }
          }
        }
        else {
          this.outsideClick = true;
          if (this.menuTrigger) {
            this.menuTrigger.closeMenu();
            this.advsearchFilter = false;
            this.hasFocus = false;
          }
          if (this.lgMatSelect && this.lgMatSelect.panelOpen) {
            this.lgMatSelect.close();
            this.hasFocus = true;
          }
          if (this.smMatSelect && this.smMatSelect.panelOpen) {
            this.smMatSelect.close();
          }
          if (this.outsideClick === true && this.insideClick === false) {
            this.enableSearchClick(false);
          }
        }
      }
    } else {
      if(this.menuTrigger){
        this.menuTrigger.closeMenu();
      }
    }
    this.cd.markForCheck();
  }

  enableSearchClick(value: boolean) {
    this.searchClickService.setClickOptions(value);
  }

  protected isContain(source: ElementRef, event: MouseEvent) {
    try {
      if ((event.target as Element).classList.contains('search-click')) {
        return false;
      } else {
        return source.nativeElement.contains(event.target);
      }
    }
    catch (ex) {
      return false;
    }
  }
  public getSiteServerRelativeUrl(): string {
    const endpoint = this.appConfigService.getPortalNgPath('');
    return endpoint;
  }
  private doAirplaneIdSearch(value: any) {
  const d = {
      queryType: 'airplaneTypeAhead',
      resultsPerPage: 1,
      pageNo: 1,
      sortBy: 'relevance',
      refinementValues: []
    };
  this.cd.markForCheck();
  return value.length > 2 ? this.headerService.getSearchResult(d) : of([]);
  }

  private doHeaderSearch(value: any) {
    this.highlightValue = false;
    // Change the min height for large screen size
    if (value && value.length > 2 && window.innerWidth > 991) {
      if (this.menuTrigger) {
        const menu = document.getElementById(this.menuTrigger.menu.panelId);
        if (menu) {
          menu.style.minHeight = '202px';
        }
      }
    }
    else if (value && value.length < 3 && window.innerWidth > 991) {
      const menu = document.getElementById(this.menuTrigger.menu.panelId);
      if (menu) {
        menu.style.minHeight = 'inherit';
      }
    }
    const appliedSelectedCat = this.selectedFilter.selectedCat;
    const selectedCat = appliedSelectedCat ? this.categoryList.find(x => x.id === appliedSelectedCat) : { id: '', value: 'All' };
    this.refinementValues = [];
    this.refinementValues.push({
      refSearchString: value,
      refinementName: 'Text Search',
      refinementType: 'text'
    });
    if (value && value.length > 2 && selectedCat && selectedCat.value.toUpperCase() !== 'Cases') {
      const categoriesRefinements = {
        refValId: null,
        refValName: null,
        refinementName: null,
        refinementType: null
      };
      const d = {
        pageNo: 1,
        queryType: 'searchTypeAhead',
        resultsPerPage: 1,
        sortBy: 'relevance',
        refinementValues: this.refinementValues
      };
      if (selectedCat && selectedCat.value !== 'All') {
        categoriesRefinements.refValId = selectedCat.id;
        categoriesRefinements.refValName = selectedCat.value;
        categoriesRefinements.refinementName = 'Category';
        categoriesRefinements.refinementType = 'list';
        d.refinementValues.push(categoriesRefinements);
      }
      return this.headerService.getSearchResult(d);
    }
    else {
      return of([]);
    }
  }
  goToSearchPage(searchResult) {
    const searchData = this.getSearchData(searchResult);
    this.searchResultsService.setSearchRequestItem(searchData);
    this.searchResultsService.setSaveSearchRequestItem(null);
    this.searchRouter.navigate(['Platform/searchResults']);
    this.headerGroup.controls.selectedCat.setValue('');
    this.headerGroup.controls.searchText.setValue('');
    this.selectedFilter.selectedCat = '';
    this.selectedFilter.AirplaneID = '';
    this.showSearchClearIcons = false;
    if(this.menuTrigger){
      this.menuTrigger.closeMenu();
    }
    this.headerService.setopenSideMenu(false);
    this.analyticService.track('gotoSearchPageFromHeader',searchData);
  }
  getSearchData(searchResult) {
    const appliedSelectedCat = this.selectedFilter.selectedCat;
    const selectedCat = appliedSelectedCat ? this.categoryList.find(x => x.id === appliedSelectedCat) : { id: '', value: 'All' };
    const searchService: any = {};
    const valId = searchResult ? searchResult.identifier : null;
    this.searchRequest.version = 0;
    this.searchRequest.searchText = this.headerGroup.get('searchText').value;
    this.searchRequest.valId = searchResult ? searchResult.identifier : null;
    this.searchRequest.valName = searchResult ? searchResult.edescription : null;
    this.searchRequest.refName = searchResult ? searchResult.ridentifier : null;
    this.searchRequest.globalSearchCall = true;
    this.searchRequest.globalTypeAheadItem = null;
    this.searchRequest.searchTypeAheadSelected = true;
    this.searchRequest.searchTextTyped = this.headerGroup.get('searchText').value;
    this.searchRequest.searchCategory = selectedCat.value;
    this.searchRequest.searchCategoryId = selectedCat.id;
    if (searchResult) {
      // tslint:disable-next-line: no-shadowed-variable
      const valId = searchResult.identifier;
      const searchText = searchResult.description;
      this.searchRequest.globalTypeAheadItem = searchResult;
      this.searchRequest.currentAirplaneSelection = null;
      this.searchRequest.currentrefName = null;
      this.searchRequest.currentrefValId = null;
      this.searchRequest.currentrefValName = null;
      this.searchRequest.currentRefinementSelection = null;
      if (searchText.endsWith(' - Serial Number') ||
        searchText.endsWith(' - Basic Effectivity') ||
        searchText.endsWith(' - Line Number') ||
        searchText.endsWith(' - Engine Effectivity') ||
        searchText.endsWith(' - Tail Number') ||
        searchText.endsWith(' - Customer Variable')
      ) {
        const refValId = searchText.substring(0, searchText.indexOf(' - '));
        const refValName = searchText.substring(searchText.indexOf(' - ') + 3, searchText.length);
        this.searchRequest.currentrefValId = refValId;

        if (searchText.endsWith(' - Serial Number')) { this.searchRequest.currentrefValName = 'Serial_Number'; }
        if (searchText.endsWith(' - Basic Effectivity')) { this.searchRequest.currentrefValName = 'Basic_Eff'; }
        if (searchText.endsWith(' - Line Number')) { this.searchRequest.currentrefValName = 'Line_No'; }
        if (searchText.endsWith(' - Engine Effectivity')) { this.searchRequest.currentrefValName = 'Engine_Eff'; }
        if (searchText.endsWith(' - Tail Number')) { this.searchRequest.currentrefValName = 'Tail_No'; }
        if (searchText.endsWith(' - Customer Variable')) { this.searchRequest.currentrefValName = 'Customer_Variable'; }
        this.searchRequest.currentAirplaneSelection = searchText;
        this.searchRequest.searchText = searchText;
      } else if (valId > 0) {
        // tslint:disable-next-line: no-shadowed-variable
        const searchText = searchResult.description;
        this.searchRequest.currentrefName = searchResult.ridentifier;
        this.searchRequest.currentrefValId = valId;
        this.searchRequest.currentrefValName = searchResult.edescription;
        this.searchRequest.currentRefinementSelection = searchText;
        this.searchRequest.searchText = searchText;
      } else {
        this.searchRequest.searchText = searchText;
        this.searchRequest.searchData = null;
      }
    }
    else {
      this.searchRequest.searchText = this.headerGroup.get('searchText').value;
      this.searchRequest.searchData = null;
    }
    this.cd.markForCheck();
    return this.searchRequest;
  }
  goToApplicationPage(searchResult) {
    const applicationRefinement = {
      refValId: searchResult.identifier,
      refValName: searchResult.edescription,
      refinementName: 'App Name Long',
      refinementType: 'list'
    };
    const d = {
      queryType: 'parallelAppSearch',
      resultsPerPage: 1,
      pageNo: 1,
      sortBy: 'relevance',
      refinementValues: [applicationRefinement]
    };

    this.headerService.getSearchResult(d).subscribe(response => {
      let url = response.applications.length > 0 ? response.applications[0].urlSet[0].url : '';
      // tslint:disable-next-line: no-unused-expression
      if(url) {
        url = this.benNavigationService.formatLink(url);
        window.open(url, '_blank');
      }
      this.cd.markForCheck();
    });

  }
  displayAirplaneId(selectedAirplane) {
    return selectedAirplane.description;
  }
  advFilterMenuOpened() {
    // tslint:disable-next-line: no-unused-expression
    this.autoCompleteAirplaneID ? this.autoCompleteAirplaneID.closePanel() : '';
    this.headerGroup.patchValue({
      selectedCat: this.selectedFilter.selectedCat,
      AirplaneID: this.selectedFilter.AirplaneID
    });
    this.cd.markForCheck();
  }
  onCatChange(event) {
    this.applyFilter('onCatChange');
    if (this.menuTrigger) {
      this.menuTrigger.closeMenu();
    }
  }
  applyFilter(type: any) {
    this.selectedFilter = { selectedCat: this.headerGroup.get('selectedCat').value, AirplaneID: this.headerGroup.get('AirplaneID').value };
    this.refinementValues.length = 0;
    const catRef = this.getCategoryRefinement();
    this.analyticCatValue = [];
    if (catRef != null) {
      this.analyticCatValue = catRef.refValName;
    } else {
      this.analyticCatValue = 'All';
    }
    if (type !== 'onLoad') {
      // Adobe Tag changes, to track category only once search results are ready
     // this.analyticService.track('headerSearchCatChange', this.analyticCatValue);
    }
    // tslint:disable-next-line: no-unused-expression
    catRef && this.refinementValues.push(catRef);
    const airRef = this.getAirplaneRefinement();
    // tslint:disable-next-line: no-unused-expression
    airRef && this.refinementValues.push(airRef);
    this.searchResults = [];
    this.cd.markForCheck();
  }

  getAirplaneRefinement() {
    let airplaneRefinement = null;
    const selectedAirplane = this.selectedFilter.AirplaneID;
    try {
      const refValId = selectedAirplane.description.substring(0, selectedAirplane.description.indexOf(' - '));
      const refValName = selectedAirplane.description.substring(selectedAirplane.description.indexOf(' - ') + 3,
        selectedAirplane.description.length);
      airplaneRefinement = {
        refValId,
        refValName,
        airplaneIdDisplayName: selectedAirplane.airplaneIdDisplayName,
        refinementName: 'airplaneId',
        refinementType: 'selection',
      };
    }
    catch (ex) {

    }
    return airplaneRefinement;
  }
  getCategoryRefinement() {
    const selectedCat = this.categoryList.find(x => x.id === this.selectedFilter.selectedCat);
    let categoryRefinement = null;
    try {
      if (selectedCat) {
        categoryRefinement = {
          refValId: selectedCat.id,
          refValName: selectedCat.value,
          refinementName: 'Category',
          refinementType: 'list'
        };
      }
    }
    catch (ex) {

    }
    return categoryRefinement;
  }
  getTextRefinement() {
    let textRefinement = null;
    const searchTerm = this.headerGroup.get('searchText').value;
    try {
      textRefinement = {
        refSearchString: searchTerm,
        refinementName: 'Text Search',
        refinementType: 'text'
      };
    }
    catch (ex) {

    }
    return textRefinement;
  }
  getCategories() {
    const catList: any[] = [];
    // tslint:disable-next-line: forin
    for (const c in this.searchCategories) {
      catList.push({
        label: this.searchCategories[c].label,
        value: this.searchCategories[c].value,
        id: this.searchCategories[c].id,
        defaultval: this.searchCategories[c].defaultval,
        searchPrivs: this.searchCategories[c].searchPrivs
      });
      // }
    }
    return catList;
  }

  initializeFormControl() {
    this.headerGroup = this.formBuilder.group({
      searchText: new FormControl(''),
      selectedCat: new FormControl(''),
      AirplaneID: new FormControl('')
    });
    this.cd.markForCheck();
  }

  search() {
    this.searchDisplayBar = true;
    this.headerService.setViewSearchData(true);
  }

  closeSearchBar() {
    if (document.getElementById('searchInputSm') as HTMLInputElement) {
      this.searchInputValue = (document.getElementById('searchInputSm') as HTMLInputElement).value;
    }
    this.showSearchEvent.emit(false);
    this.searchDisplayBar = false;
    this.headerService.setViewSearchData(false);
  }
  getWidth() {
    if (window.innerWidth > 991) {
      const catOptionLargeId = document.getElementById('mat-cat-large');
      const searchId = document.getElementById('global-search-target');
      if (this.menuTrigger) {
        const menu = document.getElementById(this.menuTrigger.menu.panelId);
        if (catOptionLargeId && searchId && menu) {
          menu.style.width = searchId.clientWidth - catOptionLargeId.clientWidth + 'px';
          menu.style.maxWidth = searchId.clientWidth - catOptionLargeId.clientWidth + 'px';
          menu.style.position = 'Fixed';
          menu.style.right = '134px';
          if (this.headerGroup.get('searchText').value && this.headerGroup.get('searchText').value.length > 2) {
            menu.style.minHeight = '202px';
          } else {
            menu.style.minHeight = 'inherit';
          }
        }
      }
    }
    else if (window.innerWidth < 992) {
      const catOptionId = document.getElementById('mat-drop-id');
      const searchBarSmall = document.getElementById('matsearchbar');
      if (this.menuTrigger) {
        const menu = document.getElementById(this.menuTrigger.menu.panelId);
        if (menu && catOptionId && searchBarSmall && window.innerWidth < 992) {
          menu.style.width = searchBarSmall.clientWidth - catOptionId.clientWidth - 70 + 'px';
          menu.style.maxWidth = searchBarSmall.clientWidth - catOptionId.clientWidth - 70 + 'px';
          menu.style.position = 'Fixed';
          menu.style.left = catOptionId.clientWidth + 'px';
        }
      }

    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (this.screenWidth > 991) {
      if (event.target.innerWidth < 991) {
        if (this.menuTrigger) {
          this.menuTrigger.closeMenu();
        }
        if (this.lgMatSelect && this.lgMatSelect.panelOpen) {
          this.checkMatSelectOpenLg = true;
          this.lgMatSelect.close();
        }
        if (this.checkMatSelectOpenSm) {
          this.smMatSelect.open();
          this.checkMatSelectOpenSm = false;
        }
        if (document.getElementById('searchInput') as HTMLInputElement) {
          const val = (document.getElementById('searchInput') as HTMLInputElement).value;
          this.headerGroup.patchValue({
            searchText: val
          });
        }

      }
    }
    if (this.screenWidth < 991) {
      if (event.target.innerWidth > 991) {
        if (this.menuTrigger) {
          this.menuTrigger.closeMenu();
        }
        if (this.smMatSelect && this.smMatSelect.panelOpen) {
          this.checkMatSelectOpenSm = true;
          this.smMatSelect.close();
        }
        if (document.getElementById('searchInputSm') as HTMLInputElement) {
          const val = (document.getElementById('searchInputSm') as HTMLInputElement).value;
          this.headerGroup.patchValue({
            searchText: val
          });
        }
        else if (this.searchInputValue || this.searchInputValue === '') {
          (document.getElementById('searchInput') as HTMLInputElement).value = this.searchInputValue;
        }
      }
    }
    this.screenWidth = window.innerWidth;
  }
  highlightFunction() {
    this.highlightValue = true;
  }

  searchClickFunc(event: ClipboardEvent){
    if (this.menuTrigger){
      this.menuTrigger.openMenu();
    }
  }

  disableSearchIcons(){
    this.showSearchClearIcons = false;
  }

  enableSearchIcons(){
    if(this.headerGroup.controls['searchText'].value === '' && this.menuTrigger){
      this.menuTrigger.closeMenu();
    }
    if (this.headerGroup.get('searchText').value && this.headerGroup.get('searchText').value.length > 1) {
      this.showSearchClearIcons = true;
    } else {
      this.showSearchClearIcons = false;
    }
  }

  disableClearSearchIcons(){
    this.headerGroup.controls.searchText.setValue('');
    this.advsearchFilter = false;
    this.searchResults = [];
    this.showSearchClearIcons = false;
    this.menuTrigger.closeMenu();
    this.clearSearchText = true;
    document.getElementById('searchInput').focus();

  }
}
