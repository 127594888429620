import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, BehaviorSubject } from 'rxjs';
import { merge } from 'lodash';
import { Observable } from 'rxjs';
import { Workspace } from 'src/app/workspace/workspace';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private compositeAppConfig: Configuration = {
    paths: {
      backendBase: 'http://localhost:8080',
      appConfigPath: './assets/config/app-config.json',
      configFilePath: './assets/config/config-files.json',
      localConfigPath: '/assets/config/local-config.json',
      rpAliasPath: '',
      portalUiPath: '/portal-ui',
      portalNgPath: '/portal_ng',
      envConfigPath: '/env-config',
    },
    routing: {},
    features: {},
    polling: {},
    analytics: {},
    properties: {},
    componentConfigFile: {},
    componentConfig: {}
  };
  envConfig = new BehaviorSubject({});
  workspaces = new BehaviorSubject([]);

  constructor(private http: HttpClient) { }

  async init(loadenv: boolean): Promise<Configuration> {
    this.initPaths();
    let path = this.getPath('appConfigPath');
    let configJson: any;
    try {
      configJson = await this.fetchConfigFileAsPromise(path);
    } catch (error) {
      this.handleError(error);
    }
    this.updateConfig(configJson);
    path = this.getPath('localConfigPath');
    try {
      configJson = await this.fetchConfigFileAsPromise(path);
    } catch (error) {
      this.handleError(error);
    }
    this.updateConfig(configJson);
    path = this.getPath('configFilePath');
    try {
      configJson = await this.fetchConfigFileAsPromise(path);
    } catch (error) {
      this.handleError(error);
    }
    this.updateConfig(configJson);

    if (loadenv) {
      try {
        this.loadEnvConfig();
      } catch (error) {
        this.handleError(error);
      }
    }
    return this.compositeAppConfig;
  }

  initPaths(): void {
    const config = { paths: {} };
    const pathArr = window.location.pathname.split('/').filter(item => item && !item.match(/\.(htm|css|js)/));
    let rpAliasPath = '';
    let portalUiPath = '';
    let portalNgPath = '';
    switch (pathArr.length) {
      case 0: // running locally, outside of tomcat, use default /assets/config/local-config.json
        break;
      case 1: // running on tomcat (locally or on mbfps server, with no RP alias)
        portalUiPath = `/${pathArr[0]}`;
        portalNgPath = portalUiPath.replace(/portal-ui/, 'portal_ng');
        config.paths[`portalUiPath`] = portalUiPath;
        config.paths[`portalNgPath`] = portalNgPath;
        config.paths[`backendBase`] = window.location.origin;
        // Apache config will route to local-config.json outside of portal-ui
        config.paths[`localConfigPath`] = `${portalUiPath}/assets/config/local-config.json`;
        break;
      case 2:       // running on tomcat (on mbfps server or through RP, with RP alias)
      default:
        rpAliasPath = `/${pathArr[0]}`;
        portalUiPath = `/${pathArr[1]}`;
        portalNgPath = portalUiPath.replace(/portal-ui/, 'portal_ng');
        config.paths[`rpAliasPath`] = rpAliasPath;
        config.paths[`portalUiPath`] = portalUiPath;
        config.paths[`portalNgPath`] = portalNgPath;
        config.paths[`backendBase`] = window.location.origin;
        // Apache config will route to local-config.json outside of portal-ui
        config.paths[`localConfigPath`] = `${rpAliasPath}${portalUiPath}/assets/config/local-config.json`;
        break;
    }
    this.updateConfig(config);
  }

  fetchConfigFile(fileUrl: string): Observable<any> {
    return this.http.get(fileUrl).pipe(shareReplay());
  }

  fetchConfigFileAsPromise(fileUrl: string): Promise<any> {
    return this.fetchConfigFile(fileUrl).toPromise();
  }

  fetchComponentConfig(name: string): Observable<any> {
    let config = this.compositeAppConfig.componentConfig[name];
    if (!config) {
      const configPath = this.compositeAppConfig.componentConfigFile[name];
      config = this.fetchConfigFile(configPath);
      this.compositeAppConfig.componentConfig[name] = config;
    }
    return config;
  }

  loadEnvConfig(): void {
    const workspaceVariable = 'workspaces';
    const backendPath = this.getBackendPath('env-config');
    this.fetchConfigFile(backendPath).subscribe(envConfig => {
      if (envConfig) {
        this.envConfig.next(envConfig);
        if (envConfig[workspaceVariable]) {
          this.workspaces.next(envConfig[workspaceVariable]);
        }
      }
    });
  }

  updateConfig(config: any): void {
    merge(this.compositeAppConfig, config);
  }

  getConfig(): Configuration {
    return this.compositeAppConfig;
  }

  public getPath(pathName: string): string {
    return this.compositeAppConfig.paths[pathName] || '';
  }

  public getRpPath(): string {
    return `${this.getPath('backendBase')}${this.getPath('rpAliasPath')}`;
  }

  public getBackendPath(pathName?: string): string {
    let path = '';
    if (pathName) {
      path = this.getPath(pathName);
    }
    return `${this.getPath('backendBase')}${this.getPath('rpAliasPath')}${this.getPath('portalNgPath')}${path}`;
  }

  public getPortalUIPath(pathName?: string): string {
    let path = '';
    if (pathName) {
      path = this.getPath(pathName);
    }
    return `${this.getPath('backendBase')}${this.getPath('rpAliasPath')}${this.getPath('portalUiPath')}${path}`;
  }

  public getPortalNgPath(pathName?: string): string {
    let path = '';
    if (pathName) {
      path = this.getPath(pathName);
    }
    return `${this.getPath('backendBase')}${this.getPath('rpAliasPath')}${this.getPath('portalNgPath')}${path}`;
  }

  public getRoute(routeName: string): any {
    return this.compositeAppConfig.routing[routeName] || '';
  }

  public getFeatureSwitch(switchName: string): string {
    return this.compositeAppConfig.features[switchName] || '';
  }

  public getPolling(pollingName: string): string {
    return this.compositeAppConfig.polling[pollingName] || '';
  }

  public getAnalytics(analyticsName: string): any {
    return this.compositeAppConfig.analytics[analyticsName] || '';
  }

  public getProperty(propertyName: string): any {
    return this.compositeAppConfig.properties[propertyName] || '';
  }

  getWorkspaces(): Observable<Workspace[]> {
    return this.workspaces;
  }

  getWorkspace(name: string): Observable<Workspace> {
    const workspace = new BehaviorSubject({
      id: '',
      shortName: '',
      longName: '',
      type: '',
      templateFile: '',
      icon: '',
      menuIcon: '',
      color: '',
      unlisted: true
    });
    this.workspaces.subscribe(ws => {
      if (ws && ws.length > 0) {
        workspace.next(ws.filter(w => w.shortName === name)[0]);
      }
    });
    return workspace;
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      if (error.status === 404) {
        return {};
      }
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}

export interface Configuration {
  paths: {};
  routing: {};
  features: {};
  polling: {};
  analytics: {};
  properties: {};
  componentConfigFile: {};
  componentConfig: {};
}
