export interface ProductInfoResponse    {
    code: string;
    data: ProductInfo;
}

export interface Image  {
    id: string;
    type: string;
    imageData: string;
    indexValue: number;
    promoted: boolean;
}

export interface AddedToWidget  {
    id: string;
    shortName: string;
    longName: string;
    type: string;
    templateFile: string;
}

export interface ProductInfo {
    id: string;
    shortName: string;
    longName: string;
    type: string;
    description: string;
    shortDescription: string;
    brandName: string;
    url: string;
    categoryName: string;
    color: string;
    colorBand: string;
    icon: string;
    hasUnplannedMsg: string;
    hasPlannedMsg: string;
    hasLargerSizes: string;
    authorized: boolean;
    companyAuthorized: boolean;
    appSuspended: boolean;
    addedTo: AddedToWidget[];
    images: Image[];
    altUrl: string;
    teamRoom: boolean;
}

export interface BrandProperties    {
    brand: BrandProperty;
}

export interface BrandProperty    {
    inPsp: boolean;
    logo: string;
}

export enum ProductDisplay  {
    AddToFavs = 'Add to my Favorites',        // Basic Widget, add
    AddedToFavs = 'Remove from My Favorites',    // Basic Widget already added
    AddToHome = 'Add to MBF Home',             // dashboard
    AddedToHome = 'Remove from MBF Home',         // dashboard
    NoAction = 'noAction',                 // MBF Support Page
    RequestAccess = 'Request Access',      // widget, user does not have access
    Open = 'Open',                         // widget, user has access
    NoOpen = 'noOpen',                     // user cannot open
    AvailableOnHome = 'Available on MBF Home',     // avaiable on home
    AvailableOnFav = 'Available in My favorites',     // avaiable on home
    StarRequestAccess = 'Create Star Request',
}

export interface ProductAction  {
    action: ProductDisplay;
    url: string;
}

export interface ProductActions {
    actionArea: ProductAction;
    openArea: ProductAction;
}

export class ProductActionsImpl implements ProductActions {
    actionArea: ProductAction;
    openArea: ProductAction;
    constructor()   {
        this.actionArea = {
            action: ProductDisplay.NoAction,
            url: ''
        };
        this.openArea = {
            action: ProductDisplay.NoOpen,
            url: ''
        };
    }
}


