<!-- Performance improvement - loading image early-->
<ng-container>
    <img *ngIf="tutorialSlides" class="hidden" src="{{tutorialSlides[1].media}}" />
    <img *ngIf="tutorialSlides" class="hidden" src="{{tutorialSlides[5].media}}" />
</ng-container>

<div class="tutorialDialog" id="tutorialDialog" [ngSwitch]="layoutName">
    <div *ngSwitchCase="'welcome'">
        <div class="modal-header text-center tutorial-title-background flex">
            <div class="tutorial-modal-title">Application</div>
            <mat-icon (click)="closeDialog()" class="ml-auto cursor-pointer close-icon">close</mat-icon>
        </div>
        <mat-dialog-content>
            <div class="flex">
                <div class="w-full">
                    <div class="tutorial-content">Find and explore <b>Applications</b> available to you on {{plateformName}}.</div>
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div *ngSwitchCase="'content'">
        <div class="modal-header text-center tutorial-title-background flex">
            <div class="tutorial-modal-title">{{tutorialSlides[currentIndex].header}}</div>
            <mat-icon (click)="closeDialog()" class="ml-auto cursor-pointer close-icon">close</mat-icon>
        </div>
        <mat-dialog-content>
            <div class="flex">
                <div class="w-full">
                    <div class="tutorial-content" [innerHTML]="tutorialSlides[currentIndex].content"></div>
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div class="content-with-media" *ngSwitchCase="'contentWithMedia'">
        <div class="modal-header text-center">
            <div>
                <div *ngIf="!loaded" class="min-h-20">
                    <mat-spinner class="spinnerCls" diameter="25"></mat-spinner>
                </div>
                <img class="tutorial-media img-responsive app-card-image" [hidden]="!loaded" (load)="loaded = true" src="{{tutorialSlides[currentIndex].media}}"/>
            </div>
            <div class="tutorial-modal-title close-div">{{tutorialSlides[currentIndex].header}}</div>
            <mat-icon (click)="closeDialog()" class="ml-auto cursor-pointer close-icon">close</mat-icon>
        </div>
        <mat-dialog-content class="pt-0">
            <div class="flex">
                <div class="w-full">
                    <div class="tutorial-content" [innerHTML]="tutorialSlides[currentIndex].content"></div>
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div class="flex p-2">
        <div class="flex w-full">
            <div class="flex page-number" *ngIf="tutorialSlides && currentIndex>0"> {{currentIndex}} of {{tutorialSlides.length -1}}</div>
            <div class="flex ml-auto pr-6">
                <button *ngIf="currentIndex!=(tutorialSlides.length - 1)" mat-button tabindex=-1 class="action-buttons" color="accent" (click)="next()">
                    <span class="font-BoeingMeso-reg font-semibold letter-spacing-1">NEXT</span>
                </button>
                <button *ngIf="currentIndex==(tutorialSlides.length - 1)" mat-button tabindex=-1 class="action-buttons" color="accent" (click)="closeDialog()">
                    <span class="font-BoeingMeso-reg font-semibold letter-spacing-1">DONE</span>
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Moblie view -->
<div class="tutorialDialogMobile" id="tutorialDialogMobile">
    <div>
        <div class="modal-header text-center tutorial-title-background flex">
            <div class="tutorial-modal-title">Welcome to {{plateformName}}</div>
            <mat-icon (click)="closeDialog()" class="cursor-pointer close-icon-mobile">close</mat-icon>
        </div>
        <mat-dialog-content>
            <div class="flex">
                <div class="w-full">
                    <div class="tutorial-content">We have prepared an introductory tutorial for you that is best viewed in larger screen sizes. To view the tutorial, please access {{plateformName}} using a larger screen size and click "Tutorial" from the help menu.</div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
</div>