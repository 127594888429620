<div class="text-px-14">
    <mat-dialog-content>
        <div>
            <mat-icon *ngIf="data.isSuccess" class="mat-palette-green-700 text-px-60">check_circle</mat-icon>
            <mat-icon *ngIf="!data.isSuccess" class="warn-color text-px-60"> error_outline</mat-icon>
        </div>
        <div class="pt-6 pb-16">{{data.Title}}</div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close color="accent">DISMISS</button>
    </mat-dialog-actions>
</div>