import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppConfigService } from 'src/app/core/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class WorkspaceOptionsService {

  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) { }

  resetWorkspace(workspaceId: string) {
    const endpoint = this.appConfigService.getBackendPath('getWidget') + '/' + workspaceId + '/layouts/reset';
    return this.httpClient.post<any>(endpoint, {}).pipe(catchError(this.error));
  }

  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

}
