import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { FooterData, LinkCategory, Link, SocialMedia, Brands } from 'src/app/footer/footer-data';
import { AppConfigService } from 'src/app/core/app-config.service';
import { Workspace } from 'src/app/workspace/workspace';
import { FooterService } from 'src/app/footer/footer.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { WorkspaceService } from '../workspace/workspace.service';

@Component({
  selector: 'app-footer-base',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterBaseComponent implements OnInit {
  footerService: FooterService;
  appConfigService: AppConfigService;
  workspaceService: WorkspaceService;
  footerData: FooterData;
  linkCategories: LinkCategory[];
  brands: Brands = {
    desktopHeading: '',
    mobileHeading: '',
    brandList: [] as SocialMedia[]
  } as Brands;
  socialMedia: SocialMedia[] = [] as SocialMedia[];
  communityBase: string;
  mbfHome = false;
  envConfig: {};
  currYear = new Date().getFullYear();
  // show build, configuration in hidden div
  showInfoDiv = false;
  infoEnvProperties = ['shortServername', 'azureLocation', 'environment', 'portal-ng-buildTag', 'portal-ng-buildUser', 'portal-ng-buildTimestamp', 'portal-ng-gitBranch', 'portal-ng-gitCommit'];
  infoProperties = ['portal-ui-buildTag', 'portal-ui-buildUser', 'portal-ui-buildTimestamp', 'portal-ui-gitBranch', 'portal-ui-gitCommit'];

  constructor(
    public cd: ChangeDetectorRef,
    footerService: FooterService,
    appConfigService: AppConfigService,
    public router: Router,
    public location: Location,
    workspaceService: WorkspaceService
  ) {
    this.footerService = footerService;
    this.appConfigService = appConfigService;
    this.workspaceService = workspaceService;
  }

  ngOnInit(): void {
    // get oemPlatformAcronym (MBF or MMF) from env_config,
    // and use that to get the appropriate footer data json
    this.appConfigService.envConfig.subscribe(config => {
      // tslint:disable-next-line: no-string-literal
      if (config['oemPlatformAcronym']) {
        this.envConfig = config;
        // tslint:disable-next-line: no-string-literal
        this.getFooterData(config['oemPlatformAcronym']);
      }
    });
    this.communityBase = `${this.appConfigService.getPortalUIPath()}/index.html#/Platform/workspace/`;
    this.setMbfHome();
    this.router.events.subscribe(val => {
      this.setMbfHome();
    });
    this.cd.markForCheck();
  }

  getFooterData(oemPlatformAcronym: string): void {
    // workspace data is from an endpoint separate from footer data
    // make requests in parallel and combine them when both return
    const footerDatas = this.footerService.getFooterData(oemPlatformAcronym);
    const workspaces = this.workspaceService.getWorkspacesProp(true);
    this.cd.markForCheck();
    forkJoin([footerDatas, workspaces]).subscribe(results => {
      this.footerData = results[0];
      this.brands = this.footerData.brands;
      this.socialMedia = this.footerData.socialMedia;
      this.linkCategories = this.footerData.links.linkCategories.filter((item) => item.title !== 'Communities');
      this.linkCategories.forEach(item => {
        item.links.forEach(val =>{
          if(val.type === 'rp'){
            val.url = this.envConfig["rpAlias"] + val.url;
          }
        })
      });
      this.cd.markForCheck();
      // filter unlisted workspaces, transform to linkCategory type
      if (results && results[1] && Array.isArray(results[1].data)) {
        const wsr = results[1].data
          .filter((ws: Workspace) => ws.unlisted === false)
          .map(ws => this.buildWorkspaceLink(ws));
        if (wsr.length > 0) {
          for (const linkCategories of this.linkCategories){
            if (linkCategories.title === 'Portals'){
              for (const w of wsr){
                linkCategories.links.push(w);
              }
            }
          }
        }
      }
      this.cd.markForCheck();
    });
  }
  setMbfHome() {
    this.mbfHome = (this.location.path() === ''
      || this.location.path() === this.appConfigService.getRoute(`mbfHome`)
      || this.location.path() === this.appConfigService.getRoute(`mbfHomeWorkspace`));
  }
  toggleInfo(): void {
    this.showInfoDiv = !this.showInfoDiv;
  }

  getProperty(key: string) {
    return this.appConfigService.getProperty(key);
  }

  // format Workspace so we can display it as a link
  buildWorkspaceLink(ws: Workspace): Link {
    return { title: ws.longName, url: `${this.communityBase}${ws.id}`, urlTarget: '_self' };
  }

  buildMbfSupportPageUrl(type): string {
    let url = '';
    // tslint:disable-next-line: no-string-literal
    const mbfPspId = this.envConfig['mbfPspId'];
    switch (this.appConfigService.getProperty(`mbfSupportPageTarget`)) {
      case 'portal_ng':
        type = 'portal_ng';
        url = `index.html#/Platform/apps/${mbfPspId}`;
        break;
      case 'portal-ui':
      default:
        type = 'portal-ui';
        url = `#/Platform/psp/${mbfPspId}`;
        break;
    }
    this.cd.markForCheck();
    return url;
  }

  urlClicked(link) {
    const url: string = this.buildLink(link.url, link.type, link.title);
    if (url.toLowerCase().endsWith(window.location.hash.toLowerCase())) {
      window.scrollTo(0, 0);
    } else {
      window.open(url, link.urlTarget ? link.urlTarget : '_self');
    }
    this.cd.markForCheck();
    return false;
  }

  directUrlClicked(url, target) {
    if (url.toLowerCase().endsWith(window.location.hash.toLowerCase())) {
      window.scrollTo(0, 0);
    } else {
      window.open(url, target ? target : '_self');
    }
    this.cd.markForCheck();
    return false;
  }

  buildLink(url: string, type: string = 'external', title: string = ''): string {
    let updatedUrl;
    if (title === this.appConfigService.getProperty(`partpageTitle`)) {
      url = this.envConfig[`partpageUrl`];
    }
    if (title === this.appConfigService.getProperty(`mbfSupportPageTitle`)) {
      url = `#/Platform/psp/${this.envConfig['mbfPspId']}`;
    }
    if (title === 'Access Administrator Support') {
      url = this.buildAccessAdminLink();
    }
    if (title === 'Product and Data Access (Access Summary)') {
      url = this.buildAccessSummaryLink();
    }
    switch (type) {
      case 'portal_ng':
        updatedUrl = `${this.appConfigService.getPortalNgPath()}/${url}`;
        break;
      case 'rp':
        if (url.startsWith('/') || url === '') {
          updatedUrl = url;
        // tslint:disable-next-line: no-string-literal
        } else if (this.envConfig['isThroughRp']) {
          // tslint:disable-next-line: no-string-literal
          updatedUrl = `${this.envConfig['rpAlias']}${url}`;
        } else {
          updatedUrl = `/${url}`;
        }
        break;
      case 'external':
      default:
        updatedUrl = url;
        break;
    }
    this.cd.markForCheck();
    return updatedUrl;
  }
  buildAccessAdminLink() {
      let url = '';
      const accessAdminUrl = 'accessAdminUrl';
      const mbfAdminAccessId = this.envConfig[accessAdminUrl];
      url = `#/Platform/psp/${mbfAdminAccessId}`;
      return url;
  }
  buildAccessSummaryLink() {
    let url = '';
    const accessSummaryUrl = 'accessSummaryUrl';
    url = this.envConfig[accessSummaryUrl];
    return url;
  }

}
