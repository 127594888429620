import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BrowserEolDialogComponent } from './browser-eol-dialog/browser-eol-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-browser-eol',
  templateUrl: './browser-eol.component.html',
  styleUrls: ['./browser-eol.component.css']
})
export class BrowserEolComponent implements OnInit {

  browserType = '';
  constructor(
    private router: Router,
    private matDialog: MatDialog) { }

  ngOnInit(): void {
    this.getBrowserSelectionCookie();
  }

  getBrowserSelectionCookie() {
    const hasSeenEOL = sessionStorage.getItem('sentToEOL');
    if (!hasSeenEOL || hasSeenEOL === '0') {
      this.callBrowserEOLDialog('TridentEngine');
    } else {
      this.router.navigate(['/Platform/workspace/']);
    }
  }

  callBrowserEOLDialog(browserTypeArg: any){
    const dialogRef = this.matDialog.open(BrowserEolDialogComponent, {
      panelClass: 'user-eol-container-dialog',
      backdropClass: 'user-eol-container-dialog-backdrop-background',
      autoFocus: false,
      disableClose: true,
      data: {browserType: browserTypeArg}
    });
  }
}
