import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExportService } from '../../workspace-widget/custom-widgets/mrcm-widget/export.service';

@Component({
  selector: 'app-compound-count',
  templateUrl: './compound-count.component.html',
  styleUrls: ['./compound-count.component.css']
})
export class CompoundCountComponent implements OnInit {
  payload: string;
  title: string;
  content = '';
  // tslint:disable-next-line: max-line-length
  constructor(private exportService: ExportService, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.data.allUser === true) {
      this.title = 'All users will be able to see the content';
    }
    else if (data.data.noUser === true) {
      this.title = 'No users will be able to see the content';
 }
    else if (data.data.userCount > 0) {
      this.title = 'Approximately ' + data.data.userCount + ' users will be able to see the content';
 }
    for (let i = 0; i < data.data.userCCIDCombo.length; i++) {
      if (i !== (data.data.userCCIDCombo.length - 1)) {
        this.content = this.content + data.data.userCCIDCombo[i] + ', ';
      }
      else {
        this.content = this.content + data.data.userCCIDCombo[i];
      }
    }

  }
  ngOnInit(): void {
  }
  download() {
    this.payload = this.data.data.userCCIDCombo;
    this.exportService.downloadFileforCount(this.payload, 'CountDownload', this.payload, '');

  }
}
