<div *ngIf="loading" [ngClass]="{'content-loading zindex':loading,'content-loaded':!loading}">
    <mat-spinner diameter="25" strokeWidth="5"></mat-spinner>
</div>
<div class="folder-dialog-width">
    <div mat-dialog-title>
        <span class="text-px-16 font-meso-hairline">{{action}} Folder </span>
    </div>
    <mat-dialog-content>
        <form [formGroup]="formGroup">
            <div class="font-meso  fix-mat-form-field add-folder-dialog">
                <mat-form-field appearance="fill" class="text-px-16 w-full">
                    <mat-label>Name</mat-label>
                    <input matInput maxLength="38" formControlName="folderName" placeholder="Name" required>
                    <mat-error class="pb-4"
                        *ngIf="formGroup.get('folderName').hasError && formGroup.get('folderName').hasError('duplicate')">
                        <span>The folder name already exists</span>
                    </mat-error>
                </mat-form-field>
                <br>
                <mat-form-field appearance="fill" class="text-px-16 w-full">
                    <mat-label>Description</mat-label>
                    <textarea matInput class="textarea_resize" maxLength="500" formControlName="description"
                        cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5" placeholder="Description"></textarea>
                </mat-form-field>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <div class="flex font-meso">
            <button mat-button color="accent" class="text-px-14 uppercase"
                (click)="closeDialog()"><span>cancel</span></button>
            <button *ngIf="action=='Add'" mat-button color="accent" class="text-px-14 uppercase" (click)="doAction()"
                [disabled]="!formGroup.valid" [analyticTagging]="{'eventId':'addFolder','srcCtrl':this,'obj':formGroup.get('folderName').value}"><span>Add</span></button>
            <button *ngIf="action=='Edit'" mat-button color="accent" class="text-px-14 uppercase" (click)="EditAction()"
                [disabled]="!formGroup.valid"><span>Update</span></button>
        </div>
    </mat-dialog-actions>
</div>