import { Injectable } from '@angular/core';
import { Adapter } from 'src/app/core/interfaces/adapter';
import { RolePermissionsItem } from 'src/app/user/role-permissions-item';

@Injectable({
    providedIn: 'root'
  })
export class RolePermissionsItemAdapter implements Adapter<RolePermissionsItem> {
    adapt(item: any): RolePermissionsItem {
        return {
            permissionId: item.permissionId || '',
            permissionName: item.permissionName || ''
        };
    }
}

@Injectable({
    providedIn: 'root'
  })
export class RolePermissionsItemArrayAdapter implements Adapter<RolePermissionsItem[]> {
    constructor(public rpia: RolePermissionsItemAdapter) {}

    adapt(permissions: any): RolePermissionsItem[] {
        const rolePermissions: RolePermissionsItem[] = new Array<RolePermissionsItem>();
        for (const permission of permissions) {
            rolePermissions.push(this.rpia.adapt(permission));
        }
        return rolePermissions;
    }
}
