import { NgZone, Pipe, PipeTransform } from '@angular/core';
import { AppConfigService } from 'src/app/core/app-config.service';

@Pipe({
  name: 'footerBuildLink'
})
export class FooterBuildLinkPipe implements PipeTransform {

  constructor(private appConfigService: AppConfigService, private ngZone: NgZone) { }

  transform(envConfig: any, url: string, type: string = 'external', title: string = ''): unknown {
    let updatedUrl;
    this.ngZone.runOutsideAngular(() => {
      if (title === this.appConfigService.getProperty(`partpageTitle`)) {
        url = envConfig[`partpageUrl`];
      }
      if (title === this.appConfigService.getProperty(`mbfSupportPageTitle`)) {
        url = `#/Platform/psp/${envConfig['mbfPspId']}`;
      }
      switch (type) {
        case 'portal_ng':
          updatedUrl = `${this.appConfigService.getPortalNgPath()}/${url}`;
          break;
        case 'rp':
          if (url.startsWith('/') || url === '') {
            updatedUrl = url;
          } else if (envConfig.isThroughRp) {
            updatedUrl = `${envConfig.rpAlias}${url}`;
          } else {
            updatedUrl = `/${url}`;
          }
          break;
        case 'external':
        default:
          updatedUrl = url;
          break;
      }
    });
    return updatedUrl;
  }

}
