import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { AppConfigService } from '../core/app-config.service';

@Injectable()
export class XsrfInterceptor implements HttpInterceptor {

    logoutUrl: any;
    envConfig = {};
    constructor(public appConfigService: AppConfigService, private router: Router) {
        this.appConfigService.envConfig.subscribe(config => {
            this.envConfig = config;
        });
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req && (req.method === 'POST' || req.method === 'PUT' || req.method === 'PATCH' || req.method === 'DELETE')) {
            const csrfToken = this.tokenGenerator() as string;
            // custom csrf token name to avoid collision with other apps behind RP
            document.cookie = `CSRF-TOKEN-MBFPS=${csrfToken}; path=/`;
            req = req.clone({ headers: req.headers.set('X-CSRF-TOKEN', csrfToken) });
        }

        return next.handle(req).pipe(
            tap(evt => {
                if (evt instanceof HttpResponse) {
                    if (evt.body) { }
                }
            }),
            catchError((err: any) => {
                const isThrough = 'isThroughRp';
                const logout = 'logoutUrl';
                const isThroughRp = this.envConfig[isThrough];
                this.logoutUrl = isThroughRp ? this.envConfig[logout] : `${window.location.origin}/index.html?t=${new Date().getTime()}`;
                if (err instanceof HttpErrorResponse) {
                    try {
                        if (err.message.indexOf('/ReverseProxy/') > 0) {
                            window.location.href = this.logoutUrl;
                        } else if (err.status == 401 && err.error != null && err.error["www-authenticate"] != null) {
                            console.log('FedProxy session expired or missing, so refresh it');
                            window.location.href = `${window.location.origin}/index.html?t=${new Date().getTime()}`;
                        }
                    } catch (e) {
                        window.location.href = this.logoutUrl;
                    }
                }
                return throwError(err);
            })
        );
    }

    private tokenGenerator(): string {
        return (Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)).toString();
    }
}
