import { Component, OnInit } from '@angular/core';
import { AppConfigService } from 'src/app/core/app-config.service';

@Component({
  selector: 'app-nxg-skinny-footer-build-info',
  templateUrl: './nxg-skinny-footer-build-info.component.html',
  styleUrls: ['./nxg-skinny-footer-build-info.component.scss']
})
export class NxgSkinnyFooterBuildInfoComponent implements OnInit {

  envConfig: {};
  buildinfo: any;

  constructor(private appConfigService: AppConfigService) { }

  ngOnInit(): void {
    this.appConfigService.envConfig.subscribe(config => {
      if (config['oemPlatformAcronym']) {
        this.envConfig = config;
      }
    });
    this.appConfigService.fetchComponentConfig('nxg-skinny-footer-build-info').subscribe(buildinfo => {
      this.buildinfo = buildinfo;
    });
  }

  getProperty(key: string) {
    return this.appConfigService.getProperty(key);
  }
}
