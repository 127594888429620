import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { UserApiService } from 'src/app/user/user-api.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/app-config.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class AppInsightMonitoringService {
    appInsights: ApplicationInsights;
    constructor(private userApiService: UserApiService, private httpClient: HttpClient, private appConfigService: AppConfigService) {

        this.getApplicationInsightData().subscribe(result=>{
            var Key = result.match(/var appInsightsInstrumentationKey(.*);/gi)
            var appInsightKey = Key[0].match(/'(.*)'/gi)[0].replace("'", "").replace("'", "");
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: appInsightKey,
                    enableAutoRouteTracking: true // option to log all route changes
                }
            });
            this.userApiService.getUser().subscribe((user) => {
                //var userData = { accountId: user.airlineCode, authenticatedUserId: user.userId };
                this.appInsights.loadAppInsights();
                this.appInsights.trackPageView({
                    properties: { "accountId": user.airlineCode, "authenticatedUserId": user.userId }
                });
            });
        })
    }

    logPageView(name?: string, url?: string) { // option to call manually
        this.appInsights.trackPageView({
            name: name,
            uri: url
        });
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name: name });
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        this.appInsights.trackMetric({ name: name, average: average });
    }

    logException(exception: Error, severityLevel?: number) {
        this.appInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights.trackTrace({ message: message });
    }

    public getApplicationInsightData(): Observable<string> {
        const endpoint = this.appConfigService.getPortalNgPath('env-config.js');
        return this.httpClient.get(endpoint, { responseType: 'text' }).pipe(catchError(this.error));
    }

    // Handle error
    error(error: HttpErrorResponse) {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
            errorMessage = error.error.message;
        } else {
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.log(errorMessage);
        return throwError(errorMessage);
    }

}