import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { HeaderService } from 'src/app/header/header.service';

@Component({
  selector: 'app-custom-snackbar',
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.css']
})
export class CustomSnackbarComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public notification: any,
    private headerService: HeaderService) { }

  ngOnInit(): void { }

  learnMore() {
    this.headerService.setLearnMoreClicked(true);
  }

  dismiss() {
    this.headerService.setDismissClicked(true);
  }

  dislayTitle(str: string) {
    if (str !== undefined) {
      if (str.length > 40) {
        str = str.substring(0, 40);
        return str + '...';
      }
      else {
        return str;
      }
    }
    else {
      return '';
    }
  }

}
