import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppConfigService } from 'src/app/core/app-config.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { SearchSvcRequest, SavedSearchSvcRequest } from './search-results.data';


@Injectable({
  providedIn: 'root'
})
export class SearchResultsService {

  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) { }

  private searchSvcReq: SearchSvcRequest;
  private savedSearchSvcReq: SavedSearchSvcRequest;

  // tslint:disable-next-line: member-ordering
  private componentMethodCallSource = new BehaviorSubject<any>('');


  // tslint:disable-next-line: member-ordering
  componentViewAllMessages = this.componentMethodCallSource.asObservable();

  // for related search
  private componentMethodCallSourceAirplane = new BehaviorSubject<any>('');


  componentViewAllMessagesAirplane = this.componentMethodCallSourceAirplane.asObservable();

  // Get Search Result Data(doSearch)
  public getSearchResult(searchData): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const endpoint = this.appConfigService.getBackendPath('getSearchResultEndpoint');
    return this.httpClient.post<any>(endpoint, JSON.stringify(searchData), httpOptions);
  }

  public setSearchRequestItem(searchRequest: SearchSvcRequest) {
    this.searchSvcReq = searchRequest;
  }

  // Convenience Method so outside services dont need to know how to set up a simple text search
  public setTextSearchRequest(searchText: string)
  {
    this.searchSvcReq = new SearchSvcRequest();
    this.searchSvcReq.searchText = searchText;
  }

  public getSearchRequestItem(isDefault: boolean): SearchSvcRequest {
    if(isDefault)
    {
      return new SearchSvcRequest();
    }
    else
    {
      return this.searchSvcReq;
    }
  }

  public setSaveSearchRequestItem(saveSearchRequestItem: SavedSearchSvcRequest) {
    this.savedSearchSvcReq = saveSearchRequestItem;
  }

  public getSaveSearchRequestItem(isDefault): SavedSearchSvcRequest {
    if(isDefault)
    {
      return new SavedSearchSvcRequest;
    }
    else
    {
      return this.savedSearchSvcReq;
    }
  }


  callComponentMethod(category: any) {
    this.componentMethodCallSource.next(category);
  }


  callComponentMethodAirplane(serNum: any) {
    this.componentMethodCallSourceAirplane.next(serNum);
  }

}
