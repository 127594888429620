// note: this matches SearchResult.java in portal_ng
export class SearchSvcTypeahead
{
    adescription: string;
    airplaneIdDisplayName: string;
    appIcon: string;
    appShortDescription: string;
    appType: string;
    astatus: string;
    ataList: string;
    cageCode: string;
    cata: string;
    category: string;
    color: string;
    database: string;
    description: string;
    ddcategory: string;
    dmodel: string;
    docType: string;
    documentLink: string;
    documentExcerpt: string;
    dtype: string;
    edescription: string;
    entireDocumentLink: string;
    etype: string;
    fotext: string;
    identifier: any;
    issDt: string;
    isSuspended: boolean;
    lmlink: string;
    manualSubType: string;
    model: string;
    owner: string;
    ownerDval: string;
    ownerDrw: string;
    ownerTBH: string;
    pdiList: string[];
    pbrand: string;
    privileges: string[];
    ppriority: string;
    productID: string; // <--
    pservice: string;
    purchasable;
    recTypes: string[];
    rev: string;
    rdt: string;
    ridentifier: any;
    rldt: string;
    sbclass: string;
    sbdocNbr: string;
    showMCWarning: boolean;
    showApp: boolean;
    showLMlink: boolean;
    source: string;
    sources: string[];
    status: string; // <-- 
    strams: string;
    strdal: string;
    strsdc: string;
    stype: string;
    tbhUrl: string;
    ttype: string;
    type: string;
    udidentifier: string;
    url: string;
    urlSet: SearchSvcUrl[];
    userAccess: boolean;
    wid: string;

    constructor()
    {
      this.showMCWarning = false;
    }
}

export class SearchSvcUrl
{
	title: string;
	url: string;
	action: string;
	actionParam: string;
	tip: string;
	icon: string;
	hide: string;
}

export class SearchSvcRequest
{
    globalTypeAheadItem: SearchSvcTypeahead;

    globalSearchCall: boolean; // Not needed?
    searchTypeAheadSelected: boolean;
    searchTextTyped: string;
    searchCategory: string;
    searchCategoryId: string;
    currentAirplaneSelection: string;
    currentrefName: string;
    currentrefValId: string;
    currentrefValName: string;
    currentRefinementSelection: string;
    searchText: string;
    searchData: string;
    version: number;
    valId: string;
    valName: string;
    refName: string;

    constructor()
    {
      this.globalSearchCall = false;
      this.searchTypeAheadSelected = false;
      this.searchTextTyped = null;
      this.searchCategory = 'All';
      this.searchCategoryId = null; 
      this.currentAirplaneSelection = null; 
      this.currentrefName = null; 
      this.currentrefValId = null;
      this.currentrefValName = null; 
      this.currentRefinementSelection = null; 
      this.searchText = ''; 
      this.searchData = null; 
      this.version = 0;
      this.valId = null; 
      this.valName = null; 
      this.refName = null;

      this.globalTypeAheadItem = new SearchSvcTypeahead();
    }
}

export class SavedSearchSvcRequest
{
  // ?? idk
  savePdata: string;

  constructor()
  {
    this.savePdata = null;
  }
}

export class DoSearchInput
{
  sortBy: string;
  queryType: string;

  pageNo: number;
  resultsPerPage: number;

  airlineCode: string;

  refinementValues: RefinementValue[];
  // note: portal has a searchUserData object that it appends to this data
}

// TODO: parse this into typescript
export class RefinementValue
{
  refSearchString: string;
	refValName: string;
	refValToolTip: string;

	// for use in output object only
	refValId: string;
	refValLeaf: boolean;
	refValSelected: boolean;
	// for use in output object only
	
	refValHits: number;
	implicitDim: boolean;
	refValValues: RefinementValue[];

	// for use in input object only
	refinementName: string;
	refinementType: string; // list, selection, category, text

  constructor(id: string, valName: string, refName: string, type: string)
  {
    this.refValId = id;
    this.refValName = valName;
    this.refinementName = refName;
    this.refinementType = type;
  }
}

export class RestulTerms
{
  // TODO: Affirm these are in fact string values
  hasIllegalCharacters: boolean;
  scrubbedKeywords: string;
  autoSuggestion: string;
  dymSuggestion: string;
  partsMessage: string;
  partsMessageUrl: string;
  partsMessageUrlText: string;

  constructor()
  {
    this.hasIllegalCharacters = false;
    this.scrubbedKeywords = null;
    this.autoSuggestion = null;
    this.dymSuggestion = null;
    this.partsMessage = null;
    this.partsMessageUrl = null;
    this.partsMessageUrlText = null;
  }
}