import { Expression } from 'src/app/shared/complex-filter/complex-compound-filter';


export interface ContentState {
    contentStateId: string;
    contentStateValue: string;
}
export interface Brand{
    id: string;
    logo: string;
    name: string;
    displayName: string;
}
export interface Widget {
    id: string;
    shortName: string;
    longName: string;
    type: string;
    templateFile: string;
}
export class FilterList {
    filterTypeId: string;
    filterId: any;
    filterOption: string;
    filterName: string;
    filterValues: any[];
    requireAll: boolean;
  }
export interface SystemMessage {
    messageRead?: boolean;
    messageId: string;
    messageTitle: string;
    messageText: string;
    messageType: string;
    messageUrl: string;
    seqNo: string;
    brand: Brand;
    authoringMethod: string;
    byApplication: string;
    messageUrlText: string;
    contactInformation: string;
    createdById: string;
    createdDateTime: string;
    effectiveDateTime: string;
    expiresDateTime: string;
    lastUpdatedDateTime: string;
    contentState: ContentState;
    emailPreferenceOverride: string;
    portalPreferenceOverride: string;
    filterType: string;
    userTarget: string;
    filterValue: Expression;
    widgets: Widget[];
    simplifiedContent: string;
    isExpanded?: false;
}

export interface UserCountInput{
    filterGroups: FilterList[];
}

export class Brand {
    id: string;
    name: string;
    displayName: string;
    logo: string;
}