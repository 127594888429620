import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppConfigService } from 'src/app/core/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  private readonly cacheApi: Map<string, Observable<string[]>> = new Map<string, Observable<string[]>>();

  constructor(private httpClient: HttpClient, private appConfigService: AppConfigService) { }

  // Check AuthGuard Access
  checkAuthGuardAccess(url: string): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const endpoint = this.appConfigService.getBackendPath('authGuardAccess');
    if (!this.cacheApi[endpoint]) {
      this.cacheApi[endpoint] = this.httpClient.post<any>(endpoint, url, httpOptions).pipe(catchError(this.error));
    }
    return this.cacheApi[endpoint];
  }

  // Handle Errors
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(errorMessage);
  }

}
