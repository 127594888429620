import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { AppConfigService } from 'src/app/core/app-config.service';
import { UserApiService } from 'src/app/user/user-api.service';
import { MatDialog } from '@angular/material/dialog';

import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BrowserEolService {

  private readonly cacheApi: Map<string, Observable<string[]>> = new Map<string, Observable<string[]>>();

  constructor(private httpClient: HttpClient, public appConfigService: AppConfigService, ) { }

  // Check EOL Eligibility
  checkEOLEligibility(): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const endpoint = this.appConfigService.getBackendPath('userEOLEligibileEndpoint');
    if (!this.cacheApi[endpoint]) {
      this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint, httpOptions).pipe(shareReplay(), catchError(this.error));
    }
    return this.cacheApi[endpoint];
  }


  // Submit EOL Browser Identification
  submitBrowserIdentification(selectedBrowser: any): Observable<any> {
    const httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    const endpoint = this.appConfigService.getBackendPath('userSubmitEOLEndpoint') + '/' + selectedBrowser;
    if (!this.cacheApi[endpoint]) {
      this.cacheApi[endpoint] = this.httpClient.get<any>(endpoint, httpOptions).pipe(shareReplay(), catchError(this.error));
    }
    return this.cacheApi[endpoint];
  }

  // Handle error
  error(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else {
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
