import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from 'src/app/core/app-config.service';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.css']
})
export class CookieBannerComponent implements OnInit {
  @Input() isFeatureActive: boolean;
  loadAPI: Promise<any>;

  constructor(private appConfigService: AppConfigService) {
    (window as any).closeCookieBannerEndpoint = this.appConfigService.getBackendPath('closeCookieBannerEndpoint');
    this.appConfigService.envConfig.subscribe(config => {
      // tslint:disable-next-line: no-string-literal
      (window as any).gdprAlertClose = config['alertDismissedDate'];
    });
  }

  ngOnInit(): void {
    if (this.isFeatureActive) {
      setTimeout(() => {
        (window as any).OneTrust.LoadBanner();
      }, 5000);
    }
  }

}
