import { Component, OnInit, Inject, Optional, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { WorkspaceService } from 'src/app/workspace/workspace.service';
import { Workspace } from 'src/app/workspace/workspace';
import { WidgetDialogComponent } from '../../dialogs/widget-dialog/widget-dialog.component';
import { WidgetService } from '../../widget.service';
import { WidgetData, ContainerData, CompleteWidgetdata, Template, ConstructWidgetOptions } from '../../widget-data';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-add-folder-dialog',
  templateUrl: './add-folder-dialog.component.html',
  styleUrls: ['./add-folder-dialog.component.css']
})
export class AddFolderDialogComponent implements OnInit, OnDestroy {
  worker: Worker;
  action: string;
  formGroup: FormGroup;
  actionableWorkspace: Workspace;
  parentId: string;
  parentName: string;
  widgetData: WidgetData = {
    id: null,
    shortName: null,
    longName: null,
    description: null,
    url: null
  };
  containerData: ContainerData = {
    containerId: null
  };
  completeWidget: CompleteWidgetdata = {
    widgetData: null,
    containerData: null
  };
  templateList: Template[];
  loading: boolean;
  locked: boolean;
  isWorkspaceToRefresh = false;
  urlItems: string = (window.location.href);
  workspaceType: string;
  editFolderData: any;
  folderName: string;
  folderDescription: string;
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  widgetId: string;
  data: any;
  workspace: any;
  favoriteId: string;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddFolderDialogComponent>,
    private widgetService: WidgetService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dataObj: any,
    private workspaceService: WorkspaceService,
    public dialog: MatDialog,
    private ngZone: NgZone) {
    this.data = dataObj;
    this.workspace = dataObj.workspace;
    this.action = dataObj.action;
    if (this.action === 'EditFolder') {
      this.action = 'Edit';
      this.editFolderData = dataObj;
      this.folderName = this.editFolderData.longName;
      this.folderDescription = this.editFolderData.description;
    }
  }

  ngOnInit(): void {
    this.initWebWorker();
    this.formGroup = this.formBuilder.group({
      folderName: [this.folderName ? this.folderName : null, [Validators.required, this.noWhitespace]],
      description: [this.folderDescription ? this.folderDescription : null],
    });
    this.setParentData();
    this.setFavoritesData();
  }

  ngOnDestroy() {
    this.worker.terminate();
  }

  initWebWorker() {
    if (typeof Worker !== 'undefined' && this.worker === undefined) {
      this.worker = new Worker('src/app/worker-process/workspace/workspace.worker', { type: 'module' });
      this.worker.onmessage = ({ data }) => {
        this[data.callbackFunc](data.response);
      };
    }
  }

  getActionableWorkspace(actionableWorkspaceData) {
    this.actionableWorkspace = actionableWorkspaceData.actionableWorkspace;
    this.parentId = actionableWorkspaceData.parentId;
    this.parentName = actionableWorkspaceData.parentName;
    this.locked = actionableWorkspaceData.locked;
    this.isWorkspaceToRefresh = true;
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  closeDialog() {
    this.dialogRef.close();
  }

  doAction() {
    this.loading = true;
    if (this.isWorkspaceToRefresh) {
      this.addFolder();
    }
    else {
      this.checkUniqueName(this.formGroup.value.folderName);
    }
  }

  EditAction() {
    this.loading = true;
    if (this.formGroup.value.folderName === this.data.longName) {
      this.updateFolder();
    } else {
      this.checkUniqueName(this.formGroup.value.folderName);
    }
  }

  setParentData() {
    if (this.urlItems.indexOf('Platform') !== -1 && this.urlItems.indexOf('workspace') !== -1) {
      this.workspaceService.workspaceData.pipe(take(1)).subscribe((workspace: any) => {
        if (workspace) {
          this.workspaceType = workspace.type;
          this.worker.postMessage({
            callbackFunc: 'getActionableWorkspace',
            listeningFunc: 'getActionableWorkspace',
            response: JSON.stringify(workspace, ['type', 'id', 'longName', 'locked', 'content', 'layout'])
          });
        }
      }, (error: any) => {
      });
    }
  }

  /**
   * Method to set the Favorites Id
   */
  setFavoritesData() {
    this.workspaceService.getHomeWorkspace().subscribe((response: any) => {
      const workspace = response.data;
      if (workspace) {
        this.worker.postMessage({
          callbackFunc: 'setFavoritesId',
          listeningFunc: 'getActionableWorkspace',
          response: JSON.stringify(workspace, ['type', 'id', 'longName', 'locked', 'content', 'layout'])
        });
      }
    }, (error: any) => {
    });
  }

  setFavoritesId(actionableWorkspaceData) {
    this.favoriteId = actionableWorkspaceData.parentId;
  }

  /**
   * Method to check and add folder
   */
  addFolder() {
    if (this.actionableWorkspace && !this.locked) {
      this.checkUniqueName(this.formGroup.value.folderName);
    }
    else {
      this.loading = false;
      this.openConfirmDialog('Unable to Add Folder. This workspace does not support the addition of folders. ' +
        'Please try again on a different workspace.', false);
    }
  }

  /**
   * Checking the name is unique or not
   * @param name Name of the folder
   */
  checkUniqueName(name: string) {
    this.widgetService.getWidgetUniqueName(name, 'BasicFolder').subscribe((response: any) => {
      if (response && response.body && response.body.data) {
        if (this.action === 'Add') {
          this.widgetService.getWidgetTemplate().subscribe((templateResponse: any) => {
            this.templateList = templateResponse.body.data;
            const constructWidgetOptions: ConstructWidgetOptions = {
              shortName: this.formGroup.value.folderName,
              longName: this.formGroup.value.folderName,
              description: this.formGroup.value.description,
              parentId: this.favoriteId,
              templateName: 'BasicFolder',
              templateList: this.templateList
            };
            const completeWidgetData = this.widgetService.getConstructWidget(constructWidgetOptions);
            this.createWidget(completeWidgetData);
          });
        } else if (this.action === 'Edit') {
          this.updateFolder();
        }
      } else {
        this.loading = false;
        this.formGroup.get('folderName').setErrors({ duplicate: true });
      }
    }, (error: any) => {
    });
  }

  updateFolder() {
    const constructWidgetOptions: ConstructWidgetOptions = {
      id: this.editFolderData.id,
      shortName: this.formGroup.value.folderName,
      longName: this.formGroup.value.folderName,
      description: this.formGroup.value.description,
      parentId: this.parentId
    };
    const completeWidgetData = this.widgetService.getConstructWidget(constructWidgetOptions);
    this.updateWidget(completeWidgetData);
  }

  updateWidget(completeWidgetData: CompleteWidgetdata) {
    this.widgetService.updateWidget(completeWidgetData).subscribe((response: any) => {
      if (response) {
        this.widgetId = response.data.id;
        this.getUpdatedWorkspace();
        this.workspaceService.getDefaultWorkspace().subscribe((workspace: any) => {
          if (workspace && workspace.data && workspace.data.longName === 'MBF Home') {
            this.workspaceService.getHomeWorkspace(false, true, false).subscribe((res) => {
              this.workspaceService.setDefaultWorkspace(of(res));
            });
          } else {
            this.workspaceService.getHomeWorkspace(false, true, false);
          }
        }, (error: any) => {
          this.workspaceService.getHomeWorkspace(false, true, false);
        });
      }
    }, (error: any) => {
    });
  }

  /**
   * Load the template name and id from api
   */
  getWidgetTemplate() {
    this.widgetService.getWidgetTemplate().subscribe((response: any) => {
      this.templateList = response.body.data;
    });
  }

  /**
   * @param title title is either add or remove or edit
   * @param success
   * Opening the alert dialog for success and warnings
   */
  openConfirmDialog(title, success) {
    const dialogRef = this.dialog.open(WidgetDialogComponent, {
      disableClose: true,
      autoFocus: false,
      width: '400px',
      data: { Title: title, isSuccess: success }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {

      }
    });
    if (success) {
      setTimeout(() => {
        dialogRef.close();
      }, 3000);
    }
  }

  /**
   * creating the folder widget by calling the create widget service
   * @param completeWidget Completed widget which is constructed for create widget
   */
  createWidget(completeWidget: CompleteWidgetdata) {
    this.widgetService.createWidget(completeWidget).subscribe((response: any) => {
      if (response) {
        this.dialogRef.close();
        this.widgetId = response.data.id;
        this.workspaceService.getDefaultWorkspace().subscribe((workspace: any) => {
          if (workspace && workspace.data && workspace.data.longName === 'MBF Home') {
            this.workspaceService.getHomeWorkspace(false, true, false).subscribe((res) => {
              this.workspaceService.setDefaultWorkspace(of(res));
            });
          } else {
            this.workspaceService.getHomeWorkspace(false, true, false);
          }
        }, (error: any) => {
          this.workspaceService.getHomeWorkspace(false, true, false);
        });
        this.openConfirmDialog('Added Folder ' + this.formGroup.value.folderName + ' to My Favorites', true);
        if (this.isWorkspaceToRefresh) {
          this.getUpdatedWorkspace();
        } else {
          this.loading = false;
        }
      }
    });
  }

  /**
   * widget is created or edited or removed then update the workspace with new data returned from api
   */
  getUpdatedWorkspace() {
    this.widgetService.getWidget(this.parentId).subscribe((workspace: any) => {
      if (this.workspaceType === 'BasicWorkspace' || this.workspaceType === 'BasicFolder') {
        this.workspaceService.setBasicWorkspace(workspace.data.layout);
      } else if (this.workspaceType === 'SingleColumnWorkspace') {
        this.workspaceService.setSingleColumWorkspace(this.parentId, workspace.data);
      }
      this.loading = false;
      if (this.action === 'Edit') {
        this.dialogRef.close({ event: this.action, data: workspace.data });
      }
    }, error => {
      this.loading = false;
    });
  }

  /**
   * Checking the input contains only the spaces or not
   * @param control form control value
   */
  public noWhitespace(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { true: 'whitespace' };
  }
}
