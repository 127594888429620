<div class="eol-content eol-width">
    <div class="eol-header">
        <div class="eol-header-title  font-semibold">
            Your Browser Setup Is Not Supported by MyBoeingFleet
        </div>
    </div>
    <div class="eol-body">
        <div>
            <div class="eol-body-content eol-body-font">
                The browser setup you are using to access MyBoeingfleet is not supported.  Either Edge IE mode is not configured properly, or you are using the old IE11 browser discontinued by Microsoft in 2022.  
                <u>Access to MBF products maybe blocked or not displayed properly.</u>
            </div>
            <div class="eol-body-content-list-padding">
                <div class="eol-header-title  font-semibold pb-5">
                    What Do I Need to Do?
                </div>
                <div class="eol-body-content eol-body-font pb-5">
                    <span>For details on how to proceed, select the browser you are currently
                        using.</span>
                </div>
            </div>
            <div>
                <div [ngClass]="{'align-radio-group':(selectedBrowser !=='Edge' && selectedBrowser !=='IE' ) }">
                    <mat-radio-group color="primary" class="flex flex-col pb-5 radio-content" (change)="browserTypeConfirmtion($event)">
                        <div id="ie11" [ngClass]="{ 'eol-radio-border': selectedBrowser==='IE' , 'eol-radio-no-border mb-6': (selectedBrowser !=='Edge' && selectedBrowser !=='IE' )} " class="order-1">
                            <div class="flex-radio-button-content">
                                <div class="align-radio-content-ie11" [ngClass]="{ 'align-when-selected  ': selectedBrowser==='Edge' }">
                                    <mat-radio-button value="IE">

                                        <span class="eol-body-content eol-body-font font-medium">I’m using IE11</span>

                                    </mat-radio-button>
                                </div>
                                <div>
                                    <img src="assets/img/browser-eol/ie11-logo.png " class="logo ">
                                </div>
                            </div>
                            <div *ngIf="selectedBrowser==='IE' " class="eol-body-content eol-body-font pt-5 ">
                                <div class="eol-body-content-list">

                                    <span class="pb-5">People still using IE11 are at risk of not being able to access products on MBF without notice.  Immediate action must be taken to upgrade to one of the browsers supported by MBF (Edge, Chrome, or Firefox). 
                                        See this <a class="cursor-pointer primary-color" [href]="ie11FAQUrl" [attr.download]="isDownload"
                                        [analyticTagging]="{ 'eventId': 'browserIdentificationContinue', 'srcCtrl': 'MBF: BrowserIdentification: Continue, optionSelected: '+ this.adobeSelection}">FAQ</a> for more information</span>
                                </div>
                            </div>
                        </div>
                        <div class="edge mb-6" [ngClass]="{ 'eol-radio-border mt-4': selectedBrowser==='Edge' , 'eol-radio-no-border mt-0': (selectedBrowser !=='Edge' && selectedBrowser !=='IE' )} " class="order-2">
                            <div class="flex-radio-button-content">
                                <div class="align-radio-content-edge" [ngClass]="{ 'align-when-selected  ': selectedBrowser==='IE' }">
                                    <mat-radio-button value="Edge">

                                        <span class="eol-body-content eol-body-font font-medium">I’m using Edge</span>

                                    </mat-radio-button>
                                </div>
                                <div class="pt-2">

                                    <img src="assets/img/browser-eol/edge-logo.png " class="logo ">

                                </div>
                            </div>
                            <div *ngIf="selectedBrowser==='Edge' " class="eol-body-content eol-body-font pt-5 ">
                                <div class="eol-body-content-list">
                                    <span>Edge with IE mode <u>should be turned off</u> when visiting most MBF applications (including the MBF home page).  If IE mode is not configured properly, you are at risk of not being able to access products on MBF without notice.</span>
                                </div>
                                <div class="eol-body-content-list pt-6 ">
                                    <span>Edge IE mode <u>should only be turned on</u> when visiting the following MBF applications:</span>
                                </div>
                                <div class="eol-body-content-list pt-3">
                                    <span>V1 Toolbox | V2 Toolbox | Non-Boeing Toolbox | Toolbox-Military | Product Standards</span>
                                </div>
                                <div class="flex flex-row pt-8 eol-body-content-list">
                                    <div><img class="edge-warning-height" src="assets/img/browser-eol/browser-eol-edge-warning-image.jpg " alt="Select to Turn Off IE Mode "></div>
                                    <div class="edge-message ">
                                        <p><b>To manually turn IE mode off/on,</b> select the icon in the upper right corner of your browser.</p>
                                        <p class="pt-6">If this icon is not present or you cannot select it, please contact your Electronic Access Focal (EAF) or IT browser administrator to correct your browser’s IE mode settings for MyBoeingFleet.</p>
                                        <p class="pt-6">See this <a class="cursor-pointer primary-color" [href]="edgeFAQUrl" [attr.download]="isDownload"
                                            [analyticTagging]="{ 'eventId': 'browserIdentificationContinue', 'srcCtrl': 'MBF: BrowserIdentification: Continue, optionSelected: '+ this.adobeSelection}">FAQ</a> for infomation on this issue.</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </mat-radio-group>
                </div>
            </div>
            <div *ngIf="selectedBrowser ==='Edge' || selectedBrowser ==='IE'" class="eol-body-content pb-5 pt-5 ">
                If further assistance is needed, please
                <a class="cursor-pointer primary-color " (click)="getURL( 'mbf-services/csp-ui', '_blank', 'rp', 'Contact Us') ">Contact Us.</a>
            </div>
        </div>
        <div class="eol-footer pt-4">
            <div class="eol-footer-continue-button pb-4">
                <button mat-button [disabled]="disableContinue " [ngClass]="{ 'eol-footer-enable-continue-button': !disableContinue, 'eol-footer-disable-continue-button': disableContinue} " 
                [analyticTagging]="{ 'eventId': 'browserIdentificationContinue', 'srcCtrl': 'MBF: BrowserIdentification: Continue, optionSelected: '+ this.adobeSelection}" (click)="submitContinue() ">CONTINUE</button>
            </div>
        </div>
    </div>
</div>