<div class="text-px-16">
    <div mat-dialog-content class="confirmation-alert-max-width">
        <div>
            <mat-icon *ngIf="data.type==='success'" class="text-px-60 success-color">check_circle</mat-icon>
            <mat-icon *ngIf="data.type==='error'" class="text-px-60 error-color">error_outline</mat-icon>
            <mat-icon *ngIf="data.type==='warning'" class="text-px-60 warn-color">warning</mat-icon>
        </div>
        <br>
        <div class="text-px-14">
            <span>{{data.title}}</span>
            <span>{{data.title?'!&nbsp;':''}}</span>
            <span>{{data.text}}</span>
        </div>
        <br>
    </div>
    <div mat-dialog-actions class="text-right float-right">
        <button tabindex="-1" mat-button mat-dialog-close color="accent" class="text-px-14 uppercase">DISMISS</button>
    </div>
</div>