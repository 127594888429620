<div class="search-tutorial-dialog" id="search-tutorial-dialog" [ngSwitch]="layoutName">

    <div *ngSwitchCase="'welcome'">
        <div class="modal-header text-center search-tutorial-title-background flex">
            <div class="search-tutorial-modal-title">Search {{plateformName}}</div>
            <mat-icon (click)="closeDialog()" class="ml-auto cursor-pointer c-#E0E0E0">close</mat-icon>
        </div>
        <mat-dialog-content class="h-min-24">
            <div class="flex">
                <div class="w-full">
                    <div class="search-tutorial-content">Here's a quick tutorial to show you around and point out a few useful features along the way.</div>
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div *ngSwitchCase="'content'">
        <div class="modal-header text-center search-tutorial-title-background flex">
            <div class="search-tutorial-modal-title">{{tutorialSlides[currentIndex].header}}</div>
            <mat-icon (click)="closeDialog()" class="ml-auto cursor-pointer c-#E0E0E0">close</mat-icon>
        </div>
        <mat-dialog-content class="h-min-24">
            <div class="flex">
                <div class="w-full">
                    <div class="search-tutorial-content" [innerHTML]="tutorialSlides[currentIndex].content"></div>
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div *ngSwitchCase="'contentWithMedia'">
        <div class="modal-header text-center">
            <div class="subdiv"><img class="search-tutorial-media img-responsive" src="{{tutorialSlides[currentIndex].media}}" /></div>
            <div class="search-tutorial-modal-title subdiv">
                {{tutorialSlides[currentIndex].header}}</div>
            <mat-icon (click)="closeDialog()" class="ml-auto cursor-pointer c-#E0E0E0 close-icon-postion">close</mat-icon>
        </div>
        <mat-dialog-content class="pt-0">
            <div class="flex">
                <div class="w-full">
                    <div class="search-tutorial-content" [innerHTML]="tutorialSlides[currentIndex].content"></div>
                </div>
            </div>
        </mat-dialog-content>
    </div>

    <div class="flex p-2">
        <div class="flex w-full">
            <div class="flex page-number" *ngIf="tutorialSlides && currentIndex>0"> {{currentIndex}} of {{tutorialSlides.length -1}}</div>
            <div class="flex ml-auto pr-6">
                <button *ngIf="currentIndex!=(tutorialSlides.length - 1)" mat-button tabindex=-1 class="action-buttons" color="primary" (click)="next()">
                    <span class="font-semibold font-BoeingMeso-reg letter-spacing-1 secondary">NEXT</span>
                </button>
                <button *ngIf="currentIndex==(tutorialSlides.length - 1)" mat-button tabindex=-1 class="action-buttons" color="primary" (click)="closeDialog()">
                    <span class="font-semibold font-BoeingMeso-reg letter-spacing-1 secondary">DONE</span>
                </button>
            </div>
        </div>
    </div>
</div>

<!-- Moblie view -->
<div class="search-tutorial-dialog-mobile" id="search-tutorial-dialog-mobile">
    <div>
        <div class="modal-header text-center search-tutorial-title-background flex">
            <div class="search-tutorial-modal-title">Search {{plateformName}}</div>
            <mat-icon (click)="closeDialog()" class="cursor-pointer c-#E0E0E0 close-icon-mobile">close</mat-icon>
        </div>
        <mat-dialog-content>
            <div class="flex">
                <div class="w-full">
                    <div class="search-tutorial-content">We have prepared an introductory tutorial for you that is best viewed in larger screen sizes. To view the tutorial, please access {{plateformName}} using a larger screen size and click "Tutorial" from the help menu.</div>
                </div>
            </div>
        </mat-dialog-content>
    </div>
</div>