import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.css']
})
export class ConfirmationModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() { }

  // When the user clicks the action button, return true
  // to the component observing the afterClose
  actionFunction() {
    this.closeModal(true);
  }
  callAction(action) {
    this[action]();
  }
  // If the user clicks the cancel button
  // just close the modal
  closeModal(resp: boolean = false) {
    this.dialogRef.close(resp);
  }

}
