import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'headerBuildUrl'
})
export class HeaderBuildUrlPipe implements PipeTransform {

  // tslint:disable-next-line: ban-types
  transform(fn: Function, ...args: any[]): any {
    return fn(...args);
  }

}
