import { Directive, Input, NgZone, ElementRef } from '@angular/core';
import { AnalyticTaggingService } from 'src/app/services/shared/analytic-tagging/analytic-tagging.service';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[analyticTagging]'
})
export class AnalyticTaggingDirective {
  // tslint:disable-next-line: no-input-rename
  @Input('analyticTagging') analyticTagging: any;
  @Input() event = 'click'; // pass desired event
  constructor(private ngZone: NgZone, private elRef: ElementRef, private analyticTaggingService: AnalyticTaggingService) { }

  // tslint:disable-next-line: use-lifecycle-interface
  ngOnInit() {
    this.ngZone.runOutsideAngular(() => {
      this.elRef.nativeElement.addEventListener(this.event, this.track.bind(this));
    });
  }
  track(event) {
    if (this.analyticTagging.eventId) {
      this.analyticTaggingService.track(this.analyticTagging.eventId, this.analyticTagging.srcCtrl, this.analyticTagging.obj);
    }
  }
  ngOnDestory() {
    this.elRef.nativeElement.removeEventListener(this.event, this.track);
  }
}
