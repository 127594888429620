import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/core/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  private portalUrl = "/portal_ng/logout";
  private newLogutServiceUrl: any;

  constructor(
    private appConfigService: AppConfigService
  ) {
    // Get the Logout URLs from Env Config
    this.appConfigService.envConfig.subscribe(config => {
      if (config['logoutServiceUrl']) {
        this.newLogutServiceUrl = config['logoutServiceUrl'];
      }
    });

    // Set the Portal Application Logout URL
    this.portalUrl = this.appConfigService.getBackendPath() + "/logout";
    this.portalUrl = this.portalUrl ? this.portalUrl : "/portal_ng/logout";
  }

  logout(clearStorage: boolean) {
    if(clearStorage) {
      this.clearStorage();
    }
    this.portalLogout();
    this.logoutRedirect(this.newLogutServiceUrl);
  }

  private clearStorage() {
    localStorage.removeItem('welcomeMsgAndNotificationDisplayed');
    sessionStorage.removeItem('ccid');
    sessionStorage.removeItem('sentToEOL');
    localStorage.removeItem('MBFSelectedBrowser');
    sessionStorage.removeItem('reloadPageBrowserType');
    sessionStorage.removeItem('breadCrumbList');
    window.sessionStorage.clear();
    localStorage.clear();
  }

  private portalLogout() {
    var xhttpPortal = new XMLHttpRequest();
    xhttpPortal.open("GET", this.portalUrl, true);
    xhttpPortal.send();
  }

  private logoutRedirect(logoutPageUrl: string) {
    window.location.href = logoutPageUrl;
  }
}