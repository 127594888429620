import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchClickService {

  searchClickOption = new BehaviorSubject<boolean>(false);
  searchClickOptionObservable = this.searchClickOption.asObservable();

  constructor() { }
  getclickOptions(): boolean{
    return this.searchClickOption.value;
  }
  setClickOptions(value: boolean): void {
    this.searchClickOption.next(value);
  }

}
