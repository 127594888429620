import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { AppConfigService } from 'src/app/core/app-config.service';
import { catchError, shareReplay  } from 'rxjs/operators';
import { PollingItem, PollingConfig } from './polling-interface';

@Injectable({
  providedIn: 'root'
})
export class PollingTimerService {
  pollingQueue: PollingItem[] = [{ key: 'polling', callback: () => { this.reLoadPolling(); } }];
  worker: Worker;
  // tslint:disable-next-line: variable-name
  private readonly cache_api: Map<string, Observable<string[]>> =
  new Map<string, Observable<string[]>>();

  constructor(
    private httpClient: HttpClient,
    private appConfigService: AppConfigService,
    private ngZone: NgZone) {
    this.ngZone.runOutsideAngular(() => {
      if (typeof Worker !== 'undefined') {
        this.worker = new Worker('./polling-webworker.worker', { type: 'module' });
        this.registerPolling(this.pollingQueue);
        this.worker.onmessage = ({ data }) => {
          if (data.key) {
            const item = this.getPollingElement(data.key);
            if (item.callback) {
              item.callback();
            }
          }
        };
      }
      this.initPolling();
    });
  }

  private initPolling() {
    this.getPollingConfig().subscribe((res) => {
      this.worker.postMessage({ func: 'initPolling', response: JSON.stringify(res) });
    });
  }

  private reLoadPolling() {
    this.getPollingConfig().subscribe((res) => {
      this.worker.postMessage({ func: 'reLoadPolling', response: JSON.stringify(res) });
    });
  }
  public registerPolling(pollingItems: PollingItem[]) {
    this.worker.postMessage({ func: 'registerPolling', response: JSON.stringify(pollingItems) });
    this.pushPollingElement(pollingItems);
  }
  public deregisterPolling(pollingItems: PollingItem[]): void {
    this.worker.postMessage({ func: 'deregisterPolling', response: JSON.stringify(pollingItems) });
    this.popPollingElement(pollingItems);
  }
  private getPollingConfig(): Observable<PollingConfig[]> {
    const endpoint = this.appConfigService.getBackendPath('pollingTimer');
    if (!this.cache_api[endpoint]){
      this.cache_api[endpoint] = this.httpClient.get<any>(endpoint).pipe(shareReplay(), catchError(this.error));
    }
    return this.cache_api[endpoint];

  }
  private popPollingElement = (pollingItems: PollingItem[]) => {
    pollingItems.forEach(element => {
      this.pollingQueue = this.pollingQueue.filter(x => x.key !== element.key);
    });
  }
  private pushPollingElement = (pollingItems: PollingItem[]) => {
    pollingItems.forEach(element => {
      const found = this.pollingQueue.find(item => item.key === element.key);
      if (!found) { this.pollingQueue.push(element); }
    });
  }
  private getPollingElement = (key: string) => {
    return this.pollingQueue.find(item => item.key === key);
  }
  // Handle error
  private error(error: HttpErrorResponse) {
    return throwError(undefined);
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngOnDestroy() {
    this.worker.terminate();
  }
}
