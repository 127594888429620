import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getNotificationHeader'
})
export class GetNotificationHeaderPipe implements PipeTransform {

  // tslint:disable-next-line: ban-types
  transform(fn: Function, ...args: any[]): any {
    return fn(...args);
  }

}
