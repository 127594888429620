import { Component, OnInit, Inject, Optional, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AddFolderDialogComponent } from '../../basic-folder/add-folder-dialog/add-folder-dialog.component';
import { WorkspaceService } from 'src/app/workspace/workspace.service';
import { WidgetDialogComponent } from '../../dialogs/widget-dialog/widget-dialog.component';
import { Workspace } from 'src/app/workspace/workspace';
import { ContainerData, WidgetData, CompleteWidgetdata, Template, ConstructWidgetOptions } from '../../widget-data';
import { WidgetService } from '../../widget.service';
import { take } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'app-add-link-dialog',
  templateUrl: './add-link-dialog.component.html',
  styleUrls: ['./add-link-dialog.component.css']
})
export class AddLinkDialogComponent implements OnInit, OnDestroy {
  worker: Worker;
  action: string;
  formGroup: FormGroup;
  parentName: string;
  loading: boolean;
  actionableWorkspace: Workspace;
  parentId: string;
  locked: boolean;
  widgetData: WidgetData = {
    id: null,
    shortName: null,
    longName: null,
    description: null,
    widgetShortDescription: null,
    url: null
  };
  containerData: ContainerData = {
    containerId: null
  };
  completeWidget: CompleteWidgetdata = {
    widgetData: null,
    containerData: null
  };
  urlItems: string = (window.location.href);
  templateList: Template[];
  linkName: string;
  linkUrl: string;
  description: string;
  widgetShortDescription: string;
  dialogData: any;
  workspaceType: string;
  isWorkspaceToRefresh = false;
  folderId: string;
  data: any;
  workspace: any;
  favoriteId: string;
  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddFolderDialogComponent>,
    private widgetService: WidgetService,
    private cd: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public dataObj: any,
    private workspaceService: WorkspaceService,
    public dialog: MatDialog) {
    this.action = dataObj.action;
    this.data = dataObj;
    this.workspace = dataObj.workspace;
    if (this.action === 'Edit' || this.action === 'Add') {
      this.dialogData = dataObj;
      if (dataObj.longName && dataObj.url) {
        this.linkName = dataObj.longName.substring(0, 50);
        this.linkUrl = dataObj.url;
        this.description = dataObj?.description;
        this.widgetShortDescription = dataObj?.description;

      }
    }
    else if (this.action === 'Edit Link') {
      this.dialogData = dataObj.data;
      this.action = 'Edit';
      if (dataObj.data.longName && dataObj.data.url) {
        this.linkName = dataObj.data.longName.substring(0, 50);
        this.linkUrl = dataObj.data.url;
        this.description = dataObj.data?.description;
        this.widgetShortDescription = dataObj.data?.description;

      }
    }
    else if (this.action === 'AddLink') {
      this.action = 'Add';
      this.dialogData = dataObj;
      this.folderId = dataObj.id;
      if (this.dialogData.linkUrl && this.dialogData.url) {
        this.linkName = this.dialogData.linkUrl.substring(0, 50);
        this.linkUrl = this.dialogData.url;
        this.description = dataObj?.description;
        this.widgetShortDescription = dataObj?.description;
      }
    }
  }

  ngOnInit(): void {
    this.initWebWorker();
    this.formGroup = this.formBuilder.group({
      linkName: [this.linkName ? this.linkName : null, [Validators.required, this.noWhitespace]],
      linkUrl: [this.linkUrl ? this.linkUrl : null, [Validators.required, this.noWhitespace]],
      description: [this.description ? this.description : null],
     // shortDescription: this.description

    });
    this.workspaceService.editOption.subscribe((response) => {
      this.cd.markForCheck();
    });
    this.setParentData();
    this.setFavoritesData();
  }

  ngOnDestroy() {
    this.worker.terminate();
  }

  closeDialog() {
    this.dialogRef.close();
    this.cd.markForCheck();
  }

  initWebWorker() {
    if (typeof Worker !== 'undefined' && this.worker === undefined) {
      this.worker = new Worker('src/app/worker-process/workspace/workspace.worker', { type: 'module' });
      this.worker.onmessage = ({ data }) => {
        this[data.callbackFunc](data.response);
      };
    }
  }

  getActionableWorkspace(actionableWorkspaceData) {
    this.actionableWorkspace = actionableWorkspaceData.actionableWorkspace;
    this.parentId = actionableWorkspaceData.parentId;
    this.parentName = actionableWorkspaceData.parentName;
    this.locked = actionableWorkspaceData.locked;
    this.isWorkspaceToRefresh = true;
  }

  /**
   * Check it has workspace or call the default workspace
   */
  doAction() {
    this.loading = true;
    this.widgetService.getWidgetTemplate().subscribe((response: any) => {
      this.templateList = response.body.data;
      if (this.isWorkspaceToRefresh) {
        this.checkActionableWorkspace();
      } else {
        this.createLinkWidget();
      }
    });
    this.cd.markForCheck();
  }

  /**
   *  Checking the workspace is actionable or locked
   */
  checkActionableWorkspace() {
    if (this.actionableWorkspace && !this.locked) {
      this.createLinkWidget();
    } else {
      this.loading = false;
      this.openConfirmDialog('Unable to Add Widget. This workspace does not support the addition of widgets.' +
        ' Please try again on a different workspace.', false);
    }
  }

  /**
   * Construct the widget paramters and call the create widget api
   */
  createLinkWidget() {
    const constructWidgetOptions: ConstructWidgetOptions = {
      shortName: this.formGroup.value.linkName,
      longName: this.formGroup.value.linkName,
      url: this.formGroup.value.linkUrl,
      description: this.formGroup.value?.description,
      widgetShortDescription: this.formGroup.value?.description,
      parentId: this.favoriteId,
      folderId: this.folderId,
      templateName: 'DragDropWidget',
      templateList: this.templateList
    };
    const completeWidgetData = this.widgetService.getConstructWidget(constructWidgetOptions);
    this.widgetService.createWidget(completeWidgetData).subscribe((response: any) => {
      if (response) {
        this.workspaceService.getDefaultWorkspace().subscribe((workspace: any) => {
          if (workspace && workspace.data && workspace.data.longName === 'MBF Home') {
            this.workspaceService.getHomeWorkspace(false, true, false).subscribe((res) => {
              this.workspaceService.setDefaultWorkspace(of(res));
            });
          } else {
            this.workspaceService.getHomeWorkspace(false, true, false);
          }
        }, (error: any) => {
          this.workspaceService.getHomeWorkspace(false, true, false);
        });
        if (this.isWorkspaceToRefresh) {
          this.getUpdatedWorkspace();
        } else {
          this.loading = false;
          this.dialogRef.close();
        }
      }
    });
    this.cd.markForCheck();
  }

  /**
   * widget is created or edited or removed then update the workspace with new data return from api
   */
  getUpdatedWorkspace() {
    this.widgetService.getWidget(this.parentId).subscribe((workspace: any) => {
      if (workspace && workspace.data) {
        if (this.workspaceType === 'BasicWorkspace' || this.workspaceType === 'BasicFolder') {
          this.workspaceService.setBasicWorkspace(workspace.data.layout);
        } else if (this.workspaceType === 'SingleColumnWorkspace') {
          this.workspaceService.setSingleColumWorkspace(this.parentId, workspace.data);
        }
        this.loading = false;
        this.dialogRef.close();
      }
    });
    this.cd.markForCheck();
  }

  /**
   * @param title title is either add or remove or edit
   * @param success
   * Opening the alert dialog for success and warnings
   */
  openConfirmDialog(title, success) {
    const dialogRef = this.dialog.open(WidgetDialogComponent, {
      width: '400px',
      data: { Title: title, isSuccess: success }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 1) {

      }
    });
    setTimeout(() => {
      dialogRef.close();
    }, 3000);
  }

  /**
   * Initially setting the parent data if creating the link from workspace page
   */
  setParentData() {
    if (this.urlItems.indexOf('Platform') !== -1 && this.urlItems.indexOf('workspace') !== -1) {
      this.workspaceService.workspaceData.pipe(take(1)).subscribe((workspace: any) => {
        if (workspace) {
          this.workspaceType = workspace.type;
          this.worker.postMessage({
            callbackFunc: 'getActionableWorkspace',
            listeningFunc: 'getActionableWorkspace',
            response: JSON.stringify(workspace, ['type', 'id', 'longName', 'locked', 'content', 'layout'])
          });
        }
      });
    }
  }

  /**
   * Method to set the Favorites Id
   */
  setFavoritesData() {
    this.workspaceService.getHomeWorkspace().subscribe((response: any) => {
      const workspace = response.data;
      if (workspace) {
        this.worker.postMessage({
          callbackFunc: 'setFavoritesId',
          listeningFunc: 'getActionableWorkspace',
          response: JSON.stringify(workspace, ['type', 'id', 'longName', 'locked', 'content', 'layout'])
        });
      }
    }, (error: any) => {
    });
  }

  setFavoritesId(actionableWorkspaceData) {
    this.favoriteId = actionableWorkspaceData.parentId;
  }

  noWhitespace(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { true: 'whitespace' };
  }

  /**
   * Construct and update the widget with given values
   */
  EditAction() {
    this.loading = true;
    const constructWidgetOptions: ConstructWidgetOptions = {
      id: this.dialogData.id,
      shortName: this.formGroup.value.linkName,
      longName: this.formGroup.value.linkName,
      url: this.formGroup.value.linkUrl,
      description: this.formGroup.value.description,
      widgetShortDescription: this.formGroup.value.description

    };
    const completeWidgetData = this.widgetService.getConstructWidget(constructWidgetOptions);
    this.widgetService.updateWidget(completeWidgetData).subscribe((response: any) => {
      if (response && response.data) {
        this.getUpdatedWorkspace();
        this.workspaceService.getDefaultWorkspace().subscribe((workspace: any) => {
          if (workspace && workspace.data && workspace.data.longName === 'MBF Home') {
            this.workspaceService.getHomeWorkspace(false, true, false).subscribe((res) => {
              this.workspaceService.setDefaultWorkspace(of(res));
            });
          } else {
            this.workspaceService.getHomeWorkspace(false, true, false);
          }
        }, (error: any) => {
          this.workspaceService.getHomeWorkspace(false, true, false);
        });
      }
    });
    this.cd.markForCheck();
  }

  /**
   * delete the widget with the layout id
   */
  deleteLink() {
    this.dialogRef.close({ event: this.action, dialogData: this.dialogData });
    this.cd.markForCheck();
  }

  closeDeleteDialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

}
