<div *ngIf="loading" [ngClass]="{'content-loading zindex':loading,'content-loaded':!loading}">
    <mat-spinner diameter="25" strokeWidth="5"></mat-spinner>
</div>
<div class="text-px-14">
    <mat-dialog-content>
        <mat-icon class="text-px-60">error_outline</mat-icon>
        <div class="pt-6 pb-16">
            <p>Are you sure you want to reset your dashboard?</p>
            <p>Any changes you've made to the workspace will be deleted.</p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close color="accent">CANCEL</button>
        <button mat-button color="accent" (click)="doAction()">YES, RESET NOW</button>
    </mat-dialog-actions>
</div>