<mat-dialog-content>
    <div class="redar-dialog">
        <div class="redar-dialog-title"><b>Engineering Information Delivery (EID) no longer supports IE11</b></div>
        <div class="redar-info-section">EID has updated their application and no longer requires the PRIZM plugin. The new solution that replaces the
            PRIZM
            plugin does not support IE11. If you are using IE11 or Edge in IE11 mode, some key features will no longer work.
        </div>
        <div class="redar-info-section"><b>If you are using Edge </b> and seeing this message, your browser settings are not correct. Contact your EAF
            or
            browser administrator to have this application removed from the Edge enterprise site list that controls IE mode
            settings.</div>
        <div class="redar-info-section"><b>If you are using IE11, </b> as noted in prior messages, IE11 is no longer supported by any application on
            MBF.
            EID is the first of many applications where performance and functionality will degrade over time if you continue
            to
            use IE11.</div>
        <div class="redar-info-section"><b>Boeing recommends Edge since it supports all applications on MBF (when IE mode is set properly). </b> Chrome
            and
            Firefox will support most applications on MBF. The newly updated version of EID supports all of 3 of these
            browsers.
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="start">
    <div class="pb-10">
        <button mat-raised-button color="accent" (click)="submit()" class="redar-got-it">GOT IT</button>
    </div>
</mat-dialog-actions>
   
