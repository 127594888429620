import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'striphtml'
})
export class StriphtmlPipe implements PipeTransform {

  transform(input: any, count: number): string {
    // tslint:disable-next-line: prefer-const
    let tmp = document.createElement('DIV');
    tmp.innerHTML = input;
    let txt = tmp.textContent || tmp.innerText || '';
    if (txt.length > count) {
      txt = txt.substring(0, count) + '...';
    }
    return txt;
  }
}
