<div class="rounded-lg font-boeing-meso bgm-white">
    <div mat-dialog-title>
        <div class="user-role-title-subTitle h-per-20">
            <div class="count-compound-title">User List</div>
            <div class="count-compound-subtitle">
                {{this.title}}
            </div>
        </div>
    </div>
    <mat-dialog-content>
        <div class=" w-full overflow-y-auto div-content">
            <div class="bg-gray-230 subdiv-content">
                {{content}}
            </div>
        </div>
    </mat-dialog-content>
    <button mat-button tabindex=-1 class="action-buttons mr-auto font-semibold font-italic" color="accent"><span
       (click)="download()">Download .csv</span></button>
    <div class="font-semibold font-italic file-msg-div">File will contain the first 10,000 users and is not sorted.</div>
    <mat-dialog-actions>

        <button mat-button [mat-dialog-close]="true" tabindex=-1 class="action-buttons searchBtn close-btn"><span
                class="font-semibold" >CLOSE</span></button>
    </mat-dialog-actions>

</div>