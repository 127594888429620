<div class="text-px-16">
    <div mat-dialog-content class="confirmation-modal-min-width">
        <div>
            <mat-icon *ngIf="data.type==='success'" class="text-px-60 success-color">check_circle</mat-icon>
            <mat-icon *ngIf="data.type==='error'" class="text-px-60 error-color">error_outline</mat-icon>
            <mat-icon *ngIf="data.type==='warning'" class="text-px-60 warn-color">warning</mat-icon>
        </div>
        <br>
        <div class="text-px-14">
            <span>{{data.title}}</span>
        </div>
        <br>
    </div>
    <div mat-dialog-actions class="text-right float-right">
        <button tabindex="-1" mat-button *ngFor="let action of data.actions"  color="accent" class="text-px-14 uppercase" (click)="callAction(action.func)"><span class="font-semibold">{{ action.text }}</span></button>
    </div>
</div>

