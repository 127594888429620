<div class="skinny-footer-build-info flex flex-col">
  <div class="flex flex-row">
    <div class="flex flex-col">
      <div class="skinny-footer-build-info-label-div">
        <span class="material-symbols-sharp">deployed_code</span>
        <span class="skinny-footer-build-info-label">Deployment Details</span>
      </div>
      <div *ngFor="let prop of buildinfo.envInfoProp" class="skinny-footer-build-info-env">{{prop.name}} : {{envConfig[prop.value]}}</div>
    </div>
  </div>
  <div class="flex flex-row">
    <div class="flex flex-col">
      <div *ngFor="let prop of buildinfo.uiInfoProp" class="skinny-footer-build-info-ui">{{prop.name}} : {{getProperty(prop.value)}}</div>
    </div>
    <div class="flex flex-col">
      <div *ngFor="let prop of buildinfo.ngInfoProp" class="skinny-footer-build-info-ng">{{prop.name}} : {{envConfig[prop.value]}}</div>
    </div>
  </div>
</div>
